import { TFunction } from 'i18next';

import { AnalyticsQuestion, FormVersion, EvaluationStatus, FormRouteType } from 'graphql-api';

export const ages = ['1', '2', '3', '4', '5', '6'];

export const createChartData = (questionItem: AnalyticsQuestion, t: TFunction, formVersion = FormVersion.VASU, hasCorrection?: boolean) => {
  const versionedText = formVersion === FormVersion.VASU ? 'formOptions' : 'parentalImportanceFormOptions';
  return questionItem.answers.values
    .slice(0)
    .reverse()
    .map((answerItem) => ({
      name: t(`${versionedText}.${answerItem.value}`),
      value: hasCorrection ? answerItem.repliers + 1 : answerItem.repliers,
      replierValue: answerItem.value,
      isHighlighted: questionItem.answers.myAnswer === answerItem.value,
      isOwnValue: questionItem.answers.discussionAnswer === answerItem.value,
    }));
};

export const getFormRouteType = (evaluationStatus?: EvaluationStatus) => {
  switch (evaluationStatus) {
    case EvaluationStatus.CREATED:
    case EvaluationStatus.VASU:
    case EvaluationStatus.VASU_DONE:
      return FormRouteType.evaluation;
    case EvaluationStatus.DISCUSSION:
    case EvaluationStatus.DISCUSSION_DONE:
    case EvaluationStatus.DISCUSSION_READY:
      return FormRouteType.discussion;
    case EvaluationStatus.RECAP:
    case EvaluationStatus.RECAP_READY:
    case EvaluationStatus.DONE:
      return FormRouteType.recap;
    default:
      return FormRouteType.evaluation;
  }
};

export const getFormVersionFromRoute = (formRouteType?: FormRouteType) => {
  switch (formRouteType) {
    case FormRouteType.discussion:
      return FormVersion.DISCUSSION;
    case FormRouteType.evaluation:
      return FormVersion.VASU;
    case FormRouteType.parentalInvolvement:
      return FormVersion.PARENTAL;
    case FormRouteType.recap:
      return FormVersion.RECAP;
    default:
      return FormVersion.VASU;
  }
};

export const getFormVersionFromStatus = (evaluationStatus?: EvaluationStatus) => getFormVersionFromRoute(getFormRouteType(evaluationStatus));
