import React from 'react';

import moment from 'moment';

export interface AgendaDateProps {
  label: string;
  day: string;
}

const AgendaDate: React.FC<AgendaDateProps> = (props) => {
  const [day, month, date] = props.label.split(' ');

  const isToday = moment(props.day).isSame(moment(), 'day');

  const classToday = 'bg-teacher-blue text-white';

  const dateClass = `${isToday ? classToday : 'text-teacher-blue'} h-8 w-8 rounded-full flex justify-center items-center`;

  return (
    <div className="flex gap-4 py-1 pl-2 pr-4 items-center">
      <span className={dateClass}>{date}</span>
      <span className="text-teacher-blue text-opacity-60">{`${month}, ${day}`}</span>
    </div>
  );
};

export default AgendaDate;
