import React from 'react';

import { useTranslation } from 'react-i18next';

import { ChildrenEvalutationStatus, Group } from 'graphql-api';
import { ReactComponent as Edit } from 'assets/edit-icon.svg';
import { ReactComponent as Trash } from 'assets/trash-icon.svg';
import { ReactComponent as Done } from 'assets/done-icon.svg';
import { ReactComponent as Document } from 'assets/document-icon.svg';
import { ReactComponent as InProgress } from 'assets/inprogress-icon.svg';
import colors from 'utils/colors';
import { Button } from 'components';

export interface GroupCardProps {
  group: Group;
  numberOfChildren: number;
  onEdit?: () => void;
  onDelete?: () => void;
  onClick?: () => void;
  continueDisabled?: boolean;
  isSupport?: boolean;
}

const GroupCard: React.FC<GroupCardProps> = ({ group, numberOfChildren, onEdit, onDelete, onClick, continueDisabled = false, isSupport }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col rounded-lg border-2 px-4 py-4 mb-6 border-admin-blue bg-white">
      <div className="flex items-center justify-between mb-3">
        <div className="text-teacher-blue font-bold">{group.name}</div>
        <div className="flex items-center">
          {onEdit && <Edit className="cursor-pointer" onClick={onEdit} />}
          {onDelete && !isSupport && <Trash className="cursor-pointer ml-2 bg-disabled" onClick={onDelete} stroke={colors.teacherBlue} />}
        </div>
      </div>
      {!!numberOfChildren && group.evaluationStatus?.status && (
        <>
          <div className="flex items-center">
            {
              {
                [ChildrenEvalutationStatus.done]: (
                  <>
                    <Done stroke={colors.done} />
                    <div className="text-done ml-2 font-medium">{t('common.done')}</div>
                  </>
                ),
                [ChildrenEvalutationStatus.inProgress]: (
                  <>
                    <InProgress />
                    <div className="text-progress ml-2 font-medium">{t('common.inProgress')}</div>
                  </>
                ),
                [ChildrenEvalutationStatus.none]: (
                  <>
                    <Document />
                    <div className="text-admin-blue ml-2 font-medium">{t('common.hasntStarted')}</div>
                  </>
                ),
              }[group.evaluationStatus.status]
            }
          </div>
          <div className="flex items-center text-teacher-blue my-3">
            <div>{t('common.done')}</div>
            <div className="ml-2 bg-result-blue rounded-xl px-2 text-sm">{`${group.evaluationStatus.done}/${numberOfChildren}`}</div>
          </div>
          <div className="flex items-center justify-between text-teacher-blue">
            <div className="flex items-center">
              <div>{t('childrenEvaluationTypes.ece')}</div>
              <div className="ml-2 bg-result-blue rounded-xl px-2 text-sm">{group.evaluationStatus.ecec}</div>
            </div>
            <div className="flex items-center">
              <div>{t('childrenEvaluationTypes.discussion')}</div>
              <div className="ml-2 bg-result-blue rounded-xl px-2 text-sm">{group.evaluationStatus.discussion}</div>
            </div>
            <div className="flex items-center">
              <div>{t('childrenEvaluationTypes.recap')}</div>
              <div className="ml-2 bg-result-blue rounded-xl px-2 text-sm">{group.evaluationStatus.recap}</div>
            </div>
          </div>
        </>
      )}
      {onClick && (
        <Button variant="primary" onClick={onClick} disabled={continueDisabled} className="!w-full mt-4">
          {t('common.continue')}
        </Button>
      )}
    </div>
  );
};

export default GroupCard;
