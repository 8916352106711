import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormRouteType, useStartEvaluation } from 'graphql-api';
import NavigationRoute from 'navigation/NavigationRoute';
import { showToast, ToastType } from 'utils/toast';
import { ageGroupErrorMessageBeginning } from 'utils/errorMessages';

export const useOpenEvaluation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [startEvaluation, { loading }] = useStartEvaluation({
    onCompleted(createEvaluationData) {
      if (createEvaluationData.createEvaluation.id) {
        navigate(`${NavigationRoute.EcecPlan}/${FormRouteType.evaluation}/${createEvaluationData.createEvaluation.id}`);
      }
    },
    onError(error) {
      const errorMessage = error.message.startsWith(ageGroupErrorMessageBeginning)
        ? 'errorMessages.ageGroupNotFound'
        : 'teacherPreparationForPlanDiscussion.noEvalPeriod';

      showToast({
        title: t(errorMessage),
        type: ToastType.ERROR,
      });
    },
  });

  return {
    startEvaluation,
    loading,
  };
};
