import React from 'react';
import { useTranslation } from 'react-i18next';

import { TeacherCalendar, ModalPicker } from 'components';
import { useTeacherByUnitSlug } from 'hooks/useTeacherByUnitSlug';
import { useUserByIdForUnitAdminCalendar } from 'graphql-api';

export interface UnitAdminCalendarProps {
  userId: number | null;
}

export const UnitAdminCalendar: React.FC<UnitAdminCalendarProps> = ({ userId }) => {
  const { t } = useTranslation();

  const { data } = useUserByIdForUnitAdminCalendar(userId, { skip: !userId, fetchPolicy: 'no-cache' });
  const [unitSlug, setUnitSlug] = React.useState<string>();

  const { teachers, teacherSlug, setTeacherSlug, teacherId } = useTeacherByUnitSlug(unitSlug);

  return (
    <React.Fragment>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-x-5">
        <ModalPicker
          title={t('unitScreen.chooseUnit')}
          label={t('userMaintenanceScreen.unit')}
          onChange={(slug) => setUnitSlug(slug)}
          data={data?.user.ownedUnits}
          selectedItem={unitSlug}
        />

        <ModalPicker
          title={t('superAdminCalendar.chooseTeacher')}
          label={t('common.teacher')}
          onChange={(slug) => setTeacherSlug(slug)}
          data={teachers}
          selectedItem={teacherSlug}
        />
      </div>

      <TeacherCalendar teacherId={teacherId} />
    </React.Fragment>
  );
};
