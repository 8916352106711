import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Loader, ScreenLayout } from 'components';
import { Child, User, useChildBySlug } from 'graphql-api';
import { ChildFormStep } from 'components/Units';

export const EditUsersChildScreen: React.FC = () => {
  const { t } = useTranslation();
  const { accessLevel: role, userSlug, childSlug } = useParams();
  const [user, setUser] = useState<User>();
  const [child, setChild] = useState<Child>();
  const breadcrumb = [
    {
      step: '/users',
      title: `${t('userScreen.title')}: ${t(`accessLevels.${role}`)}`,
    },
    {
      step: `/users/${role}/${userSlug}`,
      title: `${user?.firstName} ${user?.lastName}`,
    },
    {
      step: `/users/${role}/${userSlug}/${childSlug}`,
      title: `${child?.firstName} ${child?.lastName}`,
    },
  ];

  const { loading, refetch } = useChildBySlug(childSlug, {
    onCompleted(data) {
      if (data.childBySlug) {
        setUser(data.childBySlug.parent);
        setChild(data.childBySlug);
      }
    },
  });
  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <Loader isLoading={loading}>
        <ChildFormStep child={child} breadcrumb={breadcrumb} refetch={refetch} />
      </Loader>
    </ScreenLayout>
  );
};
