import React from 'react';

import { useOutsideClickHandler } from 'hooks/useClickOutside';

export interface DropdownProps {
  options: string[];
  onChange: (value: string) => void;
  defaultSelected: string;
  className?: string;
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { options, onChange, defaultSelected, className = '' } = props;

  const [selected, setSelected] = React.useState(defaultSelected);
  const [isOpen, setIsOpen] = React.useState(false);
  const [optionsLocation, setOptionsLocation] = React.useState({ top: '0px', left: '0px' });
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  useOutsideClickHandler(wrapperRef, () => setIsOpen(false));

  React.useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const toggleOptions = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);

    if (wrapperRef.current) {
      const box = wrapperRef.current.getBoundingClientRect();

      const dropDownListYOffset = 5;

      setOptionsLocation({
        top: `${box.top + box.height + dropDownListYOffset}px`,
        left: `${box.left}px`,
      });
    }
  };

  const changeSelected = (newSelected: string) => {
    setSelected(newSelected);
    onChange(newSelected);
  };

  const optionComponents = options.map((option) => (
    <div
      title={option}
      key={option}
      className="hover:bg-admin-blue hover:text-breadcrumb-blue h-8 flex items-center pl-4"
      onClick={() => changeSelected(option)}
    >
      {option}
    </div>
  ));

  return (
    <div
      className={`${className} cursor-pointer relative rounded-lg ${
        isOpen ? 'bg-breadcrumb-blue' : 'hover:bg-breadcrumb-blue'
      } w-12 h-10 flex justify-center items-center font-semibold text-teacher-blue`}
      ref={wrapperRef}
      onClick={toggleOptions}
    >
      <div>{selected}</div>

      {isOpen && (
        <div className="fixed top-0 h-36 w-28 overflow-y-auto py-1 left-0 z-10 border-2 border-table rounded-lg bg-white" style={optionsLocation}>
          {optionComponents}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
