import React from 'react';

import { useTranslation } from 'react-i18next';

import { Modal } from 'components';
import { Unit } from 'graphql-api';

export interface UnitModalProps {
  visible: boolean;
  openModal: (visible: boolean) => void;
  onSelect: (id: Unit) => void;
  units?: Unit[];
  selectedUnits?: Unit[];
}

const UnitModal: React.FC<UnitModalProps> = ({ visible, openModal, onSelect, units, selectedUnits }) => {
  const { t } = useTranslation();

  const selectItem = (unit: Unit) => {
    onSelect(unit);
    openModal(false);
  };
  const selectedUnitIds = selectedUnits ? selectedUnits.map((item) => item.id) : [];
  const filteredUnits = selectedUnits ? units?.filter((item) => !selectedUnitIds.includes(item.id) && !item.admin) : units;
  return (
    <Modal className="max-w-[350px] md:max-w-full" visible={visible} title={t('unitScreen.chooseUnit')} onClose={() => openModal(false)}>
      {filteredUnits &&
        filteredUnits.map((item) => {
          const selected = selectedUnitIds.includes(item.id);
          const selectedBackground = selected ? 'bg-admin-blue' : '';
          return (
            <button
              key={item.id}
              className={`text-teacher-blue ${selectedBackground} hover:bg-background py-2 pl-2 text-left`}
              onClick={() => selectItem(item)}
            >
              {item.name}
            </button>
          );
        })}
    </Modal>
  );
};

export default UnitModal;
