import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface BreadCrumbProps {
  data: BreadCrumbData[];
  className?: string;
}

export interface BreadCrumbData {
  step: string;
  title: string | null;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ data, className = '' }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const actualLength = data.filter((item) => !!item.title).length;
  const createSlash = (index: number) => (actualLength - 1 !== index ? '/' : '');

  const activeButtonBackgroundClass = (item: BreadCrumbData) => (item.step === location.pathname ? 'bg-breadcrumb-blue' : 'bg-white');
  return (
    <div className={`flex items-center text-xs md:text-base ${className}`}>
      {data &&
        data.map(
          (item, index) =>
            !!item.title && (
              <div className="flex items-center">
                <button
                  onClick={() => navigate(item.step)}
                  className={`px-1 rounded-xl ${activeButtonBackgroundClass(item)} text-admin-blue truncate w-24 md:w-80 max-w-min`}
                >
                  {item.title}
                </button>
                <div className="mx-1 text-admin-blue">{createSlash(index)}</div>
              </div>
            ),
        )}
    </div>
  );
};

export default BreadCrumb;
