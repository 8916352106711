import React from 'react';

import moment from 'moment';
import type { View } from 'react-big-calendar';

import type { ConsultingEvent } from 'graphql-api';

import TimeViewHeader from './TimeViewHeader';
import AgendaDate from './AgendaDate';
import AgendaTime from './AgendaTime';
import AgendaEvent from './AgendaEvent';
import MonthEvent from './MonthEvent';
import WeekEvent from './WeekEvent';
import DayEvent from './DayEvent';

export const useCalendarCustomComponents = () => {
  return React.useMemo(
    () => ({
      agenda: {
        date: AgendaDate,
        time: AgendaTime,
        event: AgendaEvent,
      },
      day: {
        header: TimeViewHeader,
        event: DayEvent,
      },
      week: {
        header: TimeViewHeader,
        event: WeekEvent,
      },
      month: {
        event: MonthEvent,
      },
    }),
    [],
  );
};

export const useEventPropGetter = (consultations: ConsultingEvent[], view: View) => {
  return React.useCallback(
    (event: ConsultingEvent) => {
      if (view === 'agenda') {
        const isBefore = (consultation: ConsultingEvent) => moment(consultation.startsAt).isBefore(moment(event.startsAt));
        const isAfter = (consultation: ConsultingEvent) => moment(consultation.startsAt).isAfter(moment(event.startsAt));
        const sameDayEvents = consultations.filter((consultation) => moment(consultation.startsAt).isSame(moment(event.startsAt), 'day'));
        const isDayMaxEvent = !sameDayEvents.some(isAfter);
        const isDayMinEvent = !sameDayEvents.some(isBefore);
        const isMaxEvent = !consultations.some(isAfter);
        const isMinEvent = !consultations.some(isBefore);

        const shoulHaveBottomBorder = !isMaxEvent && isDayMaxEvent;
        const shoulHaveTopBorder = !isMinEvent && isDayMinEvent;

        return {
          className: `text-xs !border-0 !border-admin-blue !border-opacity-50 ${shoulHaveBottomBorder ? '!border-b' : ''} ${
            shoulHaveTopBorder ? '!border-t' : ''
          }`,
        };
      }

      return {
        className: event.parent
          ? `${view === 'month' ? 'pointer-events-none sm:pointer-events-auto sm:bg-done' : 'bg-done'} text-white text-xs`
          : `${
              view === 'month' ? 'pointer-events-none sm:pointer-events-auto sm:bg-table sm:bg-opacity-50' : 'bg-table bg-opacity-50'
            } text-teacher-blue text-xs`,
      };
    },
    [consultations, view],
  );
};
