import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import NavigationRoute from 'navigation/NavigationRoute';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';
import { logOutUser } from 'redux/authentication/authentication.actions';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as Analytics } from 'assets/menu-analytics.svg';
import { ReactComponent as FAQ } from 'assets/menu-faq.svg';
import { ReactComponent as Language } from 'assets/menu-language.svg';
import { ReactComponent as Logout } from 'assets/menu-logout.svg';
import { ReactComponent as UnitSelected } from 'assets/menu-unit-selected.svg';
import { ReactComponent as Unit } from 'assets/menu-unit.svg';
import { ReactComponent as User } from 'assets/menu-user.svg';
import { ReactComponent as UserSelected } from 'assets/menu-user-selected.svg';
import { ReactComponent as ECEC } from 'assets/ecec-icon.svg';
import { ReactComponent as Calendar } from 'assets/calendar-icon.svg';
import { ReactComponent as MobileCloseMenu } from 'assets/mobile-close-menu.svg';
import { ReactComponent as MobileHamburgerMenu } from 'assets/mobile-hamburger-menu.svg';
import { ReactComponent as Carret } from 'assets/carret2.svg';
import { ReactComponent as Home } from 'assets/menu-home.svg';
import { LanguageMenu } from 'components';
import { getLanguageMenu, LanguageMenuItem } from 'utils/languageMenu';

export interface MenuProps {
  isOpenOnMobile: boolean;
  toggleOnMobile: () => void;
}

const Menu: React.FC<MenuProps> = ({ isOpenOnMobile, toggleOnMobile }) => {
  const { t } = useTranslation();
  const { isAuthenticated, accessLevel, email } = useAppSelector(({ auth }) => auth);
  const [isLanguageMenuOpened, setLanguageMenuOpen] = useState(false);
  const [isLanguageMenuOpenOnMobile, setLanguageMenuOpenOnMobile] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const pathName = location.pathname;

  const getNavColor = () => {
    if (!isAuthenticated || accessLevel === AccessLevel.Teacher) {
      return 'bg-teacher-blue';
    }
    if (accessLevel === AccessLevel.Parent) {
      return 'bg-parent-green';
    }
    return 'bg-admin-blue';
  };

  const navColor = getNavColor();

  const renderLanguageMenu = () => (
    <button className="relative flex items-center mb-2" onClick={() => setLanguageMenuOpen(!isLanguageMenuOpened)}>
      <Language />
      <LanguageMenu isOpened={isLanguageMenuOpened} setOpen={setLanguageMenuOpen} accessLevel={accessLevel} />
      <div className="ml-3 text-white font-bold">{t('newMenu.language')}</div>
    </button>
  );

  const renderBottomMenu = () => {
    if (!isAuthenticated) {
      return <div className="mb-8">{renderLanguageMenu()}</div>;
    }

    return (
      <>
        <button className="flex items-center mb-2" onClick={() => navigation(NavigationRoute.FAQ)}>
          <FAQ />
          <div className="ml-3 text-white font-bold">{t('newMenu.faq')}</div>
        </button>
        {renderLanguageMenu()}
        <button className="flex items-center mb-2" onClick={() => dispatch(logOutUser())}>
          <Logout />
          <div className="ml-3 text-white font-bold">{t('newMenu.signOut')}</div>
        </button>
      </>
    );
  };

  const getUpperMenu = () => {
    switch (accessLevel) {
      case AccessLevel.SuperAdmin:
      case AccessLevel.UnitAdmin:
      case AccessLevel.Support:
        return [
          {
            path: NavigationRoute.Units,
            icon: <Unit />,
            selectedIcon: <UnitSelected />,
            title: t('newMenu.units'),
          },
          {
            path: NavigationRoute.Users,
            icon: <User />,
            selectedIcon: <UserSelected />,
            title: t('newMenu.users'),
          },
          {
            path: NavigationRoute.EcecPlan,
            icon: <ECEC />,
            selectedIcon: <ECEC />,
            title: t('newMenu.ecec'),
          },
          {
            path: NavigationRoute.Calendar,
            icon: <Calendar />,
            selectedIcon: <Calendar />,
            title: t('newMenu.calendar'),
          },
          {
            path: '/analytics/child-relationship',
            icon: <Analytics />,
            selectedIcon: <Analytics />,
            title: t('newMenu.analytics'),
          },
        ];
      case AccessLevel.Parent:
        return [
          {
            path: NavigationRoute.Home,
            icon: <Home />,
            selectedIcon: <Home />,
            title: t('common.home'),
          },
          {
            path: NavigationRoute.EcecPlan,
            icon: <ECEC />,
            selectedIcon: <ECEC />,
            title: t('newMenu.ecec'),
          },
          {
            path: NavigationRoute.Calendar,
            icon: <Calendar />,
            selectedIcon: <Calendar />,
            title: t('newMenu.calendar'),
          },
        ];
      case AccessLevel.Teacher:
        return [
          {
            path: NavigationRoute.Home,
            icon: <Home />,
            selectedIcon: <Home />,
            title: t('common.home'),
          },
          {
            path: NavigationRoute.EcecPlan,
            icon: <ECEC />,
            selectedIcon: <ECEC />,
            title: t('newMenu.ecec'),
          },
          {
            path: NavigationRoute.Calendar,
            icon: <Calendar />,
            selectedIcon: <Calendar />,
            title: t('newMenu.calendar'),
          },
          {
            path: '/analytics/child-relationship',
            icon: <Analytics />,
            selectedIcon: <Analytics />,
            title: t('newMenu.analytics'),
          },
        ];
      default:
        return [];
    }
  };

  const renderUpperMenu = () => {
    const upperMenu = getUpperMenu();
    if (isAuthenticated) {
      return upperMenu.map((item) => (
        <button key={item.title} className="flex items-center mb-2" onClick={() => navigation(item.path)}>
          {pathName === item.path ? item.selectedIcon : item.icon}
          <div className="ml-3 text-white font-bold">{item.title}</div>
        </button>
      ));
    }
    return null;
  };

  const renderMobileMenu = () => {
    const authenticatedMenu = getUpperMenu();

    authenticatedMenu.push({
      path: NavigationRoute.FAQ,
      icon: <FAQ />,
      selectedIcon: <FAQ />,
      title: t('newMenu.faq'),
    });

    const mobileMenu = (isAuthenticated ? authenticatedMenu : []).map((item) => (
      <div key={item.title} className="flex items-center mb-4 cursor-pointer" role="button" onClick={() => navigation(item.path)}>
        {pathName === item.path ? item.selectedIcon : item.icon}
        <div className="ml-3 text-white font-bold text-xl">{item.title}</div>
      </div>
    ));

    const languageMenu = getLanguageMenu(t);

    const onMenuClick = (menuItem: LanguageMenuItem) => {
      if (menuItem.onClick) {
        menuItem.onClick();
      }
    };

    mobileMenu.push(
      <div key="language" className="mb-4 text-xl">
        <div role="button" className="flex items-center cursor-pointer" onClick={() => setLanguageMenuOpenOnMobile((prev) => !prev)}>
          <div className="flex items-center">
            <Language />
            <div className="ml-3 text-white font-bold">{t('newMenu.language')}</div>
          </div>
          <Carret className={`ml-auto ${isLanguageMenuOpenOnMobile ? 'rotate-180' : ''}`} />
        </div>
        {isLanguageMenuOpenOnMobile && (
          <div className="mt-3">
            {!!languageMenu.length &&
              languageMenu.map((menuItem) => (
                <div
                  key={menuItem.title}
                  className={`py-3 px-8 text-xs cursor-pointer ${
                    i18next.language === menuItem.active ? `text-white ${navColor}` : `${navColor.replace('bg-', 'text-')} bg-white`
                  }`}
                  onClick={() => onMenuClick(menuItem)}
                >
                  {menuItem.title}
                </div>
              ))}
          </div>
        )}
      </div>,
    );

    return mobileMenu;
  };

  return (
    <div className={`md:w-72 flex flex-col px-5 md:pr-0 z-10 ${navColor} ${isOpenOnMobile ? 'h-screen' : ''}`}>
      <div className="flex justify-between items-center">
        <Logo className="md:mt-12" />
        {isOpenOnMobile ? (
          <MobileCloseMenu className="my-5 md:hidden" onClick={toggleOnMobile} />
        ) : (
          <MobileHamburgerMenu className="my-5 md:hidden" onClick={toggleOnMobile} />
        )}
      </div>

      <div className={`${isOpenOnMobile ? '' : 'hidden'} md:flex md:flex-col md:h-full`}>
        {isAuthenticated && (
          <div className="flex justify-between">
            <div>
              <div className="text-white font-semibold mt-2">{email}</div>
              <div className="text-white mt-1 text-xs">{t(`accessLevels.${accessLevel}`)}</div>
            </div>
            <div className="flex items-center justify-center md:hidden">
              <Logout className="mt-2" onClick={() => dispatch(logOutUser())} />
            </div>
          </div>
        )}
        <div className="mt-24 hidden md:block">{renderUpperMenu()}</div>
        <div className="mt-auto mb-4 hidden md:block">{renderBottomMenu()}</div>
        <div className="mt-12 md:hidden">{renderMobileMenu()}</div>
      </div>
    </div>
  );
};

export default Menu;
