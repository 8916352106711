import React from 'react';

import ReactSwitch from 'react-switch';

export interface SwitchProps {
  onChange: (checked: boolean) => void;
  checked: boolean;
  title: string;
  disabled?: boolean;
}

const Switch: React.FC<SwitchProps> = ({ onChange, checked, title, disabled }) => (
  <div className="flex items-center">
    <ReactSwitch
      onChange={onChange}
      checked={checked}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor="#6588BC"
      offColor="#C4C4C4"
      handleDiameter={20}
      height={24}
      width={44}
      disabled={disabled}
    />
    <div className="text-teacher-blue ml-4 text-xs">{title}</div>
  </div>
);

export default Switch;
