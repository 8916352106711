import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, TextInput } from 'components';
import { Child, Group, Unit } from 'graphql-api';

interface Props {
  onChange: (key: keyof Child, value: string | Date | Unit | Group) => void;
  child?: Child;
  onNext?: () => void;
}

const FormStep: FC<Props> = ({ onChange, onNext, child }) => {
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        label={t('userScreen.firstName')}
        onChange={(e) => onChange('firstName', e.target.value)}
        value={child?.firstName}
        containerClassName="mt-4 mb-8"
      />
      <TextInput
        label={t('userScreen.lastName')}
        onChange={(e) => onChange('lastName', e.target.value)}
        value={child?.lastName}
        containerClassName="mt-4 mb-8"
      />
      <Button
        variant="tertiary"
        onClick={onNext}
        disabled={!child?.firstName || !child.lastName}
        labelClassName="!text-teacher-blue font-bold w-full flex justify-center"
        className="w-fit ml-auto pr-1"
      >
        {t('common.next')}
      </Button>
    </>
  );
};

export default FormStep;
