import React from 'react';

import { useTranslation } from 'react-i18next';

import { ChildInfoForm, useUpdateChild } from 'graphql-api';
import { Button, TextArea, DatePicker } from 'components';
import { transformBirthDate } from 'utils/helpers';

export interface ChildFormProps {
  childFormValue: ChildInfoForm;
  dateOfBirth?: Date;
  setChildFormValue?: (childFormValue: ChildInfoForm) => void;
  refetch?: () => void;
  disabled?: boolean;
  editable?: boolean;
}

const ChildForm: React.FC<ChildFormProps> = (props) => {
  const { childFormValue, dateOfBirth, setChildFormValue, refetch, disabled, editable = true } = props;
  const { t } = useTranslation();
  const [updateChild] = useUpdateChild({
    onCompleted() {
      if (refetch) {
        refetch();
      }
    },
  });

  const editChild = () => {
    let body = {};
    if (childFormValue.enrollmentDate) {
      body = {
        ...body,
        enrollmentDate: transformBirthDate(childFormValue.enrollmentDate as Date),
      };
    }
    if (typeof childFormValue.healthAndOtherReport === 'string') {
      body = {
        ...body,
        healthAndOtherReport: childFormValue.healthAndOtherReport,
      };
    }
    updateChild({
      variables: {
        id: childFormValue.id,
        payload: body,
      },
    });
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    editChild();
  };

  const changeHealthReport: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    if (setChildFormValue) {
      setChildFormValue({
        ...childFormValue,
        healthAndOtherReport: e.target.value,
      });
    }
  };

  const changeEnrollmentDate = (startingDate: Date) => {
    if (setChildFormValue) {
      setChildFormValue({ ...childFormValue, enrollmentDate: startingDate });
    }
  };

  const isDisabledInputs = disabled || !editable;

  return (
    <form onSubmit={onSubmit}>
      <div className="md:grid md:grid-cols-2 md:gap-16 mt-6 md:mt-0">
        <TextArea
          className="mb-4 w-full !h-full text-xs md:text-base"
          label={t('common.generalHealth')}
          onChange={changeHealthReport}
          value={childFormValue.healthAndOtherReport}
          disabled={isDisabledInputs}
        />
        <div className="flex-col">
          <DatePicker selectedDate={dateOfBirth} />
          <DatePicker
            title={t('common.startingDate')}
            selectedDate={childFormValue.enrollmentDate as Date}
            onChangeDate={isDisabledInputs ? undefined : changeEnrollmentDate}
          />
        </div>
      </div>
      {editable && (
        <Button variant="primary" type="submit" disabled={disabled}>
          {t('common.saveChanges')}
        </Button>
      )}
    </form>
  );
};

export default ChildForm;
