import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ScreenLayout } from 'components';
import { UserListStep } from 'components/Users';
import { User, useUsersByRoleForUserListScreen } from 'graphql-api';
import { AccessLevel } from 'redux/authentication/enums';
import { useAppSelector } from 'hooks/redux';

export const UserListScreen: React.FC = () => {
  const navigate = useNavigate();
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const isUnitAdmin = accessLevel === AccessLevel.UnitAdmin;

  const startRole = isUnitAdmin ? AccessLevel.Teacher : AccessLevel.UnitAdmin;

  const [role, setRole] = useState(startRole);

  const [onlyActive, setOnlyActive] = useState(true);

  const { data, loading } = useUsersByRoleForUserListScreen(role, onlyActive, { fetchPolicy: 'no-cache' });

  const onUserClick = (user?: User) => {
    navigate(user ? `/users/${role}/${user.slug}` : `/users/${role}/new`);
  };

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <UserListStep
        data={data}
        loading={loading}
        setRole={setRole}
        selectedRole={role}
        onlyActive={onlyActive}
        setOnlyActive={setOnlyActive}
        onClick={onUserClick}
      />
    </ScreenLayout>
  );
};
