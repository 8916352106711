import React from 'react';

import { ReactComponent as Carret } from 'assets/carret.svg';
import colors from 'utils/colors';

export interface TableProps {
  tableColumns: { title: string }[];
  data?: { [key: string]: string | string[] }[];
  isParent?: boolean;
  onRowClick: (item: { [key: string]: string | string[] }) => void;
}

const Table: React.FC<TableProps> = ({ tableColumns, data, isParent = false, onRowClick }) => {
  const [selectedColumn, setSelectedColumn] = React.useState(0);

  if (!data) return null;

  const [nameColumn, ...restTableColumns] = tableColumns;

  const lastColumn = restTableColumns.length - 1;

  const changeColumn = (direction: 1 | -1) => {
    setSelectedColumn((prevColumn) => {
      const newColumn = prevColumn + direction;

      if (newColumn < 0) {
        return 0;
      } else if (newColumn >= restTableColumns.length) {
        return lastColumn;
      } else {
        return newColumn;
      }
    });
  };

  const isFirstColumn = selectedColumn === 0;
  const isLastColumn = selectedColumn === lastColumn;

  const headerClassName = `border-b-2 border-b-table/50 text-admin-blue font-medium relative text-left pb-2 w-1/${tableColumns.length}`;

  const renderHeader = () => (
    <thead>
      <tr>
        <th className={`pl-2 md:pl-0 ${headerClassName}`}>{nameColumn.title}</th>
        <th className={`${headerClassName} md:hidden w-8`} role="button" onClick={() => changeColumn(-1)}>
          <Carret className="ml-2" fill={isFirstColumn ? colors['teacherBlue-0.3'] : colors.teacherBlue} />
        </th>
        {restTableColumns.map((column, index) => (
          <th className={`${headerClassName} ${index === selectedColumn ? 'text-center md:text-left' : 'hidden md:table-cell'}`} key={column.title}>
            {column.title}
          </th>
        ))}
        <th className={`${headerClassName} md:hidden w-8`} role="button" onClick={() => changeColumn(1)}>
          <Carret className="rotate-180 ml-2" fill={isLastColumn ? colors['teacherBlue-0.3'] : colors.teacherBlue} />
        </th>
      </tr>
    </thead>
  );

  const renderCells = (rowData: { [key: string]: string | string[] }) => {
    const [nameEntry, ...restEntries] = Object.entries(rowData);

    const nameColl = (
      <td key={nameEntry[0]} className="my-1 py-2 pl-2 md:pl-0 bg-white md:bg-transparent">
        {nameEntry[1]}
      </td>
    );

    const restColls = restEntries.map(
      ([key, value], index) =>
        key !== 'slug' && (
          <td
            key={Array.isArray(value) ? value[0] : value}
            className={`my-1 py-2 ${index === selectedColumn ? 'text-center md:text-left' : 'hidden md:table-cell'}`}
          >
            {Array.isArray(value)
              ? value.map(
                  (moreItems: string | { child: []; slug: string; active: boolean }, index) =>
                    (typeof moreItems !== 'object' && (
                      <div className="my-1" key={'child_' + index + moreItems}>
                        {moreItems}
                      </div>
                    )) ||
                    (typeof moreItems === 'object' && (
                      <div className={`my-1 ${isParent && !moreItems.active ? 'text-red-500' : ''}`} key={'child_' + index + moreItems.slug}>
                        {moreItems?.child}
                      </div>
                    )),
                )
              : value}
          </td>
        ),
    );

    return [nameColl, <td key="1" className="md:hidden" />, ...restColls, <td key="2" className="md:hidden" />];
  };

  const renderBody = () => (
    <tbody className="text-sm">
      {data?.map((dataItem, index) => {
        const trKey = Array.isArray(dataItem) ? (Object.values(dataItem[0])[0] as string) : (Object.values(dataItem)[0] as string);
        const archivedUserClass = isParent && !dataItem.active ? 'text-red-500' : '';
        const showRow = (!isParent && dataItem.active) || isParent;
        return (
          showRow && (
            <tr
              key={index + trKey}
              className={`text-teacher-blue hover:bg-table/50 border-b-table/50 border-b-2 cursor-pointer ${archivedUserClass}`}
              onClick={() => onRowClick(dataItem)}
              tabIndex={0}
              role="button"
            >
              {renderCells(dataItem)}
            </tr>
          )
        );
      })}
    </tbody>
  );

  return (
    <table className="table-fixed font-medium -mx-6 md:-mx-0">
      {renderHeader()}
      {renderBody()}
    </table>
  );
};

export default Table;
