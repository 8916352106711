import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ScreenLayout } from 'components';
import { AccessLevel } from 'redux/authentication/enums';
import { ParentStep, TeacherStep, UnitAdminStep } from 'components/Users';

export const NewUserScreen: React.FC = () => {
  const { t } = useTranslation();
  const { accessLevel: role } = useParams();
  const breadcrumb = [
    {
      step: '/users',
      title: `${t('userScreen.title')}: ${t(`accessLevels.${role}`)}`,
    },
    {
      step: `/users/${role}/new`,
      title: t(`userScreen.addNew.${role}`),
    },
  ];

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      {
        {
          [AccessLevel.SuperAdmin]: <UnitAdminStep breadcrumb={breadcrumb} role={role as AccessLevel} />,
          [AccessLevel.UnitAdmin]: <UnitAdminStep breadcrumb={breadcrumb} role={role as AccessLevel} />,
          [AccessLevel.Teacher]: <TeacherStep breadcrumb={breadcrumb} role={role as AccessLevel} />,
          [AccessLevel.Parent]: <ParentStep breadcrumb={breadcrumb} />,
          [AccessLevel.Support]: <></>,
        }[role as AccessLevel]
      }
    </ScreenLayout>
  );
};
