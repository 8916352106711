import { useState, useMemo } from 'react';

import { useChildRelationshipToGroupAnalytics, useGroupsByUnitSlug, useUnits } from 'graphql-api';

export interface IUseChildRelationship {
  evaluationsFrom: string;
  evaluationsUntil: string;
}

export const useChildRelationship = (interval?: IUseChildRelationship) => {
  const [selectedUnitSlug, setSelectedUnitSlug] = useState<string>();
  const [selectedGroupSlug, setSelectedGroupSlug] = useState<string>();
  const [selectedChildSlug, setSelectedChildSlug] = useState<string>();

  const { data, loading: unitLoading } = useUnits();
  const { data: unitData, loading: groupLoading } = useGroupsByUnitSlug(selectedUnitSlug);

  const {
    data: analytics,
    error,
    loading: isLoadingAnalytics,
  } = useChildRelationshipToGroupAnalytics(selectedChildSlug, interval?.evaluationsFrom, interval?.evaluationsUntil);

  const units = useMemo(
    () =>
      data?.units
        .filter((item) => !!item.groups.length)
        .map((filteredItem) => ({
          slug: filteredItem.slug,
          name: filteredItem.name,
          id: filteredItem.id,
        })),
    [data],
  );

  const groups = useMemo(
    () =>
      unitData?.unitBySlug.groups
        .filter((item) => !!item.children.length)
        .map((filteredItem) => ({
          slug: filteredItem.slug,
          name: filteredItem.name,
        })),
    [unitData],
  );

  const children = useMemo(
    () =>
      unitData?.unitBySlug.groups
        .find((item) => item.slug === selectedGroupSlug)
        ?.children.map((item) => ({
          slug: item.slug,
          name: `${item.firstName} ${item.lastName}`,
        })),
    [unitData, selectedGroupSlug],
  );

  const setUnit = (unit: string) => {
    setSelectedUnitSlug(unit);
    setSelectedGroupSlug(undefined);
    setSelectedChildSlug(undefined);
  };

  const setGroup = (group: string) => {
    setSelectedGroupSlug(group);
    setSelectedChildSlug(undefined);
  };

  const childFromAnalytics = useMemo(() => children?.find((item) => item.slug === selectedChildSlug), [children, selectedChildSlug]);
  return {
    analytics,
    analyticsError: error,
    isLoadingUnits: unitLoading || groupLoading,
    isLoadingAnalytics,
    selectedUnitSlug,
    selectedGroupSlug,
    selectedChildSlug,
    units,
    groups,
    setSelectedChildSlug,
    setUnit,
    setGroup,
    children,
    childFromAnalytics,
  };
};
