import React from 'react';

import { useTranslation } from 'react-i18next';

import ChoiceGroup from 'components/ChoiceGroup';
import { getDiscussionSelectedAnswers } from 'utils/answers';
import { FormVersion, Question } from 'graphql-api';
import { Translation } from 'locales';
import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';

export interface OccuranceFormItemProps {
  formType: FormVersion;
  questionItem: Question;
  versionedRadioGroup: JSX.IntrinsicElements['input'][];
  formValue: { [key: string]: string | number };
  isError?: boolean;
  recapFormValue?: { [key: string]: boolean };
  onChange?: (key: string | number, value: number | string) => void;
  onRecapChange?: (key: string | number, value?: boolean) => void;
  lang: keyof Translation;
  disabledForm: boolean;
  isDiscussionOver: boolean;
  isSupport: boolean;
  selectedRole: AccessLevel;
}

const OccuranceFormItem: React.FC<OccuranceFormItemProps> = (props) => {
  const {
    questionItem,
    formType,
    versionedRadioGroup,
    onChange,
    onRecapChange = undefined,
    formValue,
    recapFormValue,
    isError = false,
    lang,
    disabledForm,
    isDiscussionOver,
    isSupport,
    selectedRole,
  } = props;
  const { t } = useTranslation();
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const onAgreedVisible = (isImportantToReach?: boolean) => {
    if (formType === FormVersion.RECAP || formType === FormVersion.DISCUSSION) {
      return isImportantToReach !== undefined;
    }
    return !!onRecapChange;
  };

  const agreeText = formType === FormVersion.DISCUSSION ? t('common.mainGoal') : t('common.achieved');
  const role = isSupport ? selectedRole : accessLevel;
  const userType = formType === FormVersion.DISCUSSION || formType === FormVersion.RECAP ? undefined : role;
  const question = questionItem.question[lang];

  return (
    <ChoiceGroup
      key={`${question}-${questionItem.id}`}
      containerClassName="mb-8"
      options={versionedRadioGroup.map((item, index) => ({
        value: item.value as number | string,
        id: `${question}-${index}`,
        name: `${question}-${index}`,
        label: t(`formOptions.${item.value}`),
      }))}
      onClick={(value: string) => {
        if (!disabledForm) {
          onChange && onChange(questionItem.id, +value);
        }
      }}
      selected={getDiscussionSelectedAnswers(questionItem, formValue, isDiscussionOver, userType)}
      label={question}
      isError={isError && !formValue[questionItem.id]}
      onAgreed={
        onAgreedVisible(recapFormValue && recapFormValue[questionItem.id])
          ? () => {
              onRecapChange && onRecapChange(questionItem.id, recapFormValue && !recapFormValue[questionItem.id]);
            }
          : undefined
      }
      isAgreed={recapFormValue && !!recapFormValue[questionItem.id]}
      agreeText={agreeText}
      formType={formType}
    />
  );
};

export default OccuranceFormItem;
