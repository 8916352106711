import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ActivateModal,
  BreadCrumb,
  Button,
  CreateGroupModal,
  GroupCard,
  Loader,
  Switch,
  TextInput,
  UnitAdminModal,
  ConfirmModal,
  FormStepArchiveButton,
} from 'components';
import { BreadCrumbData } from 'components/BreadCrumb';
import { AccessLevel } from 'redux/authentication/enums';
import {
  Group,
  Unit,
  useCreateUnit,
  useGroupsByUnitSlug,
  User,
  useUsersByRoleForUnitFormStep,
  useUpdateUnit,
  useDeleteUnit,
  useCreateGroup,
  useDeleteGroup,
  useUpdateGroup,
} from 'graphql-api';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left-icon.svg';
import { ReactComponent as PlusCircle } from 'assets/plus-circle-icon.svg';
import { showToast, ToastType } from 'utils/toast';
import { useAppSelector } from 'hooks/redux';

export interface UnitFormStepProps {
  unit?: Unit;
  breadcrumb: BreadCrumbData[];
  onClick?: (item?: Group) => void;
  refetch?: () => void;
}

export const UnitFormStep: React.FC<UnitFormStepProps> = ({ unit, breadcrumb, onClick, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const isSuperAdmin = accessLevel === AccessLevel.SuperAdmin;
  const isSupport = accessLevel === AccessLevel.Support;
  const { data } = useUsersByRoleForUnitFormStep(AccessLevel.UnitAdmin, { fetchPolicy: 'no-cache', skip: !isSuperAdmin });
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const [changedUnit, setChangedUnit] = useState<Unit>();
  const [selectedGroup, setSelectedGroup] = useState<Group>();

  const [groupToArchive, setGroupToArchive] = useState<Group>();

  const unitSlug = unit?.slug;
  const unitId = unit?.id;

  const { data: unitData, loading: groupLoading, refetch: refetchGroups } = useGroupsByUnitSlug(unitSlug);

  useEffect(() => {
    refetchGroups();
  }, [refetchGroups]);

  useEffect(() => {
    setChangedUnit(unit);
  }, [unit]);

  useEffect(() => {
    if (!isCreateGroupModalOpen) {
      setSelectedGroup(undefined);
    }
  }, [isCreateGroupModalOpen]);

  const [addGroup, { loading: addGroupLoading }] = useCreateGroup({
    onCompleted(addGroupData) {
      if (addGroupData.addGroup.id) {
        setIsCreateGroupModalOpen(false);
        refetchGroups();
        showToast({
          title: t('groupMaintenanceScreen.group_added'),
          type: ToastType.SUCCESS,
        });
      }
    },
  });

  const [editGroup, { loading: editGroupLoading }] = useUpdateGroup({
    onCompleted(editGroupData) {
      if (editGroupData.editGroup.id) {
        setIsCreateGroupModalOpen(false);
        refetchGroups();
        showToast({
          title: t('groupMaintenanceScreen.group_edited'),
          type: ToastType.SUCCESS,
        });
      }
    },
  });

  const [createUnit, { loading: createLoading }] = useCreateUnit({
    onCompleted(createUnitData) {
      if (createUnitData.createUnit.id) {
        refetch && refetch();
        navigate(`/units/${createUnitData.createUnit.slug}`);
        showToast({
          title: t('unitMaintenanceScreen.unit_added'),
          type: ToastType.SUCCESS,
        });
      }
    },
  });

  const [updateUnit, { loading: updateLoading }] = useUpdateUnit({
    onCompleted(updateUnitData) {
      if (updateUnitData.updateUnit.id) {
        refetch && refetch();
        if (!isActivateModalOpen) {
          showToast({
            title: t('unitMaintenanceScreen.unit_edited'),
            type: ToastType.SUCCESS,
          });
        }
        setIsActivateModalOpen(false);
      }
    },
    onError(e) {
      setIsActivateModalOpen(false);
      showToast({
        title: t(e.message),
        type: ToastType.ERROR,
      });
    },
  });

  const [deleteUnit] = useDeleteUnit({
    onCompleted(deleteUnitData) {
      if (deleteUnitData.deleteUnit.action) {
        refetch && refetch();
        navigate('/units');
        showToast({
          title: t('unitMaintenanceScreen.unit_deleted'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError() {
      showToast({
        title: t('unitMaintenanceScreen.delete_error'),
        type: ToastType.ERROR,
      });
    },
  });

  const [deleteGroup] = useDeleteGroup({
    onCompleted(deleteGroupData) {
      if (deleteGroupData.deleteGroup.action) {
        refetchGroups();
        showToast({
          title: t('groupMaintenanceScreen.group_deleted'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError() {
      showToast({
        title: t('groupMaintenanceScreen.delete_error'),
        type: ToastType.ERROR,
      });
    },
  });

  const removeUnit = () => {
    deleteUnit({ variables: { id: unitId } });
  };

  const createGroup = (groupName: string) => {
    addGroup({
      variables: {
        id: unitId,
        payload: {
          name: groupName,
        },
      },
    });
  };

  const addUnit = () => {
    createUnit({
      variables: {
        payload: {
          addressLine: changedUnit?.addressLine,
          city: changedUnit?.city,
          name: changedUnit?.name,
          zipCode: changedUnit?.zipCode,
          adminId: changedUnit?.admin?.id ? +changedUnit?.admin?.id : undefined,
        },
      },
    });
  };

  const editUnit = () => {
    updateUnit({
      variables: {
        id: changedUnit?.id,
        payload: {
          id: changedUnit?.id,
          addressLine: changedUnit?.addressLine,
          city: changedUnit?.city,
          name: changedUnit?.name,
          zipCode: changedUnit?.zipCode,
          canOpenEvaluations: isActivateModalOpen ? !changedUnit?.canOpenEvaluations : changedUnit?.canOpenEvaluations,
          adminId: changedUnit?.admin ? +changedUnit?.admin?.id : undefined,
        },
      },
    });
  };

  const updateGroup = (groupName: string) => {
    editGroup({
      variables: {
        id: selectedGroup ? +selectedGroup?.id : '',
        payload: {
          name: groupName,
        },
      },
    });
  };

  const onChange = (key: keyof Unit, value: string | User) => {
    const changedObject = changedUnit || ({} as Unit);
    setChangedUnit({
      ...changedObject,
      [key]: value,
    });
  };

  const onSelectAdmin = (admin: User) => {
    onChange('admin', admin);
    setIsAdminModalOpen(false);
  };

  const onGroupCardClick = (group: Group) => {
    if (group.children.length) {
      onClick && onClick(group);
    } else {
      setSelectedGroup(group);
      setIsCreateGroupModalOpen(true);
    }
  };

  const archiveGroup = () => {
    setGroupToArchive(undefined);

    if (groupToArchive) {
      deleteGroup({ variables: { groupId: +groupToArchive.id, id: groupToArchive.unitId } });
    }
  };

  const disableSaveChanges = !changedUnit?.addressLine || !changedUnit?.city || !changedUnit?.name || !changedUnit?.zipCode;

  return (
    <>
      <div className="flex items-center justify-between relative mb-4">
        <ArrowLeft className="absolute md:-left-10 cursor-pointer" onClick={() => navigate(-1)} />
        <div className="text-teacher-blue text-xl md:text-3xl font-bold ml-10 md:ml-0">{!unit ? t('unitScreen.newUnit') : unit.name}</div>
        {unit && <FormStepArchiveButton onConfirm={removeUnit} label={t('unitMaintenanceScreen.delete')} disabled={isSupport} />}
      </div>
      <BreadCrumb data={breadcrumb} className="mb-4 md:mb-8" />
      <div className="md:grid md:grid-cols-2 md:gap-24">
        <div className="flex flex-col">
          <div className="text-teacher-blue text-xl font-bold mb-3 md:mb-6">{t('unitScreen.unitInfo')}</div>
          <ActivateModal
            visible={isActivateModalOpen}
            openModal={setIsActivateModalOpen}
            currentStatus={!!changedUnit?.canOpenEvaluations}
            onSave={editUnit}
            loading={updateLoading}
          />
          {!!unit && (
            <Switch
              onChange={() => setIsActivateModalOpen(true)}
              checked={!!changedUnit?.canOpenEvaluations}
              title={t(`unitScreen.${unit?.canOpenEvaluations ? 'active' : 'inactive'}Switch`)}
              disabled={isSupport}
            />
          )}
          {isSuperAdmin && (
            <UnitAdminModal
              users={data?.users}
              visible={isAdminModalOpen}
              openModal={setIsAdminModalOpen}
              onSelect={onSelectAdmin}
              selectedAdmin={changedUnit?.admin}
            />
          )}
          {changedUnit?.admin && (
            <>
              <div className="mt-6 mb-2 text-teacher-blue text-base font-semibold">{t('accessLevels.UnitAdmin').toUpperCase()}</div>
              <div className="ml-2 mb-6 text-teacher-blue text-base font-bold">{`${changedUnit?.admin.firstName} ${changedUnit?.admin.lastName}`}</div>
            </>
          )}
          {isSuperAdmin && (
            <Button
              variant="tertiary"
              labelClassName="!text-teacher-blue w-full flex justify-center"
              className="w-fit"
              onClick={() => setIsAdminModalOpen(true)}
            >
              <PlusCircle className="mr-2" />
              {t('unitScreen.chooseAdmin')}
            </Button>
          )}
          <TextInput
            label={t('unitScreen.name')}
            value={changedUnit?.name}
            onChange={(e) => onChange('name', e.target.value)}
            containerClassName="mt-4 mb-4"
            disabled={isSupport}
          />
          <div className="flex mb-4">
            <TextInput
              label={t('unitScreen.zip')}
              value={changedUnit?.zipCode}
              onChange={(e) => onChange('zipCode', e.target.value)}
              containerClassName="flex-none w-24 mr-8"
              disabled={isSupport}
            />
            <TextInput
              label={t('unitMaintenanceScreen.city')}
              value={changedUnit?.city}
              onChange={(e) => onChange('city', e.target.value)}
              containerClassName="grow"
              disabled={isSupport}
            />
          </div>
          <TextInput
            label={t('unitMaintenanceScreen.address_line')}
            value={changedUnit?.addressLine}
            onChange={(e) => onChange('addressLine', e.target.value)}
            disabled={isSupport}
          />
          <div className="flex items-center justify-around mt-8 md:mt-16 gap-6">
            <Button className="w-32 md:w-56" variant="secondary" onClick={() => navigate(-1)}>
              {t('common.cancel')}
            </Button>
            <Button
              className="w-32 md:w-56"
              variant="primary"
              disabled={disableSaveChanges || isSupport}
              onClick={() => (unit ? editUnit() : addUnit())}
              icon={<Loader isLoading={updateLoading || createLoading} isSmall className="mr-2" color="white" />}
            >
              {t('common.saveChanges')}
            </Button>
          </div>
        </div>
        <div>
          <div className="text-teacher-blue text-xl font-bold mb-6 mt-8">{t('unitScreen.groupsInTheUnit')}</div>
          <Loader isLoading={groupLoading}>
            {!!unitData && (
              <>
                <ConfirmModal
                  isVisible={!!groupToArchive}
                  onCancel={() => setGroupToArchive(undefined)}
                  onConfirm={archiveGroup}
                  title={t('common.deleteSubtitle')}
                />
                {unitData.unitBySlug.groups.map((group) => (
                  <GroupCard
                    key={group.id}
                    group={group}
                    numberOfChildren={group.children.length}
                    onEdit={() => onGroupCardClick(group)}
                    onDelete={() => setGroupToArchive(group)}
                    isSupport={isSupport}
                  />
                ))}
              </>
            )}
          </Loader>
          <CreateGroupModal
            openModal={setIsCreateGroupModalOpen}
            visible={isCreateGroupModalOpen}
            onSelect={(groupName) => (selectedGroup ? updateGroup(groupName) : createGroup(groupName))}
            isLoading={addGroupLoading || editGroupLoading}
            group={selectedGroup}
          />
          {unit ? (
            <div className="flex justify-center">
              <Button
                variant="tertiary"
                labelClassName="!text-teacher-blue w-full flex justify-center"
                className="w-fit"
                onClick={() => setIsCreateGroupModalOpen(true)}
                disabled={isSupport}
              >
                <PlusCircle className="mr-2" />
                {t('unitScreen.createGroup')}
              </Button>
            </div>
          ) : (
            <div className="text-teacher-blue italic">{t('unitScreen.createUnitFirst')}</div>
          )}
        </div>
      </div>
    </>
  );
};
