import { Store } from 'redux';

import Client from 'api/Client';
import { AppThunk, persistor, store } from 'redux/store';

import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  RESTORE_PASSWORD_FAILURE,
  RESTORE_PASSWORD_REQUEST,
  RESTORE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  VALIDATE_HASH_FAILURE,
  VALIDATE_HASH_REQUEST,
  VALIDATE_HASH_SUCCESS,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  ACCEPT_GDPR,
  AUTH_TOAST_INIT,
} from './types';
import { UserAuth } from './interfaces';

export const loginUser =
  (userCreds: UserAuth): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch({
      type: LOGIN_REQUEST,
    });

    try {
      const token = await Client.login(userCreds);
      const userData = await Client.getUserData();
      const user = {
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
        accessLevel: userData.accessLevel,
        userId: userData.userId,
        unitId: userData.unitId,
        email: userData.username,
        acceptedGDPR: userData.acceptedGDPR,
      };
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { ...user },
      });
    } catch (err: any) {
      dispatch({
        type: LOGIN_FAILURE,
        error: err,
      });
    }
  };

export const logOutUser =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    persistor.purge();
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  };

export const restorePassword =
  (hash: string, password: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch({
      type: RESTORE_PASSWORD_REQUEST,
    });

    try {
      await Client.restorePassword(hash, password);
      dispatch({
        type: RESTORE_PASSWORD_SUCCESS,
      });
    } catch (err: any) {
      dispatch({
        type: RESTORE_PASSWORD_FAILURE,
        error: err,
      });
    }
  };

export const forgotPassword =
  (email: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });

    try {
      await Client.forgotPassword(email);
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
      });
    } catch (err: any) {
      dispatch({
        type: FORGOT_PASSWORD_FAILURE,
        error: err,
      });
    }
  };

export const validateHash =
  (hash: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch({
      type: VALIDATE_HASH_REQUEST,
    });

    try {
      await Client.validateHash(hash);
      dispatch({
        type: VALIDATE_HASH_SUCCESS,
      });
    } catch (err: any) {
      dispatch({
        type: VALIDATE_HASH_FAILURE,
        error: err,
      });
    }
  };

export const refreshToken =
  (refreshTokenValue: string, onTokenRefreshed: (store: Store) => void): AppThunk =>
  async (dispatch) => {
    dispatch({
      type: REFRESH_TOKEN_REQUEST,
    });

    try {
      const response = await Client.refreshToken(refreshTokenValue);
      dispatch({
        type: REFRESH_TOKEN_SUCCESS,
        payload: {
          refreshToken: response.refreshToken,
          accessToken: response.accessToken,
        },
      });
      onTokenRefreshed(store);
    } catch (error: any) {
      dispatch({
        type: REFRESH_TOKEN_FAILURE,
        error: error.message,
      });
    }
  };

export const authToastInit =
  (): AppThunk =>
  (dispatch): void => {
    dispatch({
      type: AUTH_TOAST_INIT,
    });
  };

export const acceptGDPR = () => ({
  type: ACCEPT_GDPR,
});
