import React from 'react';

import type { User } from 'graphql-api';
import { normalizedString } from 'utils/helpers';

export const useUserSearch = (users: User[]) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  const searchablesMap = React.useMemo(() => {
    const newMap = new Map<number | string, string[]>();

    users.forEach((user) => {
      const searchables = getNormalizedSearchables(user);
      newMap.set(user.id, searchables);
    });

    return newMap;
  }, [users]);

  const [filteredUsers, setFilteredUsers] = React.useState<User[]>([]);

  const searchDebounceMilliseconds = 300;

  React.useEffect(() => {
    const filterTimeoutId = setTimeout(() => {
      setFilteredUsers(
        getFilteredUsers({
          searchTerm,
          users,
          searchablesMap,
        }),
      );
    }, searchDebounceMilliseconds);

    return () => clearTimeout(filterTimeoutId);
  }, [users, searchTerm, searchablesMap]);

  return {
    searchTerm,
    setSearchTerm,
    filteredUsers,
  };
};

interface IFilterUsers {
  users: User[];
  searchTerm: string;
  searchablesMap: Map<number | string, string[]>;
}

const getFilteredUsers = ({ users, searchTerm, searchablesMap }: IFilterUsers): User[] => {
  if (!searchTerm) {
    return users;
  }

  const normalizedSearchTerm = normalizedString(searchTerm).toLowerCase();

  const filteredUsers = users.filter((user) => {
    const searchables = searchablesMap.get(user.id) || [];
    return searchables.some((searchable) => searchable.includes(normalizedSearchTerm));
  });

  return filteredUsers;
};

const getNormalizedSearchables = (user: User) => {
  const ownedUnitSearchables = user.ownedUnits?.map((unit) => [`${unit.id}`, unit.name, unit.slug]) || [];
  const childrenSearchables = user.children?.map((child) => [`${child.id}`, `${child.firstName} ${child.lastName}`, child.slug]) || [];
  const unitSearchables = user.unit ? [`${user.unit.id}`, user.unit.name, user.unit.slug] : [];

  const flatOwnedUnitSearchables = ownedUnitSearchables.flat();
  const flatChildrenSearchables = childrenSearchables.flat();

  const normalizedSearchables = [
    `${user.firstName} ${user.lastName}`,
    `${user.id}`,
    user.email || '',
    user.slug,
    ...unitSearchables,
    ...flatOwnedUnitSearchables,
    ...flatChildrenSearchables,
  ].map((searchable) => normalizedString(searchable).toLowerCase());

  return normalizedSearchables;
};
