import React from 'react';

import moment from 'moment';

import type { ConsultingEvent } from 'graphql-api';

export interface MonthEventProps {
  title: string;
  event: ConsultingEvent;
}

const MonthEvent: React.FC<MonthEventProps> = (props) => {
  const extendedTitle = `${moment(props.event.startsAt).format('HH:mm')} ${props.title}`;

  return (
    <div title={extendedTitle} className="hidden sm:block pointer-events-none sm:pointer-events-auto text-sm">
      {extendedTitle}
    </div>
  );
};

export default MonthEvent;
