import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import NavigationRoute from 'navigation/NavigationRoute';
import { Button, ChildSupport, FormGroup, Loader, Modal, TextArea } from 'components';
import { CommentsAndGoals, Evaluation, FormVersion } from 'graphql-api';
import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';
import { ReactComponent as Print } from 'assets/print.svg';
import Colors from 'utils/colors';
import { pageMargin } from 'utils/print';
import moment from 'moment';

export interface submitAnswerProp {
  formEvent?: React.FormEvent;
  saveUnfinished?: boolean;
  isTeacher?: boolean;
  isVasu?: boolean;
}

export interface EvaluationFormProps {
  submitAnswer: (e: submitAnswerProp) => void;
  formGroup: Evaluation | null;
  formValue: { [key: string]: string | number };
  setFormValue: React.Dispatch<React.SetStateAction<{ [key: string]: string | number }>>;
  answerLoading: boolean;
  disabled: boolean;
  error: boolean;
  formType: FormVersion;
  importanceValues?: { [key: string]: boolean };
  recapFormValue?: { [key: string]: boolean };
  commentFormValue?: { [key: string]: string };
  additionalData?: CommentsAndGoals;
  setImportanceValues?: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  setRecapFormValue?: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  setCommentFormValue?: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setAdditionalData?: React.Dispatch<React.SetStateAction<CommentsAndGoals>>;
  disabledForm: boolean;
  supportValue: string | null;
  setSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  evaluationSupportValue: string | null;
  setEvaluationSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  participatedSupportValue: string | null;
  setParticipatedSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  printRef: React.MutableRefObject<null>;
  sendParentSignDiscussionForm: () => void;
  sendParentSignRecapForm: () => void;
  isSupport: boolean;
  selectedRole: AccessLevel;
}

const EvaluationForm: React.FC<EvaluationFormProps> = ({
  setImportanceValues,
  setRecapFormValue,
  submitAnswer,
  formGroup,
  formValue,
  setFormValue,
  error,
  answerLoading,
  formType,
  recapFormValue,
  importanceValues,
  commentFormValue,
  setCommentFormValue,
  setAdditionalData,
  additionalData,
  disabledForm,
  supportValue,
  setSupportValue,
  evaluationSupportValue,
  setEvaluationSupportValue,
  participatedSupportValue,
  setParticipatedSupportValue,
  printRef,
  sendParentSignDiscussionForm,
  sendParentSignRecapForm,
  isSupport,
  selectedRole,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState(false);
  const { accessLevel } = useAppSelector(({ auth }) => auth);

  useEffect(() => {
    if (error && isOpened) {
      setIsOpened(false);
    }
  }, [isOpened, error]);

  const onRecapChange = (key: string | number, value?: boolean): void | undefined => {
    if (accessLevel !== AccessLevel.Parent) {
      if (formType === FormVersion.DISCUSSION && setImportanceValues && !disabledForm) {
        setImportanceValues({ ...importanceValues, [key]: value || false });
      }
      if (formType === FormVersion.RECAP && setRecapFormValue && !disabledForm) {
        setRecapFormValue({ ...recapFormValue, [key]: value || false });
      }
    }
  };

  const getRecapDiscussionValues = () => {
    if (formType === FormVersion.DISCUSSION) {
      return importanceValues;
    }
    if (formType === FormVersion.RECAP) {
      return recapFormValue;
    }
    return undefined;
  };

  const onCommentChange = (key: string | number, value: string) => {
    if (setCommentFormValue) {
      setCommentFormValue({ ...commentFormValue, [key]: value });
    }
    return undefined;
  };

  const onAdditionalDataChange = (key: keyof CommentsAndGoals, value: string) => {
    if (setAdditionalData && additionalData) {
      setAdditionalData({
        ...additionalData,
        [key]: value,
      });
    }
  };

  const onChange = (key: string | number, value: string | number) => {
    const isParentEdit = accessLevel === AccessLevel.Parent && (formType === FormVersion.VASU || formType === FormVersion.PARENTAL);
    const isOtherEdit = accessLevel !== AccessLevel.Parent && formType !== FormVersion.RECAP;
    if (isParentEdit || isOtherEdit) {
      setFormValue({ ...formValue, [key]: value });
    }
  };

  const onRecapChangeVisible = formType === FormVersion.DISCUSSION || formType === FormVersion.RECAP;
  const isParentSaveVisible = (accessLevel === AccessLevel.Parent && formType === FormVersion.VASU) || formType === FormVersion.PARENTAL;
  const isOtherVisible = accessLevel !== AccessLevel.Parent;
  const isTeacher = accessLevel === AccessLevel.Teacher;
  const isSaveVisible = isOtherVisible || isParentSaveVisible;

  const isDicussion = formType === FormVersion.DISCUSSION;
  const shouldShowSignInsteadOfSend = isDicussion;

  const isRecap = formType === FormVersion.RECAP;
  const isVasu = formType === FormVersion.VASU;
  const shouldShowPrint = (isDicussion || isRecap) && disabledForm && formGroup?.form.parentHasSigned;
  const isParentSignVisible = disabledForm && accessLevel === AccessLevel.Parent;

  const parentSaveButtonText = FormVersion.VASU === formType ? t('common.saveAndContinue') : t('common.saveAndSend');

  const saveButtonText = selectedRole === AccessLevel.Parent || accessLevel === AccessLevel.Parent ? parentSaveButtonText : t('common.saveAndSend');

  const finishFormButtonText = shouldShowSignInsteadOfSend ? t('common.saveAndSign') : saveButtonText;
  const finishFormModalText =
    shouldShowSignInsteadOfSend || ((isDicussion || isRecap) && isParentSignVisible) ? t('common.areYouSureSaveSign') : t('common.areYouSureSaveSend');

  const agreeOnclick =
    isParentSignVisible && isRecap
      ? () => sendParentSignRecapForm()
      : isParentSignVisible && isDicussion
      ? () => sendParentSignDiscussionForm()
      : () => submitAnswer({ formEvent: undefined, saveUnfinished: false, isTeacher, isVasu });

  const signStatusParent = isRecap && disabledForm && formGroup?.form.parentHasSigned;
  const signStatusTeacher = isRecap && disabledForm && formGroup?.form.teacherHasSigned;
  const signStatus = signStatusParent && signStatusTeacher && formGroup.form?.parentSignedDate && formGroup.form?.teacherSignedDate;

  return (
    <form
      onSubmit={(e) => submitAnswer({ formEvent: e, saveUnfinished: true, isTeacher, isVasu })}
      onReset={() => navigate(NavigationRoute.EcecPlan)}
      className="pb-24"
    >
      <style>{pageMargin}</style>
      <div className="py-4 border-top">
        {formGroup && (
          <FormGroup
            formGroup={formGroup}
            formValue={formValue}
            onChange={onChange}
            isError={error}
            formType={formType}
            recapFormValue={getRecapDiscussionValues()}
            onRecapChange={onRecapChangeVisible ? (key, value) => onRecapChange(key, value) : undefined}
            commentFormValue={commentFormValue}
            onCommentChange={onCommentChange}
            disabledForm={disabledForm}
            isParent={accessLevel === AccessLevel.Parent}
            isSupport={isSupport}
            selectedRole={selectedRole}
          />
        )}
        {formType === FormVersion.DISCUSSION && accessLevel !== AccessLevel.Parent && (
          <div className="grid md:grid-cols-2 gap-6 md:gap-24 my-4">
            <TextArea
              className="mb-3 w-100"
              label={t('comments_and_goals_screen.comment')}
              onChange={(e) => onAdditionalDataChange('comments', e.target.value)}
              value={additionalData?.comments}
              isError={error && !additionalData?.comments}
              disabled={disabledForm}
            />
            <TextArea
              className="mb-3 w-100"
              label={t('comments_and_goals_screen.goal')}
              onChange={(e) => onAdditionalDataChange('goals', e.target.value)}
              value={additionalData?.goals}
              isError={error && !additionalData?.goals}
              disabled={disabledForm}
            />
          </div>
        )}
        {accessLevel !== AccessLevel.Parent && (
          <>
            <ChildSupport
              supportValue={supportValue}
              setSupportValue={setSupportValue}
              disabledForm={disabledForm}
              formType={formType}
              formGroup={formGroup}
              supportKey="support"
              isError={error}
            />
            <ChildSupport
              supportValue={participatedSupportValue}
              setSupportValue={setParticipatedSupportValue}
              disabledForm={disabledForm}
              formType={formType}
              formGroup={formGroup}
              supportKey="otherParticipated"
              isError={error}
            />
            <ChildSupport
              supportValue={evaluationSupportValue}
              setSupportValue={setEvaluationSupportValue}
              disabledForm={disabledForm}
              formType={formType}
              formGroup={formGroup}
              supportKey="evaluationOfSupport"
              isError={error}
            />
          </>
        )}
      </div>
      <Loader isLoading={answerLoading}>
        <Modal visible={isOpened} title={finishFormModalText} fixHeight={false} className="max-w-[350px] md:max-w-full print:hidden">
          <div className="flex items-center w-full bg-white">
            <Button variant="tertiary" type="button" onClick={() => setIsOpened(false)} labelClassName="!text-teacher-blue">
              {t('common.cancel')}
            </Button>
            <Button variant="tertiary" type="button" onClick={agreeOnclick} labelClassName="font-bold !text-teacher-blue">
              {t('common.agree')}
            </Button>
          </div>
        </Modal>
        {isSaveVisible && (
          <Button
            type="button"
            disabled={disabledForm}
            onClick={() => {
              if (!error) {
                setIsOpened(true);
              }
            }}
            className="!w-full mt-auto mb-6 print:hidden"
          >
            {finishFormButtonText}
          </Button>
        )}
        {isDicussion && isParentSignVisible && (
          <Button
            type="button"
            className="!w-full mt-auto mb-6 print:hidden"
            disabled={formGroup?.form.teacherHasSigned && formGroup?.form.parentHasSigned}
            onClick={() => {
              if (!error) {
                setIsOpened(true);
              }
            }}
          >
            {t('common.sign')}
          </Button>
        )}
        {isRecap && isParentSignVisible && (
          <Button
            type="button"
            className="!w-full mt-auto mb-6 print:hidden"
            disabled={formGroup?.form.teacherHasSigned && formGroup?.form.parentHasSigned}
            onClick={() => {
              if (!error) {
                setIsOpened(true);
              }
            }}
          >
            {t('common.sign')}
          </Button>
        )}
        {shouldShowPrint && (
          <ReactToPrint
            trigger={() => (
              <Button
                type="button"
                className="!w-full !bg-white print:hidden"
                disabled={isSupport}
                variant="secondary"
                icon={<Print stroke={Colors.teacherBlue} />}
              >
                {t('common.printPdf')}
              </Button>
            )}
            content={() => printRef.current}
          />
        )}
        <div className="mt-5 flex items-center justify-around text-xl md:text-5xl font-bold mb-3 md:mb-8 ml-10 md:ml-0 print:ml-0">
          {signStatus && (
            <div className="items-center text-teacher-blue ml-4 text-xs">{`${t('common.parent')}: ${formGroup?.form?.parentSignedName}, ${t('common.date')}: ${
              formGroup.form?.parentSignedDate && moment(formGroup.form.parentSignedDate).format('DD/MM/YYYY HH:mm:ss')
            }`}</div>
          )}
          {signStatus && (
            <div className="items-center text-teacher-blue ml-4 text-xs">{`${t('common.teacher')}: ${formGroup?.form?.teacherSignedName}, ${t(
              'common.date',
            )}: ${formGroup.form?.teacherSignedDate && moment(formGroup.form.teacherSignedDate).format('DD/MM/YYYY HH:mm:ss')}`}</div>
          )}
        </div>
        {!disabledForm && (
          <div className="fixed w-full bottom-0 right-0 md:pl-48 print:hidden">
            <div className="flex justify-center gap-2 p-6 bg-white">
              <Button variant="secondary" type="reset">
                {t('common.cancel')}
              </Button>
              <div className="col-2" />
              {!disabledForm && isSaveVisible && (
                <Button variant="primary" className="col-5" type="submit">
                  {t('common.saveProgress')}
                </Button>
              )}
            </div>
          </div>
        )}
      </Loader>
    </form>
  );
};

export default EvaluationForm;
