import React, { PropsWithChildren } from 'react';

export interface TimeItemProps {
  children: React.ReactNode;
  isTime?: boolean;
}

const TimeItem: React.FC<PropsWithChildren<TimeItemProps>> = ({ children, isTime = false }) => (
  <div className={`flex justify-center items-center h-10 ${isTime ? 'w-12' : ''}`}>{children}</div>
);

export default TimeItem;
