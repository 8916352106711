import React, { FC } from 'react';

import { Loader } from 'components';
import { User, Group, Unit } from 'graphql-api';

interface Props {
  onChange: (key: keyof User, value: string | Date | Unit | Group[]) => void;
  onNext: () => void;
  loading: boolean;
  filteredUnits: Unit[];
}

const UnitPickerStep: FC<Props> = ({ onChange, onNext, loading, filteredUnits }) => {
  return (
    <Loader isLoading={loading}>
      {filteredUnits.map((item) => (
        <button
          key={item.id}
          className="text-teacher-bluehover:bg-background py-2 pl-2 text-left"
          onClick={() => {
            onChange('unit', item);
            onNext();
          }}
        >
          {item.name}
        </button>
      ))}
    </Loader>
  );
};

export default UnitPickerStep;
