import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Circle } from 'assets/circle.svg';
import colors from 'utils/colors';

export interface AgeGroupProps {
  ageGroup?: number;
  className?: string;
}

const AgeGroup: React.FC<AgeGroupProps> = ({ ageGroup, className }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center mb-3 md:mb-6 pl-10 md:pl-0">
      <div className={`relative w-5 h-5 md:w-8 md:h-8 rounded-full text-teacher-blue font-bold text-sm md:text-xl ${className}`}>
        <Circle width={32} height={32} fill={colors.ageGroup} className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 scale-90 md:scale-110" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">{ageGroup}</div>
      </div>
      <div className="ml-2 text-teacher-blue font-bold text-sm md:text-xl">{t(`ageGroups.${ageGroup}`)}</div>
    </div>
  );
};

export default AgeGroup;
