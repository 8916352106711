import React, { useRef } from 'react';

import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';

import { BarChart, Button, Chart } from 'components';
import { Analytics, AnalyticsChartType, FormVersion } from 'graphql-api';
import { Translation } from 'locales';
import { createChartData } from 'utils/form';
import { ReactComponent as Print } from 'assets/print.svg';
import colors from 'utils/colors';
import { pageMargin } from 'utils/print';

export interface ChartPDFProps {
  pieChartData?: {
    analytics?: Analytics;
  };
  analyticsType: AnalyticsChartType;
  title: string;
  formVersion?: FormVersion;
}

const ChartPDF: React.FC<ChartPDFProps> = ({ pieChartData, title, formVersion = FormVersion.PARENTAL, analyticsType }) => {
  const { t, i18n } = useTranslation();
  const componentRef = useRef(null);
  let chartNumber = 0;
  const lang = i18n.language as keyof Translation;

  const shouldDisablePrint = !pieChartData?.analytics;

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Button className="mt-auto w-auto px-2" disabled={shouldDisablePrint}>
            <Print stroke={colors.white} />
          </Button>
        )}
        content={() => componentRef.current}
      />
      <style>{pageMargin}</style>
      <div className="hidden print:block" ref={componentRef}>
        <div className="container mb-5">
          <div className="mb-12 flex justify-center font-extrabold text-base text-teacher-blue">{title}</div>
          {pieChartData?.analytics
            ? pieChartData.analytics.forms.map((item, index) => (
                <div key={`analytics-${index}`} className="grid grid-cols-2 gap-2 print:print-break-inside-avoid">
                  <div className="col-span-2 mb-8 flex justify-center font-extrabold text-base text-teacher-blue uppercase">{item.name[lang]}</div>
                  {item.questions.map((questionItem, questionIndex) => {
                    chartNumber += 1;
                    return (
                      <div
                        key={`analytics-question-${index}-${questionIndex}`}
                        className={`print:print-break-inside-avoid ${
                          analyticsType === AnalyticsChartType.BAR ? 'h-[260px]' : 'h-[160px]'
                        } my-1 bg-white rounded-lg border-2 px-2 pt-2 pb-0 border-admin-blue`}
                      >
                        {questionItem.question && (
                          <div className="flex justify-center font-extrabold text-base text-teacher-blue uppercase">{questionItem.question[lang]}</div>
                        )}
                        <div className="my-3 flex justify-center">
                          {
                            {
                              [AnalyticsChartType.BAR]: <BarChart data={createChartData(questionItem, t, FormVersion.VASU, true)} />,
                              [AnalyticsChartType.PIE]: <Chart data={createChartData(questionItem, t, FormVersion.PARENTAL)} formVersion={formVersion} />,
                            }[analyticsType]
                          }
                        </div>
                        {chartNumber % 4 === 0 && <div className="print:block print:break-before-page" />}
                      </div>
                    );
                  })}
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default ChartPDF;
