import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ScreenLayout } from 'components';
import { UnitListStep } from 'components/Units';
import { Unit, useUnits } from 'graphql-api';

export const UnitListScreen: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading } = useUnits({ fetchPolicy: 'no-cache' });

  const onUnitClick = (unitItem?: Unit) => {
    navigate(unitItem ? `/units/${unitItem?.slug}` : '/units/new');
  };

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <UnitListStep data={data} loading={loading} onClick={onUnitClick} />
    </ScreenLayout>
  );
};
