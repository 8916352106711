import { useIdleTimer } from 'react-idle-timer';

import dayjs from 'dayjs';
import { idleLogoutTimeoutMilliseconds, idleLogoutTimeoutMillisecondsSuperAdmin } from 'utils/constants';
import { logOutUser } from 'redux/authentication/authentication.actions';

import { useEffect } from 'react';
import { AccessLevel } from 'redux/authentication/enums';
import { useAppDispatch, useAppSelector } from './redux';

export const useIdleLogout = () => {
  const dispatch = useAppDispatch();
  const { accessLevel, userId } = useAppSelector(({ auth }) => auth);
  const idleTimeOut = accessLevel === AccessLevel.SuperAdmin ? idleLogoutTimeoutMillisecondsSuperAdmin : idleLogoutTimeoutMilliseconds;

  const getLastActivity = () => {
    return localStorage.getItem('lastActivity');
  };

  const setLastActivity = (time: any) => {
    return localStorage.setItem('lastActivity', time);
  };

  const onIdle = () => {
    dispatch(logOutUser());
    localStorage.removeItem('lastActivity');
  };

  const { pause, start, getLastActiveTime } = useIdleTimer({
    onIdle,
    eventsThrottle: 500,
    timeout: idleTimeOut,
    crossTab: true,
    events: ['click', 'scroll', 'mousemove'],
    syncTimers: 200,
    startManually: true,
    name: 'idle-timer',
    promptTimeout: idleTimeOut,
  });

  const timeCheck = () => {
    const persistedActivity = dayjs(getLastActivity());
    const now = dayjs();
    if (getLastActivity() && now.diff(persistedActivity) > idleTimeOut) {
      onIdle();
    }
  };

  useEffect(() => {
    let interval: any;

    timeCheck();
    if (userId) {
      interval = setInterval(() => {
        setLastActivity(getLastActiveTime());
        timeCheck();
      }, 1000);

      start();
    } else {
      pause();
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
};
