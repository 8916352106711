import { useMemo, useState } from 'react';

import { ChildInfoForm, useAddAnswer, Evaluation, FormVersion, useParentSignDiscussionForm, useParentSignRecapForm } from 'graphql-api';
import { useTranslation } from 'react-i18next';

import { getAnswerId, getMultipleChoiceAnswers, getTextAnswers, getVASUFormValues } from 'utils/answers';
import { showToast, ToastType } from 'utils/toast';
import { AccessLevel } from 'redux/authentication/enums';

export interface IUseEvaluation {
  formGroup: Evaluation | null;
  formValue: { [key: string]: string | number };
  setFormValue: React.Dispatch<React.SetStateAction<{ [key: string]: string | number }>>;
  setSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  accessLevel: AccessLevel;
  skip: boolean;
  refetch: () => void;
  formVersion: FormVersion;
  supportValue: string | null;
  evaluationSupportValue: string | null;
  setEvaluationSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  participatedSupportValue: string | null;
  setParticipatedSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  forcedParent?: boolean;
}

const useEvaluation = (props: IUseEvaluation) => {
  const {
    formGroup,
    setFormValue,
    formValue,
    accessLevel,
    skip,
    refetch,
    formVersion,
    setSupportValue,
    supportValue,
    setEvaluationSupportValue,
    evaluationSupportValue,
    setParticipatedSupportValue,
    participatedSupportValue,
  } = props;
  const { t } = useTranslation();
  const [childFormValue, setChildFormValue] = useState<ChildInfoForm>({
    id: '',
    enrollmentDate: undefined,
    healthAndOtherReport: undefined,
  });
  const setVasuOnLoad = () => {
    if (formGroup) {
      const formValueItems = getVASUFormValues(formGroup, accessLevel);
      setFormValue(formValueItems);
      setSupportValue(formGroup.support?.ecec || null);
      setEvaluationSupportValue(formGroup.evaluationOfSupport?.ecec || null);
      setParticipatedSupportValue(formGroup.otherParticipated?.ecec || null);
      setChildFormValue({
        id: formGroup.child.id || '',
        enrollmentDate: formGroup.child.enrollmentDate ? new Date(formGroup.child.enrollmentDate) : new Date(),
        healthAndOtherReport: formGroup.child.healthAndOtherReport,
      });
    }
  };

  const vasuTeacherDisabled = useMemo(
    () =>
      !formGroup ||
      !Object.keys(formValue).every((key) => formValue[key]) ||
      !childFormValue.enrollmentDate ||
      !supportValue ||
      !evaluationSupportValue ||
      !participatedSupportValue,
    [formGroup, formValue, childFormValue, supportValue, evaluationSupportValue, participatedSupportValue],
  );

  const vasuDisabled = useMemo(
    () => (accessLevel === AccessLevel.Parent ? !formGroup || !Object.keys(formValue).every((key) => formValue[key]) : vasuTeacherDisabled),
    [formGroup, formValue, accessLevel, vasuTeacherDisabled],
  );

  const [hasSavedAnswers, setHasSavedAnswers] = useState(false);

  const [addAnswer, { loading: answerLoading }] = useAddAnswer({
    onCompleted() {
      refetch();
      showToast({
        title: t('common.form_filled'),
        type: ToastType.SUCCESS,
      });
      setHasSavedAnswers(true);
    },
  });

  const [parentSignDiscussionForm, { loading: isParentSignLoading }] = useParentSignDiscussionForm({
    onCompleted() {
      refetch();
      showToast({
        title: t('common.form_filled'),
        type: ToastType.SUCCESS,
      });
    },
  });

  const sendVasuAnswer = (sign?: boolean) => {
    if (!formGroup) return null;

    const answers = Object.keys(formValue).map((key) => ({
      answer: formValue[key],
      questionId: +key,
      id: formGroup ? getAnswerId(formGroup, +key, accessLevel) : null,
    }));

    const multipleChoiceAnswers = getMultipleChoiceAnswers(formGroup, answers);
    const textAnswers = getTextAnswers(formGroup, answers);
    addAnswer({
      variables: {
        id: formGroup?.id,
        payload: {
          multipleChoiceAnswers,
          textAnswers,
          sign,
          formType: formVersion,
          support: supportValue,
          evaluationOfSupport: evaluationSupportValue,
          otherParticipated: participatedSupportValue,
        },
      },
    });
  };

  const sendParentSignDiscussionForm = () => {
    if (!formGroup) return null;

    parentSignDiscussionForm({
      variables: {
        id: formGroup?.id,
        sign: true,
      },
    });
  };

  const [parentSignRecapForm] = useParentSignRecapForm({
    onCompleted() {
      refetch();
      showToast({
        title: t('common.form_filled'),
        type: ToastType.SUCCESS,
      });
    },
  });

  const sendParentSignRecapForm = () => {
    if (!formGroup) return null;
    parentSignRecapForm({
      variables: {
        id: formGroup?.id,
        sign: true,
      },
    });
  };

  const isAnyAnswerLoading = answerLoading || isParentSignLoading;

  return skip
    ? {
        vasuDisabled: undefined,
        sendVasuAnswer: undefined,
        sendParentSignDiscussionForm,
        setVasuOnLoad: undefined,
        answerLoading: undefined,
        childFormValue: undefined,
        setChildFormValue: undefined,
        sendParentSignRecapForm,
        hasSavedAnswers,
        setHasSavedAnswers,
      }
    : {
        vasuDisabled,
        sendVasuAnswer,
        sendParentSignDiscussionForm,
        setVasuOnLoad,
        answerLoading: isAnyAnswerLoading,
        childFormValue,
        setChildFormValue,
        sendParentSignRecapForm,
        hasSavedAnswers,
        setHasSavedAnswers,
      };
};

export default useEvaluation;
