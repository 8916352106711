enum NavigationRoute {
  Root = '/',
  Login = '/login',
  Units = '/units',
  AddUnits = '/units/new',
  UnitsWithUnitSlug = '/units/:unitSlug',
  UnitsWithGroupSlug = '/units/:unitSlug/:groupSlug',
  UnitsWithChildSlug = '/units/:unitSlug/:groupSlug/:childSlug',
  Users = '/users',
  AddUser = '/users/:accessLevel/new',
  UsersWithUserSlug = '/users/:accessLevel/:userSlug',
  UsersWithChildSlug = '/users/:accessLevel/:userSlug/:childSlug',
  FAQ = '/faq',
  ForgotPassword = '/forgot-password',
  RestorePassword = '/restore-password',
  Analytics = '/analytics/:formType',
  EcecPlan = '/ecec-plan',
  EcecPlanUnitSlug = '/ecec-plan/unit/:unitSlug',
  EcecPlanForm = '/ecec-plan/:formRouteType/:id',
  EcecPlanGroups = '/ecec-plan/:groupSlug',
  Calendar = '/calendar',
  Home = '/home',
}

export default NavigationRoute;
