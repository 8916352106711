import React, { PropsWithChildren } from 'react';

import { ReactComponent as ExitIcon } from 'assets/exit-icon.svg';

export interface ModalProps {
  visible: boolean;
  title?: string;
  onClose?: () => void;
  fixHeight?: boolean;
  className?: string;
}

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({ visible, title, onClose, children, fixHeight = true, className = '' }) => {
  const isVisible = visible ? 'fixed' : 'hidden';
  const height = fixHeight ? 'h-96' : '';
  return (
    <div className={`${isVisible} bg-teacher-blue/[.15] z-20 inset-0 h-screen backdrop-blur-sm flex items-center justify-center`}>
      <div className={`m-auto w-96 ${height} bg-white rounded-xl py-6 px-4 flex flex-col overflow-hidden ${className}`}>
        <div className="flex justify-between items-center mb-2">
          {title ? <div className="text-teacher-blue text-xl font-medium">{title}</div> : <div />}
          {!!onClose && <ExitIcon onClick={onClose} className="cursor-pointer" />}
        </div>
        <div className="flex flex-col overflow-auto">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
