import React from 'react';

import { ApolloError } from '@apollo/client';

import { ReactComponent as Warning } from 'assets/warning.svg';

export interface ErrorMessageProps {
  error?: ApolloError | boolean;
  errorText?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, errorText }) => {
  return error ? (
    <div className="flex items-center my-2">
      <Warning className="mr-2" />
      {errorText && <div className="text-teacher-blue font-bold">{errorText}</div>}
    </div>
  ) : null;
};

export default ErrorMessage;
