import { QueryHookOptions, useQuery, gql } from '@apollo/client';

const UNIT_BY_SLUG_QUERY_FOR_BREADCRUMB = gql`
  query unitBySlug($slug: String!) {
    unitBySlug(slug: $slug) {
      name
    }
  }
`;

export type UnitForBreadcrumb = {
  name: string;
};

type Data = {
  unitBySlug: UnitForBreadcrumb;
};

type Vars = {
  slug?: string;
};

export const useUnitBySlugForBreadcrumb = (slug?: string, options?: QueryHookOptions<Data, Vars>) =>
  useQuery<Data, Vars>(UNIT_BY_SLUG_QUERY_FOR_BREADCRUMB, {
    skip: !slug,
    variables: { slug },
    ...options,
  });
