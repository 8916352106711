import React from 'react';
import { useTranslation } from 'react-i18next';

import { getImportanceRadioButtonGroupProps, getRadioButtonGroupProps } from 'utils/constants';
import { Translation } from 'locales';
import { Evaluation, FormVersion, QuestionType, EvaluationStatus } from 'graphql-api';
import TextArea from 'components/TextArea';

import { AccessLevel } from 'redux/authentication/enums';
import OccuranceFormItem from './OccuranceFormItem';
import ImportanceFormItem from './ImportanceFormItem';
import TextFormItem from './TextFormItem';

export interface FormGroupProps {
  formGroup: Evaluation;
  isError?: boolean;
  formType: FormVersion;
  formValue: { [key: string]: string | number };
  recapFormValue?: { [key: string]: boolean };
  commentFormValue?: { [key: string]: string };
  onChange?: (key: string | number, value: number | string) => void;
  onRecapChange?: (key: string | number, value?: boolean) => void;
  onCommentChange?: (key: string | number, value: string) => void;
  disabledForm: boolean;
  isParent: boolean;
  isSupport: boolean;
  selectedRole: AccessLevel;
}
const FormGroup: React.FC<FormGroupProps> = (props) => {
  const { t, i18n } = useTranslation();
  const {
    formGroup,
    onChange,
    formValue,
    isError = false,
    formType,
    recapFormValue,
    onRecapChange = undefined,
    onCommentChange,
    commentFormValue,
    disabledForm,
    isParent,
    isSupport,
    selectedRole,
  } = props;
  const rdProps = getRadioButtonGroupProps();
  const importanceRdProps = getImportanceRadioButtonGroupProps();
  const versionedRadioGroup = formType !== FormVersion.PARENTAL ? rdProps : importanceRdProps;
  const lang = i18n.language as keyof Translation;

  const isDiscussionOver = formGroup.status > EvaluationStatus.DISCUSSION;

  return (
    <>
      {formGroup.form.formGroups.map((formItem) => {
        const formName = formItem.name[lang];
        return (
          <div key={formName}>
            {formType !== FormVersion.RECAP && <div className="mb-4 pt-3 md:text-3xl text-teacher-blue font-bold block">{formName}</div>}
            {formItem.questions.map((questionItem) => (
              <>
                {questionItem.questionType === QuestionType.OCCURRENCE && (
                  <OccuranceFormItem
                    questionItem={questionItem}
                    formType={formType}
                    versionedRadioGroup={versionedRadioGroup}
                    onChange={onChange}
                    onRecapChange={onRecapChange}
                    formValue={formValue}
                    recapFormValue={recapFormValue}
                    isError={isError}
                    lang={lang}
                    disabledForm={disabledForm}
                    isDiscussionOver={isDiscussionOver}
                    isSupport={isSupport}
                    selectedRole={selectedRole}
                  />
                )}
                {questionItem.questionType === QuestionType.IMPORTANCE && (
                  <ImportanceFormItem
                    questionItem={questionItem}
                    formType={formType}
                    versionedRadioGroup={versionedRadioGroup}
                    onChange={onChange}
                    onRecapChange={onRecapChange}
                    formValue={formValue}
                    recapFormValue={recapFormValue}
                    isError={isError}
                    lang={lang}
                    disabledForm={disabledForm}
                  />
                )}
              </>
            ))}
            <div className="grid md:grid-cols-2 gap-12 md:gap-24 print:block">
              {!isParent &&
                formItem.questions.map(
                  (questionItem) =>
                    questionItem.questionType === QuestionType.TEXT && (
                      <TextFormItem
                        key={questionItem.id}
                        questionItem={questionItem}
                        formType={formType}
                        onChange={onChange}
                        formValue={formValue}
                        isError={isError}
                        lang={lang}
                        disabledForm={disabledForm || isParent}
                      />
                    ),
                )}
            </div>
            {formType === FormVersion.RECAP && (
              <TextArea
                key={`${formName}-recap-session`}
                className="mb-3 w-full"
                label={`${t('recapSession.changesAbout')}: ${formName}`}
                onChange={(e) => {
                  onCommentChange && onCommentChange(formItem.id, e.target.value);
                }}
                value={commentFormValue ? commentFormValue[formItem.id] : ''}
                disabled={disabledForm || isParent}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default FormGroup;
