import React from 'react';

import { Modal, Button, DateSelector } from 'components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'components/Modal';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import Colors from 'utils/colors';
import type { User } from 'graphql-api';

export interface InformationModalProps extends ModalProps {
  date: Date;
  parent?: Pick<User, 'firstName' | 'lastName'>;
  deleteAppointment: () => void;
}

const InformationModal: React.FC<InformationModalProps> = (props) => {
  const { onClose, date, parent, visible, deleteAppointment, fixHeight } = props;
  const { t } = useTranslation();

  const [shouldShowDeleteConfirm, setShouldShowDeleteConfirm] = React.useState(false);

  const deleteThis = () => {
    deleteAppointment();
    onClose && onClose();
    setShouldShowDeleteConfirm(false);
  };

  const startDelete = () => {
    if (parent) {
      setShouldShowDeleteConfirm(true);
    } else {
      deleteThis();
    }
  };

  const cancelDelete = () => setShouldShowDeleteConfirm(false);

  const modalTitle = parent ? `${parent.firstName} ${parent.lastName}` : t('calendar.notBooked');

  return (
    <Modal className="max-w-[350px] md:max-w-full" title={modalTitle} visible={visible} onClose={onClose} fixHeight={fixHeight}>
      <div className="flex flex-col gap-4">
        <div className="my-3">
          <DateSelector isDisabled date={date} />
        </div>

        {shouldShowDeleteConfirm && <div className="text-teacher-blue">{t('teacherCalendar.informationModal.deletionConfirmationMessage')}</div>}

        <div>
          {shouldShowDeleteConfirm ? (
            <div className="flex">
              <Button onClick={cancelDelete} variant="tertiary">
                {t('common.cancel')}
              </Button>

              <Button onClick={deleteThis} variant="tertiary" labelClassName="font-semibold">
                {t('calendar.deleteAppointment')}
              </Button>
            </div>
          ) : (
            <Button labelClassName="font-semibold" onClick={startDelete} variant="tertiary" icon={<TrashIcon stroke={Colors.teacherBlue} />}>
              {t('calendar.deleteAppointment')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default InformationModal;
