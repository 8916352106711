import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import DayPicker, { LocaleUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';

import { Button } from 'components';
import { Child, Group, Unit } from 'graphql-api';
import YearMonthDropDown from 'components/DatePicker/YearMonth';

interface Props {
  onChange: (key: keyof Child, value: string | Date | Unit | Group) => void;
  child?: Child;
  onNext?: () => void;
  month: Date;
  setMonth: (date: Date) => void;
}

const DatePickerStep: FC<Props> = ({ onChange, onNext, child, month, setMonth }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <DayPicker
        initialMonth={new Date()}
        month={month}
        localeUtils={MomentLocaleUtils}
        onDayClick={(date) => onChange('dateOfBirth', date)}
        className="bg-white"
        selectedDays={child?.dateOfBirth}
        locale={i18n.language}
        captionElement={({ date, localeUtils }: { date: Date; localeUtils: LocaleUtils }) => (
          <YearMonthDropDown date={date} localeUtils={localeUtils} onChange={(yearMonth) => setMonth(yearMonth)} />
        )}
      />
      <Button
        variant="tertiary"
        onClick={onNext}
        disabled={!child?.dateOfBirth}
        labelClassName="!text-teacher-blue font-bold w-full flex justify-center"
        className="w-fit ml-auto pr-1"
      >
        {t('common.next')}
      </Button>
    </>
  );
};

export default DatePickerStep;
