import { QueryHookOptions, useQuery, gql } from '@apollo/client';

import type { Evaluation } from './types';

const GET_EVALUATIONS_BY_CHILD_ID = gql`
  query Evaluation($childId: Int!, $archives: Boolean) {
    evaluations(childId: $childId, archives: $archives) {
      id
      status
      createdAt
      ageOfChildAtEvaluation
    }
  }
`;

export type PartialEvaluation = Pick<Evaluation, 'id' | 'status' | 'createdAt' | 'ageOfChildAtEvaluation'>;

type Data = {
  evaluations: PartialEvaluation[];
};

type Vars = {
  childId?: string;
  archives?: boolean;
};

export const useEvaluationsByChildId = (options?: QueryHookOptions<Data, Vars>) => useQuery<Data, Vars>(GET_EVALUATIONS_BY_CHILD_ID, options);
