import React from 'react';

import { useUsersByRoleForTeacherByUnitSlug } from 'graphql-api';
import { AccessLevel } from 'redux/authentication/enums';

export const useTeacherByUnitSlug = (unitSlug?: string) => {
  const { data: teacherData } = useUsersByRoleForTeacherByUnitSlug(AccessLevel.Teacher);
  const [teacherSlug, setTeacherSlug] = React.useState<string>();

  const teachers = React.useMemo(
    () =>
      teacherData?.users
        .filter(({ unit }) => unit?.slug === unitSlug)
        .map(({ firstName, lastName, slug, id }) => ({ name: `${firstName} ${lastName}`, slug, id })),
    [teacherData, unitSlug],
  );

  const teacher = React.useMemo(() => teachers?.find(({ slug }) => slug === teacherSlug), [teachers, teacherSlug]);

  const teacherId = teacher ? parseInt(teacher.id) : null;

  return {
    teachers,
    teacherSlug,
    setTeacherSlug,
    teacherId,
  };
};
