import { useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Child, EvaluationStatus, FormRouteType, useTeacherById, useParentById, useGroupsByUnitSlug, useUnits, useUserByIdForEcecLanding } from 'graphql-api';
import { AccessLevel } from 'redux/authentication/enums';
import NavigationRoute from 'navigation/NavigationRoute';
import { showToast, ToastType } from 'utils/toast';
import { getFormRouteType } from 'utils/form';

import { useOpenEvaluation } from './useOpenEvaluation';
import { useAppSelector } from './redux';

export const useEcecLanding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessLevel, userId } = useAppSelector(({ auth }) => auth);
  const [selectedUnitSlug, setSelectedUnitSlug] = useState<string>();
  const [selectedGroupSlug, setSelectedGroupSlug] = useState<string>();

  const isAdmin = accessLevel === AccessLevel.SuperAdmin || accessLevel === AccessLevel.UnitAdmin || accessLevel === AccessLevel.Support;
  const isUnitAdmin = accessLevel === AccessLevel.UnitAdmin;
  const isParent = accessLevel === AccessLevel.Parent;
  const isTeacher = accessLevel === AccessLevel.Teacher;

  const { data } = useUnits({ skip: !isAdmin, fetchPolicy: 'no-cache' });
  const { data: teacherUser } = useTeacherById(userId, { skip: !isTeacher || !userId, fetchPolicy: 'no-cache' });
  const { data: parentUser } = useParentById(userId, { skip: !isParent || !userId, fetchPolicy: 'no-cache' });
  const { data: unitData } = useGroupsByUnitSlug(selectedUnitSlug, { fetchPolicy: 'no-cache' });
  const { data: userData } = useUserByIdForEcecLanding(userId, { skip: !isUnitAdmin });

  const { startEvaluation, loading } = useOpenEvaluation();

  const onChildClick = (item: Child) => {
    const formType = getFormRouteType(item?.evaluation?.status);
    if (!isParent) {
      if (item.evaluation?.id) {
        navigate(`${NavigationRoute.EcecPlan}/${formType}/${item.evaluation?.id}`);
      } else {
        if (!isAdmin) {
          startEvaluation({
            variables: {
              payload: {
                childId: item.id,
                teacherId: userId || 0,
              },
            },
          });
        } else {
          showToast({
            type: ToastType.ERROR,
            title: t('evaluationNotifications.adminVasuDisabled'),
          });
        }
      }
    } else {
      const parentForm =
        (item.evaluation?.status || 0) < EvaluationStatus.VASU_DONE
          ? FormRouteType.evaluation
          : item.evaluation?.status === EvaluationStatus.VASU_DONE
          ? FormRouteType.parentalInvolvement
          : formType;
      navigate(`${NavigationRoute.EcecPlan}/${parentForm}/${item.evaluation?.id}`);
    }
  };

  const user = userData?.user;
  const units = useMemo(() => data?.units, [data]);
  const groups = useMemo(() => (isAdmin ? unitData?.unitBySlug.groups : user?.groups || teacherUser?.user.groups), [isAdmin, unitData, user, teacherUser]);
  const selectedGroup = useMemo(() => user?.groups?.find((item) => item.slug === selectedGroupSlug), [user, selectedGroupSlug]);
  const children = useMemo(() => (isParent ? parentUser?.user.children : selectedGroup?.children), [isParent, parentUser, selectedGroup]);
  const isActive = isAdmin ? unitData?.unitBySlug.canOpenEvaluations : user?.unit?.canOpenEvaluations || teacherUser?.user.unit?.canOpenEvaluations;
  const subtitle = useMemo(() => {
    switch (accessLevel) {
      case AccessLevel.Parent:
        return children && children.length ? t('ececLanding.childrenTitle') : '';
      case AccessLevel.Teacher:
        return groups && groups.length ? t('ececLanding.groupTitle') : '';
      default:
        return groups && groups.length ? t('ececLanding.groupsInTheUnit') : '';
    }
  }, [t, accessLevel, children, groups]);
  return {
    isAdmin,
    isParent,
    isTeacher,
    selectedUnitSlug,
    selectedGroupSlug,
    setSelectedUnitSlug,
    setSelectedGroupSlug,
    units: units || [],
    groups: groups || [],
    children: children || [],
    subtitle,
    isActive,
    accessLevel,
    onChildClick,
    loading,
  };
};
