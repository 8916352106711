import { QueryHookOptions, useQuery, gql } from '@apollo/client';
import { EvaluationStatus } from 'graphql-api';

const GROUP_BY_SLUG_QUERY_FOR_EDIT_GROUP_SCREEN = gql`
  query groupBySlug($slug: String!) {
    groupBySlug(slug: $slug) {
      id
      name
      unitId
      slug
      children {
        id
        slug
        firstName
        lastName
        active
        evaluation {
          status
        }
      }
      educators {
        id
        firstName
        lastName
      }
    }
  }
`;

export type GroupForEditGroupScreen = {
  id: number;
  name: string;
  unitId: number;
  slug: string;
  children: {
    id: number;
    slug: string;
    firstName: string;
    lastName: string;
    active: string;
    evaluation: {
      status: EvaluationStatus;
    };
  }[];
  educators: {
    id: number;
    firstName: string;
    lastName: string;
  }[];
};

type Data = {
  groupBySlug: GroupForEditGroupScreen;
};

type Vars = {
  slug?: string;
};

export const useGroupBySlugForEditGroupScreen = (slug?: string, options?: QueryHookOptions<Data, Vars>) =>
  useQuery<Data>(GROUP_BY_SLUG_QUERY_FOR_EDIT_GROUP_SCREEN, {
    skip: !slug,
    variables: { slug },
    ...options,
  });
