import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';

import { useOutsideClickHandler } from 'hooks/useClickOutside';
import { getLanguageMenu, LanguageMenuItem } from 'utils/languageMenu';
import { AccessLevel } from 'redux/authentication/enums';

export interface LanguageMenuProps {
  isOpened: boolean;
  setOpen: (openState: boolean) => void;
  accessLevel: AccessLevel;
}

const LanguageMenu: React.FC<LanguageMenuProps> = (props) => {
  const { isOpened, setOpen, accessLevel } = props;
  const wrapperRef = useRef(null);
  const { t } = useTranslation();
  const showClassName = isOpened ? 'absolute' : 'hidden';
  const languageMenu = getLanguageMenu(t);
  useOutsideClickHandler(wrapperRef, () => setOpen(false));

  const onMenuClick = (menuItem: LanguageMenuItem) => {
    if (menuItem.onClick) {
      menuItem.onClick();
      moment.locale(menuItem.active);
      setOpen(!isOpened);
    }
  };
  const isParent = accessLevel === AccessLevel.Parent;
  const backgroundColor = isParent ? 'bg-parent-green' : 'bg-admin-blue';
  const buttonHover = isParent ? 'hover:bg-parent-green/30' : 'hover:bg-admin-blue/30';
  const borderColor = isParent ? 'border-parent-green' : 'border-table';
  return (
    <div className={`${showClassName} rounded-lg -right-16 -top-9`} ref={wrapperRef}>
      <div className={`mt-1 flex flex-col rounded-lg ${borderColor} border-2 overflow-hidden bg-background ${showClassName}`}>
        {!!languageMenu.length &&
          languageMenu.map((menuItem) => (
            <button
              key={menuItem.title}
              className={`py-1 px-8 ${buttonHover} hover:text-teacher-blue ${
                i18next.language === menuItem.active ? `text-white ${backgroundColor}` : 'text-teacher-blue bg-white'
              }`}
              onClick={() => onMenuClick(menuItem)}
            >
              {menuItem.title}
            </button>
          ))}
      </div>
    </div>
  );
};

export default LanguageMenu;
