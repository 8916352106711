import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from 'redux/authentication/authentication.reducer';
import { AppAction } from 'redux/apptypes';
import { LOGOUT_SUCCESS } from 'redux/authentication/types';

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfig = {
  key: 'root',
  storage,
};

export const rootReducer = combineReducers({
  auth: authReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const reducers = (state: AppState | undefined, action: AppAction) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined; // eslint-disable-line no-param-reassign
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, reducers);

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, AppAction>;

const enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(thunk)) : applyMiddleware(thunk);

const configureStore = process.env.NODE_ENV === 'production' ? applyMiddleware(thunk) : enhancer;

export const store = createStore(persistedReducer, configureStore);
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type TypedDispatch = ThunkDispatch<AppState, any, AppAction>;
