import React from 'react';

import moment from 'moment';

import type { ConsultingEvent } from 'graphql-api';

export interface WeekEventProps {
  title: string;
  event: ConsultingEvent;
}

const WeekEvent: React.FC<WeekEventProps> = (props) => {
  const start = moment(props.event.startsAt);
  const end = start.clone().add(45, 'minutes');

  const timeRange = `${start.format('HH:mm')} - ${end.format('HH:mm')}`;
  const extendedTitle = `${start.format('HH:mm')} - ${end.format('HH:mm')} ${props.title}`;

  return (
    <div title={extendedTitle} className="text-transparent sm:text-teacher-blue">
      <div className="text-[10px] py-0.5 whitespace-nowrap">{timeRange}</div> {props.title}
    </div>
  );
};

export default WeekEvent;
