import React from 'react';

import { useTranslation } from 'react-i18next';

import { Loader, ModalPicker, Chart, ChartPDF, ErrorMessage, ChartLegend } from 'components';
import { FormVersion, AnalyticsChartType } from 'graphql-api';
import { Translation } from 'locales';
import { createChartData } from 'utils/form';
import { useParentalInvolvement } from 'hooks/useParentalInvolvement';
import { useYear } from 'hooks/useYear';
import { useTeacherAnalytics } from 'hooks/useTeacherAnalytics';

import { AnalyticsQuestionWrapper } from './AnalyticsQuestionWrapper';

export const ParentalInvolvement: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { setYearSlug, yearSlug, yearPickerData, selectedInterval } = useYear();

  const {
    analytics,
    analyticsError,
    isLoadingUnits,
    selectedUnitSlug,
    selectedGroupSlug,
    setSelectedGroupSlug,
    units,
    groups,
    setUnit,
    selectedGroupTitle,
    isLoadingAnalytics,
  } = useParentalInvolvement(selectedInterval);

  const lang = i18n.language as keyof Translation;

  const { isTeacher, filteredGroups } = useTeacherAnalytics({
    units,
    groups,
    selectedUnitSlug,
    setUnit,
  });

  return (
    <Loader isLoading={isLoadingUnits}>
      <div className="flex flex-row items-center flex-wrap md:flex-nowrap gap-3">
        <ModalPicker
          data={units}
          title={t('unitScreen.chooseUnit')}
          label={t('userMaintenanceScreen.unit')}
          selectedItem={selectedUnitSlug}
          onChange={setUnit}
          containerClassName="!mb-0 flex-1"
          disabled={isTeacher}
        />
        <ModalPicker
          data={filteredGroups}
          title={t('unitScreen.chooseGroup')}
          label={t('userMaintenanceScreen.group')}
          selectedItem={selectedGroupSlug}
          onChange={setSelectedGroupSlug}
          containerClassName="!mb-0 flex-1"
        />
        <ModalPicker
          data={yearPickerData}
          title={t('yearPicker.yearPlaceholder')}
          label={t('yearPicker.year')}
          selectedItem={yearSlug}
          onChange={setYearSlug}
          containerClassName="!mb-0 w-full md:flex-1 order-first md:order-none"
        />
        <div className="hidden md:flex md:place-self-stretch">
          <ChartPDF pieChartData={{ analytics: analytics?.parentalAnalytics }} title={selectedGroupTitle || ''} analyticsType={AnalyticsChartType.PIE} />
        </div>
      </div>
      <ChartLegend formVersion={FormVersion.PARENTAL} />
      <Loader isLoading={isLoadingAnalytics}>
        {analytics?.parentalAnalytics
          ? analytics?.parentalAnalytics.forms.map((item, index) => (
              <AnalyticsQuestionWrapper title={item.name[lang]} key={`analytics-${index}`}>
                {item.questions.map((questionItem) => (
                  <div className="flex flex-col bg-white rounded-lg border-2 px-4 pt-2 pb-0 border-admin-blue justify-between" key={questionItem.id}>
                    <div className="mb-3 mt-2 flex flex-1">
                      <Chart
                        data={createChartData(questionItem, t, FormVersion.PARENTAL)}
                        formVersion={FormVersion.PARENTAL}
                        title={questionItem.question[lang]}
                      />
                    </div>
                  </div>
                ))}
              </AnalyticsQuestionWrapper>
            ))
          : null}
      </Loader>
      <ErrorMessage error={analyticsError} errorText={t('childGroupCompareScreen.analyticsNotFound')} />
    </Loader>
  );
};
