import { FormVersion, useFormByChildId } from 'graphql-api';

export const useEvaluationByChildId = (childId: string | undefined, type?: FormVersion | undefined) => {
  const { loading, data } = useFormByChildId({
    variables: { childId, type: type },
    fetchPolicy: 'no-cache',
    skip: !childId,
  });

  return {
    isLoadingEvaluation: loading,
    evaluations: data?.evaluations || [],
  };
};
