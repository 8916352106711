import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Loader, ScreenLayout } from 'components';
import { ParentStep, TeacherStep, UnitAdminStep } from 'components/Users';
import { User, useUserBySlug } from 'graphql-api';
import { AccessLevel } from 'redux/authentication/enums';

export const EditUserScreen: React.FC = () => {
  const { t } = useTranslation();
  const { accessLevel: role, userSlug } = useParams();
  const [user, setUser] = useState<User>();
  const breadcrumb = [
    {
      step: '/users',
      title: `${t('userScreen.title')}: ${t(`accessLevels.${role}`)}`,
    },
    {
      step: `/users/${role}/${userSlug}`,
      title: `${user?.firstName} ${user?.lastName}`,
    },
  ];

  const { loading, refetch } = useUserBySlug(userSlug, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(userData: { userBySlug: User }) {
      if (userData.userBySlug) {
        setUser(userData.userBySlug);
      }
    },
  });

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <Loader isLoading={loading}>
        {
          {
            [AccessLevel.SuperAdmin]: <UnitAdminStep user={user} breadcrumb={breadcrumb} refetch={refetch} role={role as AccessLevel} />,
            [AccessLevel.UnitAdmin]: <UnitAdminStep user={user} breadcrumb={breadcrumb} refetch={refetch} role={role as AccessLevel} />,
            [AccessLevel.Teacher]: <TeacherStep user={user} breadcrumb={breadcrumb} refetch={refetch} role={role as AccessLevel} />,
            [AccessLevel.Parent]: <ParentStep user={user} breadcrumb={breadcrumb} refetch={refetch} />,
            [AccessLevel.Support]: <></>,
          }[role as AccessLevel]
        }
      </Loader>
    </ScreenLayout>
  );
};
