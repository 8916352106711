import { useConsultationsByTeacher as useConsultationsByChildOrTeacher } from 'graphql-api';

export const useConsultationsByChildOrTeacherId = (teacherID: number | undefined, childID: string | undefined) => {
  const { loading, data, refetch } = useConsultationsByChildOrTeacher({
    notifyOnNetworkStatusChange: true,
    variables: {
      teacherID,
      childID,
      showOnlyVacant: false,
    },
    fetchPolicy: 'no-cache',
    skip: !teacherID,
  });

  return {
    loading,
    consultations: data?.consultation || [],
    refetchConsultations: refetch,
  };
};
