import { AccessLevel } from 'redux/authentication/enums';
import type { Unit, User } from 'graphql-api';

export const getUserTableData = (selectedRole: AccessLevel, users?: User[]) => {
  switch (selectedRole) {
    case AccessLevel.Parent:
      return users?.map((item) => ({
        name: `${item.firstName} ${item.lastName}`,
        email: item.email,
        children: item.children.map((childrenItem) => ({ child: `${childrenItem.firstName} ${childrenItem.lastName}`, active: childrenItem.active })),
        units: item.children.map((childrenItem) => (childrenItem.unit as Unit).name),
        slug: item.slug,
        active: item.active,
      }));

    case AccessLevel.UnitAdmin:
      return users?.map((item) => ({
        name: `${item.firstName} ${item.lastName}`,
        email: item.email,
        units: item.ownedUnits ? item.ownedUnits.map((item) => item.name) : [],
        slug: item.slug,
        active: item.active,
      }));

    case AccessLevel.Teacher:
      return users?.map((item) => ({
        name: `${item.firstName} ${item.lastName}`,
        email: item.email,
        units: item.unit ? [item.unit.name] : [],
        slug: item.slug,
        active: item.active,
      }));

    default:
      return [];
  }
};
