import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'components';

export interface ConfirmModalProps {
  isVisible: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const { isVisible, onCancel, onConfirm, title } = props;
  const { t } = useTranslation();

  return (
    <Modal visible={isVisible} title={title} fixHeight={false} className="max-w-[350px] md:max-w-full print:hidden">
      <div className="flex items-center w-full bg-white">
        <Button variant="tertiary" type="button" onClick={onCancel} labelClassName="!text-teacher-blue">
          {t('common.cancel')}
        </Button>
        <Button variant="tertiary" type="button" onClick={onConfirm} labelClassName="font-bold !text-teacher-blue">
          {t('common.agree')}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
