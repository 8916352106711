import { useQuery } from '@apollo/client';

import { VASU_ANALYTICS } from './queries';
import { Analytics, AnalyticsChartType } from './types';

export const useChildRelationshipToGroupAnalytics = (childSlug?: string, evaluationsFrom?: string, evaluationsUntil?: string) =>
  useQuery<{ vasuAnalytics: Analytics }>(VASU_ANALYTICS, {
    skip: !childSlug,
    variables: { queryData: { childSlug, chartType: AnalyticsChartType.BAR, evaluationsFrom, evaluationsUntil } },
  });
