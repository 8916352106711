import React from 'react';

export type TextAreaProps = JSX.IntrinsicElements['textarea'] & {
  label?: string;
  labelClassName?: string;
  isError?: boolean;
};

const TextArea = ({ label, name, labelClassName, className = '', isError = false, value = '', disabled, ...props }: TextAreaProps) => {
  const disabledColor = disabled ? 'text-disabled' : 'text-teacher-blue';
  const disabledBorder = disabled ? 'text-disabled' : 'border-admin-blue';
  const disabledCursor = disabled ? 'cursor-not-allowed' : 'cursor-auto';
  return (
    <div className="flex flex-col print:print-break-inside-avoid print:mt-4">
      {label && (
        <label className={`mb-2 text-teacher-blue text-xs md:text-base font-semibold uppercase ${labelClassName} ${isError && 'text-warning'}`} htmlFor={name}>
          {label}
        </label>
      )}
      <textarea
        className={`print:hidden font-semibold text-xs md:text-base rounded-md border-2 p-1 md:pl-2 md:pt-2 h-36 resize-none ${disabledCursor} ${disabledBorder} ${disabledColor} ${className}`}
        name={name}
        value={value}
        {...props}
        disabled={disabled}
      />
      <div className="hidden print:block">{value}</div>
    </div>
  );
};

export default TextArea;
