import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DayPicker, { LocaleUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';

import { Modal, TextInput } from 'components';

import YearMonthDropDown from './YearMonth';

export interface DatePickerProps {
  onChangeDate?: (date: Date) => void;
  selectedDate?: Date;
  title?: string;
  disabled?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({ selectedDate, onChangeDate, title, disabled }) => {
  const { t, i18n } = useTranslation();
  const [ownDate, setSelectedDate] = useState<Date | undefined>(selectedDate);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [month, setMonth] = useState<Date>(new Date());

  useEffect(() => {
    setSelectedDate(selectedDate);
  }, [selectedDate]);

  const handleDayClick = (day: Date) => {
    if (onChangeDate) {
      onChangeDate(day);
    }
    setIsModalOpen(false);
  };

  const pickerTitle = title || t('common.dateOfBirth');
  return (
    <>
      <Modal visible={isModalOpen} title={pickerTitle} onClose={() => setIsModalOpen(false)} fixHeight={false}>
        <DayPicker
          initialMonth={new Date()}
          month={month}
          localeUtils={MomentLocaleUtils}
          onDayClick={handleDayClick}
          className="bg-white"
          selectedDays={ownDate}
          locale={i18n.language}
          captionElement={({ date, localeUtils }: { date: Date; localeUtils: LocaleUtils }) => (
            <YearMonthDropDown date={date} localeUtils={localeUtils} onChange={(yearMonth) => setMonth(yearMonth)} />
          )}
        />
      </Modal>
      <TextInput
        label={pickerTitle}
        disabled={!onChangeDate || disabled}
        containerClassName="mb-4"
        onClick={() => onChangeDate && setIsModalOpen(true)}
        value={moment(ownDate).format('DD/MM/YYYY')}
      />
    </>
  );
};

export default DatePicker;
