import {
  AuthActionTypes,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  RESTORE_PASSWORD_FAILURE,
  RESTORE_PASSWORD_REQUEST,
  RESTORE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  VALIDATE_HASH_FAILURE,
  VALIDATE_HASH_REQUEST,
  VALIDATE_HASH_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  AUTH_TOAST_INIT,
  ACCEPT_GDPR,
} from './types';
import { Auth } from './interfaces';
import { AccessLevel } from './enums';

const defaultAuthState: Auth = {
  accessToken: '',
  refreshToken: '',
  accessLevel: AccessLevel.Teacher,
  isLoading: false,
  isAuthenticated: false,
  restorePasswordSucceeded: false,
  forgotPasswordSucceeded: false,
  forgotPasswordFailed: false,
  validateHashFailed: false,
  validateHashSucceeded: false,
  validationLoading: false,
  loginError: false,
  userId: null,
  unitId: undefined,
  acceptedGDPR: false,
  email: '',
};

export default (state = defaultAuthState, action: AuthActionTypes): Auth => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isAuthenticated: true,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: true,
        loginError: false,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        loginError: true,
      };

    case RESTORE_PASSWORD_REQUEST:
    case RESTORE_PASSWORD_FAILURE:
      return {
        ...state,
        restorePasswordSucceeded: false,
      };

    case RESTORE_PASSWORD_SUCCESS:
      return {
        ...state,
        restorePasswordSucceeded: true,
      };

    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordSucceeded: false,
        forgotPasswordFailed: false,
      };

    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordSucceeded: false,
        forgotPasswordFailed: true,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSucceeded: true,
        forgotPasswordFailed: false,
      };

    case VALIDATE_HASH_REQUEST:
      return {
        ...state,
        validateHashFailed: false,
        validateHashSucceeded: false,
        validationLoading: true,
      };

    case VALIDATE_HASH_FAILURE:
      return {
        ...state,
        validateHashFailed: true,
        validationLoading: false,
      };

    case VALIDATE_HASH_SUCCESS:
      return {
        ...state,
        validateHashSucceeded: true,
        validationLoading: false,
      };

    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };

    case AUTH_TOAST_INIT:
      return {
        ...state,
        forgotPasswordFailed: false,
        forgotPasswordSucceeded: false,
        loginError: false,
        validateHashFailed: false,
        restorePasswordSucceeded: false,
      };

    case ACCEPT_GDPR:
      return {
        ...state,
        acceptedGDPR: true,
      };

    case REFRESH_TOKEN_FAILURE:
    case LOGOUT_SUCCESS:
      return defaultAuthState;

    default:
      return state;
  }
};
