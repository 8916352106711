import { QueryHookOptions, useQuery, gql } from '@apollo/client';

import type { Group } from 'graphql-api';

const GROUPS_BY_UNIT_SLUG_QUERY = gql`
  query unitBySlug($slug: String!) {
    unitBySlug(slug: $slug) {
      groups {
        id
        name
      }
    }
  }
`;

export type UnitForCreateChildModal = {
  groups: Group[];
};

type Data = {
  unitBySlug: UnitForCreateChildModal;
};

type Vars = {
  slug?: string;
};

export const useGroupsForCreateChildModal = (slug?: string, options?: QueryHookOptions<Data, Vars>) =>
  useQuery<Data, Vars>(GROUPS_BY_UNIT_SLUG_QUERY, { skip: !slug, variables: { slug }, ...options });
