import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Loader, Modal } from 'components';

export interface ActivateModalProps {
  visible: boolean;
  openModal: (visible: boolean) => void;
  onSave: () => void;
  currentStatus: boolean;
  loading: boolean;
}

const ActivateModal: React.FC<ActivateModalProps> = ({ visible, openModal, currentStatus, onSave, loading }) => {
  const { t } = useTranslation();

  const statusKey = currentStatus ? 'inactivate' : 'activate';
  return (
    <Modal visible={visible} fixHeight={false} className="pt-6 pb-2 max-w-[350px] md:max-w-full">
      <div className="text-teacher-blue font-medium mb-3">{t(`unitScreen.${statusKey}Question`)}</div>
      <div className="flex items-center justify-around">
        <Button className="w-32 md:w-56" variant="tertiary" onClick={() => openModal(false)} labelClassName="!text-teacher-blue">
          {t('common.cancel')}
        </Button>
        <Loader isLoading={loading}>
          <Button className="w-32 md:w-56" variant="tertiary" onClick={onSave} labelClassName="font-bold !text-teacher-blue">
            {t(`common.${statusKey}`)}
          </Button>
        </Loader>
      </div>
    </Modal>
  );
};

export default ActivateModal;
