import React from 'react';

import { useTranslation } from 'react-i18next';

import { ChartPDF, ErrorMessage, Loader, ModalPicker, Chart, ChartLegend } from 'components';
import { FormVersion, AnalyticsChartType } from 'graphql-api';
import { createChartData } from 'utils/form';
import { Translation } from 'locales';
import { useGroupResults } from 'hooks/useGroupResults';
import { useYear } from 'hooks/useYear';
import { useTeacherAnalytics } from 'hooks/useTeacherAnalytics';

import { AnalyticsQuestionWrapper } from './AnalyticsQuestionWrapper';

export const GroupResults: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { setYearSlug, yearSlug, yearPickerData, selectedInterval } = useYear();

  const { unitLoading, units, selectedUnitSlug, setUnit, ageGroups, selectedAgeGroup, setSelectedAgeGroup, analytics, analyticsError, isLoadingAnalytics } =
    useGroupResults(selectedInterval);

  const lang = i18n.language as keyof Translation;

  const { isTeacher } = useTeacherAnalytics({
    units,
    selectedUnitSlug,
    setUnit,
  });

  return (
    <Loader isLoading={unitLoading}>
      <div className="flex flex-row items-center flex-wrap md:flex-nowrap gap-3">
        <ModalPicker
          data={units}
          title={t('unitScreen.chooseUnit')}
          label={t('userMaintenanceScreen.unit')}
          selectedItem={selectedUnitSlug}
          onChange={setUnit}
          containerClassName="!mb-0 flex-1"
          disabled={isTeacher}
        />
        <ModalPicker
          data={ageGroups}
          title={t('groupChildrenScreen.ageGroupPlaceholder')}
          label={t('groupChildrenScreen.ageGroup')}
          selectedItem={selectedAgeGroup}
          onChange={setSelectedAgeGroup}
          containerClassName="!mb-0 flex-1"
        />
        <ModalPicker
          data={yearPickerData}
          title={t('yearPicker.yearPlaceholder')}
          label={t('yearPicker.year')}
          selectedItem={yearSlug}
          onChange={setYearSlug}
          containerClassName="!mb-0 w-full md:flex-1 order-first md:order-none"
        />
        <div className="hidden md:flex md:place-self-stretch">
          <ChartPDF
            pieChartData={{ analytics: analytics?.vasuAnalytics }}
            title={selectedAgeGroup ? t(`ageGroups.${selectedAgeGroup}`) : ''}
            formVersion={FormVersion.VASU}
            analyticsType={AnalyticsChartType.PIE}
          />
        </div>
      </div>
      <ChartLegend formVersion={FormVersion.VASU} />
      <Loader isLoading={isLoadingAnalytics}>
        {analytics?.vasuAnalytics
          ? analytics?.vasuAnalytics.forms.map((item, index) => (
              <AnalyticsQuestionWrapper title={item.name[lang]} key={`analytics-${index}`}>
                {item.questions.map((questionItem) => (
                  <div className="flex flex-col bg-white rounded-lg border-2 px-4 pt-2 pb-0 border-admin-blue justify-between" key={questionItem.id}>
                    <div className="mb-3 mt-2 flex flex-1">
                      <Chart data={createChartData(questionItem, t, FormVersion.VASU)} formVersion={FormVersion.VASU} title={questionItem.question[lang]} />
                    </div>
                  </div>
                ))}
              </AnalyticsQuestionWrapper>
            ))
          : null}
      </Loader>
      <ErrorMessage error={analyticsError} errorText={t('childGroupCompareScreen.analyticsNotFound')} />
    </Loader>
  );
};
