import React from 'react';

import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';

export type ButtonProps = JSX.IntrinsicElements['button'] & {
  icon?: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary';
  labelClassName?: string;
};

const Button = ({ children, icon, className, variant = 'primary', labelClassName, disabled, ...props }: ButtonProps) => {
  const { isAuthenticated, accessLevel } = useAppSelector(({ auth }) => auth);

  const getMainColor = (key: string) => {
    if (!isAuthenticated || accessLevel === AccessLevel.Teacher) {
      return `${key}-teacher-blue`;
    }
    if (accessLevel === AccessLevel.Parent) {
      return `${key}-parent-green`;
    }
    return `${key}-admin-blue`;
  };

  const getBackgroundColor = () => {
    if (disabled && variant === 'primary') {
      return 'bg-disabled-primary';
    } else if (disabled && variant === 'secondary') {
      return 'bg-disabled-secondary';
    }
    return variant === 'primary' ? getMainColor('bg') : 'bg-transparent';
  };

  const getButtonColor = () => {
    if (disabled && variant === 'secondary') {
      return 'text-disabled-primary';
    }
    return variant === 'primary' || disabled ? 'text-white' : getMainColor('text');
  };

  const getBorderColor = () => {
    if (disabled && variant === 'secondary') {
      return 'border-disabled-primary';
    }
    return variant === 'secondary' && !disabled ? getMainColor('border') : 'border-transparent';
  };

  const backgroundColor = getBackgroundColor();
  const borderColor = getBorderColor();
  const buttonColor = getButtonColor();
  const disabledCursor = disabled ? 'cursor-not-allowed' : 'cursor-pointer';

  return (
    <button
      className={`flex items-center justify-center w-56 py-2 border-2 rounded-lg ${disabledCursor} ${borderColor} ${backgroundColor} ${className || ''}`}
      disabled={disabled}
      {...props}
    >
      {!!icon && icon}
      <div className={`${buttonColor} text-base ${labelClassName || ''}`}>{children}</div>
    </button>
  );
};

export default Button;
