import React, { useEffect } from 'react';

import moment from 'moment-timezone';
import { useEvaluationByChildId } from 'hooks/useEvaluationByChildId';
import { useConsultationsByChildOrTeacherId } from 'hooks/useConsultationsByTeacherId';
import { ConsultationType, useRemoveParentFromConsultation, useAddParentToConsultation, EvaluationStatus, FormVersion } from 'graphql-api';
import { useTranslation } from 'react-i18next';
import { showToast, ToastType } from '../utils/toast';

export interface Consultation {
  consultationID?: number;
  parentID?: number | null;
  evaluationID?: number;
  guardianEmail?: string;
  type?: ConsultationType;
  timezone?: string;
  valuationStatus?: EvaluationStatus;
}

export const useParentConsultations = (
  parentId: number | null,
  childId: string | undefined,
  selectedEventId?: number,
  setCalendarStatusRefresh?: (refresh: boolean) => void,
  valuationStatus?: EvaluationStatus,
) => {
  const [messageReady, setMessageReady] = React.useState<boolean>(false);
  const [moreEmail, setMoreEmail] = React.useState<string>();
  const { t } = useTranslation();
  const { evaluations } = useEvaluationByChildId(
    childId,
    valuationStatus && valuationStatus <= EvaluationStatus?.VASU_DONE ? FormVersion?.VASU : FormVersion.DISCUSSION,
  );
  const formGroup = evaluations[0];
  const evaluationId = formGroup?.id;
  const evaluationStatus = formGroup?.status;
  const evalStarterTeacher = formGroup?.teacher;
  const evalStarterTeacherID = evalStarterTeacher?.id ? parseInt(evalStarterTeacher.id) : undefined;

  const { consultations, loading, refetchConsultations } = useConsultationsByChildOrTeacherId(evalStarterTeacherID, childId);

  const [addParentToConsultation] = useAddParentToConsultation({
    onCompleted: refetchConsultations,
    onError: refetchConsultations,
  });

  useEffect(() => {
    if (loading) {
      setMessageReady(true);
    }

    const emptyConsultations = consultations.filter(
      (consultation) =>
        !consultation.parent ||
        (evaluationId === consultation.evaluationId &&
          ((consultation.type != ConsultationType.DISCUSSION && formGroup?.status !== EvaluationStatus.VASU_DONE) ||
            (consultation.type != ConsultationType.RECAP && formGroup?.status !== EvaluationStatus.DISCUSSION_DONE))),
    );

    if (!emptyConsultations.length && !loading && messageReady) {
      showToast({
        title: t('calendar.thereIsNoOpenAppointmentError'),
        type: ToastType.ERROR,
      });
    }

    if (setCalendarStatusRefresh) {
      setCalendarStatusRefresh(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const [removeParentFromConsultation] = useRemoveParentFromConsultation({
    onCompleted: refetchConsultations,
    onError: refetchConsultations,
  });

  const bookConsultation = async () => {
    let consultation: Consultation = {
      consultationID: selectedEventId,
      parentID: parentId,
      evaluationID: formGroup?.id,
      type: formGroup.status < EvaluationStatus.DISCUSSION_DONE ? ConsultationType.DISCUSSION : ConsultationType.RECAP,
      timezone: moment.tz.guess(),
    };

    if (moreEmail) {
      consultation = {
        ...consultation,
        guardianEmail: moreEmail,
      };
    }

    await addParentToConsultation({
      variables: { consultation },
    });
  };

  const cancelAppointment = () => {
    const consultation: Partial<Consultation> = {
      consultationID: selectedEventId,
      timezone: moment.tz.guess(),
    };

    removeParentFromConsultation({
      variables: { consultation },
    });
  };

  const allowedEvaluationIds = [evaluationId, null];

  const allowedConsultations =
    (evaluationStatus > EvaluationStatus.VASU && evaluationStatus < EvaluationStatus.DISCUSSION_DONE) ||
    (evaluationStatus >= EvaluationStatus.DISCUSSION_DONE && formGroup?.form?.parentHasSigned)
      ? consultations.filter((consultation) => {
          return allowedEvaluationIds.includes(consultation.evaluationId);
        })
      : [];

  return {
    cancelAppointment,
    bookConsultation,
    consultations: allowedConsultations,
    loading,
    setMoreEmail,
    moreEmail,
  };
};
