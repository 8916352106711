import React from 'react';

import moment from 'moment';

import { Calendar } from 'components';
import { useCalendarView } from 'hooks/useCalendarView';
import { useChildrenOfParent } from 'hooks/useChildrenOfParent';
import { useParentConsultations } from 'hooks/useParentConsultations';
import type { ConsultingEvent } from 'graphql-api';

import './parentCalendar.css';
import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';
import { EvaluationStatus } from 'graphql-api';
import BookingModal from './BookingModal';

export interface ParentCalendarProps {
  parentId: number | null;
  parentEmail: string;
  hideChildComponents?: boolean;
  defaultChildId?: string;
  setCalendarStatusRefresh?: (refresh: boolean) => void;
  evaluationStatus?: EvaluationStatus;
}

export const ParentCalendar: React.FC<ParentCalendarProps> = ({
  parentId,
  parentEmail,
  hideChildComponents = false,
  defaultChildId,
  setCalendarStatusRefresh,
  evaluationStatus,
}) => {
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const isSupport = accessLevel === AccessLevel.Support;

  const { view, onViewChange } = useCalendarView();

  const [shouldShowBookingModal, setShouldShowBookingModal] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState<ConsultingEvent>();

  const closeBookingModal = () => {
    setShouldShowBookingModal(false);
    setSelectedEvent(undefined);
  };

  const { children, isLoadingChildren } = useChildrenOfParent(parentId);

  const [childId, setSetChildId] = React.useState<string>();

  const { cancelAppointment, bookConsultation, consultations, loading, setMoreEmail, moreEmail } = useParentConsultations(
    parentId,
    childId,
    selectedEvent?.id,
    setCalendarStatusRefresh,
    evaluationStatus,
  );

  React.useEffect(() => {
    const shouldSetDefaultChildId = !childId && children?.length;

    if (shouldSetDefaultChildId && defaultChildId) {
      setSetChildId(defaultChildId);
    } else if (shouldSetDefaultChildId) {
      setSetChildId(children[0].id);
    }
  }, [children, childId, defaultChildId]);

  const childComponents = children
    ? children.map((child) => (
        <div
          key={child.id}
          onClick={() => setSetChildId(child.id)}
          className={`truncate rounded-sm text-sm md:text-base font-semibold py-1 px-2 ${
            child.id === childId ? 'bg-parent-green text-white' : 'text-parent-green'
          } cursor-pointer`}
        >
          {`${child.firstName} ${child.lastName}`}
        </div>
      ))
    : [];

  const isNotBooked = (consultation: ConsultingEvent) => !consultation.parent;
  const isBookedByParent = (consultation: ConsultingEvent) => consultation.parent && `${consultation.parent.id}` === `${parentId}`;

  const availableConsultations = consultations.filter(isNotBooked);
  const bookedConsultations = consultations.filter(isBookedByParent);

  const consultationsToShow = [...availableConsultations, ...bookedConsultations];

  const shouldDisableBooking = bookedConsultations.length > 0;

  const onSelectEvent = (event: ConsultingEvent) => {
    setShouldShowBookingModal(true);
    setSelectedEvent(event);
  };

  return (
    <React.Fragment>
      {!isLoadingChildren && !hideChildComponents && <div className="grid grid-cols-2 md:grid-cols-4 gap-x-6 md:gap-x-12 gap-y-3">{childComponents}</div>}

      <div className="useParentGreenBorder flex-1">
        <Calendar consultations={loading ? [] : consultationsToShow} view={view} onSelectEvent={onSelectEvent} onViewChange={onViewChange} />
      </div>

      {selectedEvent?.teacher && !isSupport && (
        <BookingModal
          visible={shouldShowBookingModal}
          date={moment(selectedEvent?.startsAt).toDate()}
          teacher={selectedEvent?.teacher}
          onClose={closeBookingModal}
          cancelAppointment={cancelAppointment}
          fixHeight={false}
          bookAppointment={bookConsultation}
          parentEmail={parentEmail}
          extraEmail={moreEmail}
          isBooked={!!selectedEvent?.parent}
          setExtraEmail={setMoreEmail}
          shouldDisableBooking={shouldDisableBooking}
        />
      )}
    </React.Fragment>
  );
};
