import React from 'react';

import { TailSpin } from 'react-loader-spinner';

interface LoaderProps {
  children?: React.ReactNode;
  isLoading: boolean;
  isSmall?: boolean;
  className?: string;
  color?: string;
}

const Loader: React.FC<LoaderProps> = (props) => {
  const { isLoading, children, isSmall = false, className = '', color } = props;
  const iconSize = isSmall ? 20 : 80;
  return (
    <>
      {isLoading ? (
        <div className={`flex items-center justify-center ${className}`}>
          <TailSpin color={color || '#0D1D3C'} height={iconSize} width={iconSize} />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default Loader;
