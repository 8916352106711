import React from 'react';

import ChoiceCircle, { Choices } from 'components/ChoiceCircle';
import { ReactComponent as Goal } from 'assets/maingoal.svg';
import { ReactComponent as Done } from 'assets/donegoal.svg';
import { FormVersion } from 'graphql-api';

export interface ChoiceOptions {
  label: string;
  id: string;
  value?: number | string;
}
export interface ChoiceGroupProps {
  options: ChoiceOptions[];
  onClick?: (value: string) => void;
  label?: string;
  labelClassName?: string;
  containerClassName?: string;
  selected: {
    type: Choices;
    value: string;
  }[];
  isError?: boolean;
  onAgreed?: () => void;
  isAgreed?: boolean;
  agreeText?: string;
  formType?: FormVersion;
}

const ChoiceGroup = ({
  options,
  onClick,
  label,
  labelClassName = '',
  containerClassName = '',
  selected,
  isError = false,
  onAgreed = undefined,
  isAgreed = false,
  agreeText,
  formType,
}: ChoiceGroupProps) => (
  <div className={`${containerClassName} print:print-break-inside-avoid`}>
    {label && <div className={`text-teacher-blue text-sm md:text-xl mb-6 block font-bold ${labelClassName} ${isError && '!text-warning'}`}>{label}</div>}
    <div className="flex items-start">
      {options.map((option, index) => {
        const selectedChoice = selected
          .slice()
          .reverse()
          .find((selectedItem) => `${option.value}` === `${selectedItem.value}`);
        return (
          <div
            className={`relative w-12 max-w-14 flex flex-col items-center justify-center ${onAgreed ? 'mr-2' : 'mr-6'} md:mr-14`}
            key={`${option.label}-${option.id}`}
          >
            <ChoiceCircle
              onClick={() => onClick && onClick(option.value ? option.value?.toString() : '')}
              selected={selectedChoice ? selectedChoice.type : Choices.NotSelected}
              dividerVisible={index !== options.length - 1}
              {...option}
              shouldFitMore={!!onAgreed}
            />
            {option.label && <label className="text-teacher-blue font-semibold uppercase mt-1 text-[10px] md:text-xs text-center w-24">{option.label}</label>}
          </div>
        );
      })}
      {!!onAgreed && (
        <div className="relative">
          <div className="absolute -left-1 md:-left-6 top-0 w-0 h-7 border border-table" />
          <div className="flex flex-col justify-center items-center">
            <div
              className={`w-6 h-6 rounded-full flex items-center border-2 border-teacher-blue ${isAgreed ? 'bg-teacher-blue' : 'bg-white'}`}
              onClick={onAgreed}
              role="button"
              tabIndex={0}
            >
              {isAgreed && formType === FormVersion.DISCUSSION && <Goal />}
              {isAgreed && formType === FormVersion.RECAP && <Done />}
            </div>
            <label className="text-teacher-blue font-semibold uppercase mt-1 text-[10px] md:text-xs w-26 text-center">{agreeText}</label>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default ChoiceGroup;
