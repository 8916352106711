import React from 'react';

import type { ConsultingEvent } from 'graphql-api';

export interface AgendaEventProps {
  title: string;
  event: ConsultingEvent;
}

const AgendaEvent: React.FC<AgendaEventProps> = ({ event, title }) => (
  <div
    className={`${
      event.parent ? 'bg-done text-white' : 'bg-table bg-opacity-50 text-teacher-blue'
    } rounded-r whitespace-nowrap p-1 my-2 mr-2 border-r border-t border-b border-teacher-blue`}
  >
    {title}
  </div>
);

export default AgendaEvent;
