import React from 'react';

import { useTranslation } from 'react-i18next';

import { BarChart, Loader, ModalPicker, ChartPDF, ErrorMessage } from 'components';
import { FormVersion, AnalyticsChartType } from 'graphql-api';
import { Translation } from 'locales';
import { createChartData } from 'utils/form';
import { useChildRelationship } from 'hooks/useChildRelationship';
import { useYear } from 'hooks/useYear';
import { useTeacherAnalytics } from 'hooks/useTeacherAnalytics';

import { AnalyticsQuestionWrapper } from './AnalyticsQuestionWrapper';

export const ChildsRelationship: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { setYearSlug, yearSlug, yearPickerData, selectedInterval } = useYear();

  const {
    analytics,
    analyticsError,
    isLoadingUnits,
    isLoadingAnalytics,
    selectedUnitSlug,
    selectedGroupSlug,
    setGroup,
    units,
    groups,
    setUnit,
    childFromAnalytics,
    children,
    selectedChildSlug,
    setSelectedChildSlug,
  } = useChildRelationship(selectedInterval);

  const lang = i18n.language as keyof Translation;

  const { isTeacher, filteredGroups } = useTeacherAnalytics({
    units,
    groups,
    selectedUnitSlug,
    setUnit,
  });

  return (
    <Loader isLoading={isLoadingUnits}>
      <div className="flex flex-row items-center flex-wrap md:flex-nowrap gap-3">
        <ModalPicker
          data={units}
          title={t('unitScreen.chooseUnit')}
          label={t('userMaintenanceScreen.unit')}
          selectedItem={selectedUnitSlug}
          onChange={setUnit}
          containerClassName="!mb-0 flex-1"
          disabled={isTeacher}
        />
        <ModalPicker
          data={filteredGroups}
          title={t('unitScreen.chooseGroup')}
          label={t('userMaintenanceScreen.group')}
          selectedItem={selectedGroupSlug}
          onChange={setGroup}
          containerClassName="!mb-0 flex-1"
        />
        <ModalPicker
          data={children}
          title={t('teacherPreparationForPlanDiscussion.childPlaceholder')}
          label={t('teacherPreparationForPlanDiscussion.child')}
          selectedItem={selectedChildSlug}
          onChange={setSelectedChildSlug}
          containerClassName="!mb-0 flex-1"
        />
        <ModalPicker
          data={yearPickerData}
          title={t('yearPicker.yearPlaceholder')}
          label={t('yearPicker.year')}
          selectedItem={yearSlug}
          onChange={setYearSlug}
          containerClassName="!mb-0 flex-1"
        />
        <div className="hidden md:flex md:place-self-stretch">
          <ChartPDF
            pieChartData={{ analytics: analytics?.vasuAnalytics }}
            title={childFromAnalytics?.name || ''}
            formVersion={FormVersion.VASU}
            analyticsType={AnalyticsChartType.BAR}
          />
        </div>
      </div>
      <Loader isLoading={isLoadingAnalytics}>
        {analytics?.vasuAnalytics
          ? analytics?.vasuAnalytics.forms.map((item, index) => (
              <AnalyticsQuestionWrapper title={item.name[lang]} key={`analytics-${index}`}>
                {item.questions.map((questionItem) => (
                  <div className="flex flex-col bg-white rounded-lg border-2 px-4 pt-2 pb-0 border-admin-blue justify-between" key={questionItem.id}>
                    <div className="text-teacher-blue text-xl font-semibold">{questionItem.question[lang]}</div>
                    <div className="mb-3 mt-2 flex items-center">
                      <BarChart data={createChartData(questionItem, t, FormVersion.VASU, true)} />
                    </div>
                  </div>
                ))}
              </AnalyticsQuestionWrapper>
            ))
          : null}
      </Loader>
      <ErrorMessage error={analyticsError} errorText={t('childGroupCompareScreen.analyticsNotFound')} />
    </Loader>
  );
};
