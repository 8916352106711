import React, { useMemo } from 'react';

import TextArea from 'components/TextArea';
import { FormVersion, Question } from 'graphql-api';
import { Translation } from 'locales';
import { AccessLevel } from 'redux/authentication/enums';

export interface TextFormItemProps {
  formType: FormVersion;
  questionItem: Question;
  formValue: { [key: string]: string | number };
  isError?: boolean;
  onChange?: (key: string | number, value: number | string) => void;
  lang: keyof Translation;
  disabledForm: boolean;
}

const TextFormItem: React.FC<TextFormItemProps> = (props) => {
  const { questionItem, formType, onChange, formValue, isError = false, lang, disabledForm } = props;
  const question = questionItem.question[lang];
  const textFormItemDisalbed = useMemo(() => disabledForm || formType === FormVersion.DISCUSSION || formType === FormVersion.RECAP, [disabledForm, formType]);
  const isTextError = useMemo(
    () => isError && !formValue[questionItem.id] && formType !== FormVersion.DISCUSSION && formType !== FormVersion.RECAP,
    [isError, formValue, formType, questionItem],
  );
  const getTextFormItemAnswer = () => {
    if (formType === FormVersion.DISCUSSION || formType === FormVersion.RECAP) {
      return questionItem.answers.find((answerItem) => answerItem.replier.role === AccessLevel.Teacher)?.answer;
    }
    return formValue[questionItem.id];
  };
  return (
    <TextArea
      key={`${question}-${questionItem.id}`}
      className="mb-3 w-full text-xs md:text-base"
      label={question}
      onChange={(e) => {
        if (!textFormItemDisalbed) {
          onChange && onChange(questionItem.id, e.target.value);
        }
      }}
      value={getTextFormItemAnswer()}
      isError={isTextError}
      disabled={textFormItemDisalbed}
    />
  );
};

export default TextFormItem;
