import React from 'react';

import { useTranslation } from 'react-i18next';

import { TextArea } from 'components';
import { Evaluation, FormVersion } from 'graphql-api';

export interface ChildSupportProps {
  supportValue: string | null;
  setSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  disabledForm: boolean;
  formType: FormVersion;
  formGroup: Evaluation | null;
  supportKey: 'support' | 'otherParticipated' | 'evaluationOfSupport';
  isError: boolean;
}

const ChildSupport: React.FC<ChildSupportProps> = ({ supportValue, setSupportValue, disabledForm, formType, formGroup, supportKey, isError }) => {
  const { t } = useTranslation();
  const formElement = formGroup ? formGroup[supportKey] : null;

  const renderTitle = (title: string, value?: string | null) => (
    <div className="flex flex-col">
      <div className="mb-2 md:text-xl text-teacher-blue font-bold block">{title}</div>
      {!!value && <div className="mb-2 text-teacher-blue italic">{value}</div>}
    </div>
  );

  const renderTextArea = (label: string) => (
    <TextArea
      className="mb-3 w-100"
      label={label}
      onChange={(e) => setSupportValue(e.target.value)}
      value={supportValue || ''}
      disabled={disabledForm}
      isError={isError && !supportValue}
    />
  );

  const renderEcec = () => renderTextArea(t('childrenEvaluationTypes.ece'));

  const renderDiscussion = () => (
    <>
      {renderTitle(t('childrenEvaluationTypes.ece'), formElement?.ecec)}
      {renderTextArea(t('childrenEvaluationTypes.discussion'))}
    </>
  );

  const renderRecap = () => (
    <>
      {renderTitle(t('childrenEvaluationTypes.ece'), formElement?.ecec)}
      {renderTitle(t('childrenEvaluationTypes.discussion'), formElement?.discussion)}
      {renderTextArea(t('childrenEvaluationTypes.recap'))}
    </>
  );

  return (
    <div className="mt-3">
      <div className="mb-4 md:text-3xl text-teacher-blue font-bold block">{t(`common.${supportKey}`)}</div>
      <div className="mb-4 italic text-teacher-blue font-bold block">{t(`ececLanding.${supportKey}`)}</div>
      {
        {
          [FormVersion.VASU]: renderEcec(),
          [FormVersion.DISCUSSION]: renderDiscussion(),
          [FormVersion.RECAP]: renderRecap(),
          [FormVersion.PARENTAL]: null,
        }[formType]
      }
    </div>
  );
};

export default ChildSupport;
