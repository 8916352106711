import React from 'react';

export interface BarChartProps {
  data: {
    name: string;
    value: number;
    isOwnValue?: boolean;
  }[];
}

const BarChart = ({ data }: BarChartProps) => {
  const maxValue = Math.max(...data.map(({ value }) => value));
  const getBackgroudColor = (isOwnValue?: boolean) => (isOwnValue ? 'bg-chart-green' : 'bg-teacher-blue');
  return (
    <div className="grid grid-row-1 gap-y-2 mt-8 w-full">
      {data.map(({ name, value, isOwnValue }) => (
        <div key={`${name}-bar-chart`} className="flex items-center">
          <div className={`justify-self-end font-bold text-xs uppercase w-24 text-teacher-blue`}>{name}</div>
          <div className="h-5 relative flex-1">
            <div
              style={{ width: `${Math.floor((value / maxValue) * 100)}%` }}
              className={`absolute border-2 rounded-tr-xl rounded-br-xl top-0 bottom-0 left-0 border-transparent ${getBackgroudColor(isOwnValue)} `}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default BarChart;
