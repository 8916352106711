import React from 'react';

import moment from 'moment';

import type { ConsultingEvent } from 'graphql-api';

export interface DayEventProps {
  title: string;
  event: ConsultingEvent;
}

const DayEvent: React.FC<DayEventProps> = (props) => {
  const start = moment(props.event.startsAt);
  const end = start.clone().add(45, 'minutes');

  const timeRange = `${start.format('HH:mm')} - ${end.format('HH:mm')}`;
  const extendedTitle = `${start.format('HH:mm')} - ${end.format('HH:mm')} ${props.title}`;

  return (
    <div title={extendedTitle}>
      <div className="text-[10px] py-0.5 whitespace-nowrap">{timeRange}</div> {props.title}
    </div>
  );
};

export default DayEvent;
