import React from 'react';

import { useTranslation } from 'react-i18next';

export interface ShowMoreProps {
  text: string;
  className?: string;
}

export const ShowMore: React.FC<ShowMoreProps> = ({ text, className = '' }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <div className={`text-xs md:text-base ${className} ${isOpen ? '' : 'flex'}`}>
      <div className={`${isOpen ? '' : 'truncate'}`}>{text}</div>
      <div onClick={toggle} className="font-bold text-right whitespace-nowrap cursor-pointer">
        {isOpen ? t('common.showLess') : t('common.showMore')}
      </div>
    </div>
  );
};
