import React from 'react';

import { Tooltip, BarChart, Bar, XAxis, YAxis } from 'recharts';

import { FormVersion } from 'graphql-api';
import { useChart } from 'hooks/useChart';

import { ChartTooltipHoc } from './ChartTooltip';

export interface ChartProps {
  data: { name: string; value: number; replierValue: number }[];
  formVersion?: FormVersion;
  title?: string;
}

const Chart: React.FC<ChartProps> = ({ data, title, formVersion = FormVersion.VASU }) => {
  const barHeight = 32;
  const barWidthDefault = 283;

  const [barDiv, setBarDiv] = React.useState<null | HTMLDivElement>(null);

  const barWidth = barDiv?.getBoundingClientRect()?.width || barWidthDefault;

  const { colors, dataKeys, barData, yAxisKey } = useChart({ data, formVersion });

  const ChartTooltip = React.useMemo(() => ChartTooltipHoc(barWidth), [barWidth]);

  return (
    <div className="flex flex-col flex-1 items-between justify-between" ref={(div) => setBarDiv(div)}>
      {title && <div className="text-teacher-blue text-sm md:text-xl font-bold mb-6">{title}</div>}
      <div className="flex items-end justify-center">
        <div className="rounded-full overflow-hidden min-w-fit">
          {!!barDiv && (
            <BarChart
              width={barWidth}
              height={barHeight}
              data={barData}
              layout="vertical"
              stackOffset="expand"
              margin={{ top: -2, right: 0, bottom: -2, left: 0 }}
            >
              <Tooltip content={ChartTooltip} cursor={false} />
              <XAxis type="number" hide />
              <YAxis type="category" dataKey={yAxisKey} hide />
              {dataKeys.map((key, index) => {
                return <Bar key={`bar-${key}`} dataKey={key} stackId="a" fill={colors[index]} barSize={barHeight} />;
              })}
            </BarChart>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chart;
