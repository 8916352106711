import { useParentsChildren } from 'graphql-api';

export const useChildrenOfParent = (parentId: number | null) => {
  const { loading, data } = useParentsChildren({
    variables: { id: parentId },
    fetchPolicy: 'no-cache',
  });

  return {
    isLoadingChildren: loading,
    children: data?.user.children || [],
  };
};
