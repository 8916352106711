import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, TextInput, ScreenLayout } from 'components';
import { authToastInit, forgotPassword } from 'redux/authentication/authentication.actions';
import { AppState } from 'redux/store';
import { useAppDispatch } from 'hooks/redux';
import { showToast, ToastType } from 'utils/toast';
import { validateEmail } from 'utils/validators';
import { ReactComponent as TextLogo } from 'assets/text-logo.svg';

export const ForgotPasswordScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { forgotPasswordSucceeded, forgotPasswordFailed } = useSelector(({ auth }: AppState) => auth);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (error || forgotPasswordFailed) {
      showToast({
        title: t('userForms.emailError'),
        onClose: () => dispatch(authToastInit()),
        type: ToastType.ERROR,
      });
    }
  }, [forgotPasswordFailed, error, dispatch, t]);

  useEffect(() => {
    if (forgotPasswordSucceeded) {
      showToast({
        title: t('userForms.forgotSuccess'),
        onClose: () => dispatch(authToastInit()),
        type: ToastType.SUCCESS,
      });
    }
  }, [forgotPasswordSucceeded, dispatch, t]);

  const onClick = () => {
    if (validateEmail(email)) {
      setError(false);
      dispatch(forgotPassword(email));
    } else {
      setError(true);
    }
  };

  return (
    <ScreenLayout>
      <div className="flex flex-1 flex-col justify-center items-center">
        <div className="text-2xl md:text-5xl flex items-center text-teacher-blue mb-6 font-bold">
          <span className="mr-2">{t('common.welcome')}</span>
          <TextLogo className="-ml-2 -mr-1 h-6 md:scale-200 md:mx-8 translate-y-0.5" />
          <span>!</span>
        </div>
        <div className="max-w-md w-full px-5">
          <TextInput
            label={t('userForms.email')}
            placeholder={t('userForms.emailPlaceholder')}
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            containerClassName="mb-6"
          />
          {forgotPasswordSucceeded ? (
            <>
              <div className="text-teacher-blue flex justify-end px-1 mb-3">{t('userForms.emailSent')}</div>
              <Button onClick={() => navigate(-1)}>{t('common.backHome')}</Button>
            </>
          ) : (
            <div className="flex items-center justify-center">
              <Button className="mb-4 mt-3" disabled={!email} onClick={onClick}>
                {t('userForms.forgotPasswordButton')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </ScreenLayout>
  );
};
