import React from 'react';

import { useTranslation } from 'react-i18next';

import { AccessLevel } from 'redux/authentication/enums';
import { Button, Loader, Selector, Switch, Table, TextInput } from 'components';
import type { User } from 'graphql-api';
import { ReactComponent as Plus } from 'assets/plus-icon.svg';
import { getUserTableData } from 'utils/getUserTableData';
import { useAppSelector } from 'hooks/redux';
import { useUserSearch } from 'hooks/useUserSearch';

export interface UserListStepProps {
  loading: boolean;
  data?: { users: User[] };
  setRole: (role: AccessLevel) => void;
  onlyActive: boolean;
  setOnlyActive: (onlyActive: boolean) => void;
  selectedRole: AccessLevel;
  onClick: (item?: User) => void;
}

export const UserListStep: React.FC<UserListStepProps> = ({ loading, data, setRole, selectedRole, onlyActive, setOnlyActive, onClick }) => {
  const { t } = useTranslation();
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const isParent = selectedRole === AccessLevel.Parent;
  const isSupport = accessLevel === AccessLevel.Support;

  const otherColumns = [{ title: t('userScreen.name') }, { title: t('userScreen.email') }, { title: t('unitScreen.title') }];
  const parentColumns = [
    { title: t('userScreen.name') },
    { title: t('userScreen.email') },
    { title: t('userScreen.children') },
    { title: t('unitScreen.title') },
  ];
  const tableColumns = isParent ? parentColumns : otherColumns;

  const { filteredUsers, searchTerm, setSearchTerm } = useUserSearch(data?.users || []);

  const tableData = getUserTableData(selectedRole, filteredUsers);

  const userSelector =
    accessLevel === AccessLevel.UnitAdmin ? [AccessLevel.Teacher, AccessLevel.Parent] : [AccessLevel.UnitAdmin, AccessLevel.Teacher, AccessLevel.Parent];
  const selectorData = userSelector.map((item) => ({ label: t(`accessLevels.${item}`), value: item }));

  return (
    <>
      <div className="flex justify-between">
        <div className="text-teacher-blue text-xl md:text-3xl font-bold mb-4">{t('userScreen.title')}</div>
        {isParent && (
          <div className="mt-2">
            <Switch
              onChange={() => {
                setOnlyActive(!onlyActive);
              }}
              checked={!onlyActive}
              title={t(`common.onlyActive`)}
            />
          </div>
        )}
      </div>
      <Selector data={selectorData} selectedItem={selectedRole} onClick={(item) => setRole(item as AccessLevel)} />
      <div className="flex items-center mt-3 md:mt-0 mb-6 md:mb-12">
        <TextInput
          placeholder={t('common.search')}
          type="text"
          name="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value.trimStart())}
          containerClassName="w-full mr-3 md:mr-6"
          isSearch
          className="py-2 md:py-3 !text-base"
        />
        <Button className="p-2 md:p-3 w-auto" labelClassName="w-full flex justify-center whitespace-nowrap" onClick={() => onClick()} disabled={isSupport}>
          <Plus />
          <span className="hidden md:inline  md:ml-2">{t('userMaintenanceScreen.addNewUser')}</span>
        </Button>
      </div>
      <Loader isLoading={loading}>
        <Table data={tableData} tableColumns={tableColumns} isParent={isParent} onRowClick={(item) => onClick(item as unknown as User)} />
      </Loader>
    </>
  );
};
