import { QueryHookOptions, useQuery } from '@apollo/client';

import { PARENTAL_ANALYTICS } from './queries';
import { Analytics } from './types';

export const useParentalAnalytics = (groupSlug?: string, unitSlug?: string, evaluationsFrom?: string, evaluationsUntil?: string, options?: QueryHookOptions) =>
  useQuery<{ parentalAnalytics: Analytics }>(PARENTAL_ANALYTICS, {
    skip: !groupSlug || !unitSlug,
    variables: { queryData: { groupSlug, unitSlug, evaluationsFrom, evaluationsUntil } },
    ...options,
  });
