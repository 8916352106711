import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Loader, Modal, TextInput } from 'components';
import { Group } from 'graphql-api';

export interface CreateGroupModalProps {
  visible: boolean;
  openModal: (visible: boolean) => void;
  group?: Group;
  onSelect: (groupName: string) => void;
  isLoading: boolean;
}

const CreateGroupModal: React.FC<CreateGroupModalProps> = ({ visible, openModal, onSelect, isLoading, group }) => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState<string>('');

  useEffect(() => {
    setGroupName(group?.name || '');
  }, [group]);

  const onSave = () => {
    onSelect(groupName);
    setGroupName('');
  };
  return (
    <Modal
      visible={visible}
      title={t(`unitScreen.${!group ? 'createGroup' : 'editGroup'}`)}
      onClose={() => openModal(false)}
      fixHeight={false}
      className="pt-6 pb-2 max-w-[350px] md:max-w-full"
    >
      <TextInput label={t('groupScreen.groupName')} onChange={(e) => setGroupName(e.target.value)} value={groupName} containerClassName="mt-4 mb-8" />
      <div className="flex items-center">
        <Button variant="tertiary" onClick={() => openModal(false)} labelClassName="!text-teacher-blue">
          {t('common.cancel')}
        </Button>
        <Button
          variant="tertiary"
          onClick={onSave}
          labelClassName="font-bold !text-teacher-blue"
          icon={<Loader isLoading={isLoading} isSmall className="mr-2" color="#0D3470" />}
        >
          {t(`common.${!group ? 'create' : 'saveChanges'}`)}
        </Button>
      </div>
    </Modal>
  );
};

export default CreateGroupModal;
