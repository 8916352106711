import React, { FormEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button, TextInput, ScreenLayout, Loader } from 'components';
import { authToastInit, restorePassword, validateHash } from 'redux/authentication/authentication.actions';
import { AppState } from 'redux/store';
import { useAppDispatch } from 'hooks/redux';
import { showToast, ToastType } from 'utils/toast';
import { validatePassword } from 'utils/validators';
import NavigationRoute from 'navigation/NavigationRoute';

export const RestorePasswordScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { restorePasswordSucceeded, validationLoading, validateHashSucceeded, validateHashFailed } = useSelector(({ auth }: AppState) => auth);
  const param = new URLSearchParams(location?.search);
  const hash = param.get('hash');
  const type = param.get('type');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState(false);
  const [matchError, setMatchError] = useState(false);

  useEffect(() => {
    if (restorePasswordSucceeded) {
      navigate(NavigationRoute.Root);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restorePasswordSucceeded]);

  useEffect(() => {
    if (hash) {
      dispatch(validateHash(hash));
    }
  }, [dispatch, hash]);

  const handleRestorePassword = (e: FormEvent) => {
    e.preventDefault();
    if (validatePassword(password) && validatePassword(passwordConfirm)) {
      setError(false);
      setMatchError(password !== passwordConfirm);
      if (password === passwordConfirm) {
        dispatch(restorePassword(hash || '', password));
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (validateHashFailed) {
      showToast({
        title: t('userForms.wrongHash'),
        onClose: () => dispatch(authToastInit()),
        type: ToastType.ERROR,
      });
    }
  }, [validateHashFailed, dispatch, t]);

  useEffect(() => {
    if (restorePasswordSucceeded) {
      showToast({
        title: t('userForms.restoreSuccess'),
        onClose: () => dispatch(authToastInit()),
        type: ToastType.SUCCESS,
      });
    }
  }, [restorePasswordSucceeded, dispatch, t]);

  return (
    <ScreenLayout>
      <div className="flex flex-1 flex-col justify-center items-center">
        <div className="flex items-top text-teacher-blue mb-6 font-bold mx-5">
          {type === 'set' && (
            <div className="mb-3">
              <div className="text-2xl mb-4">{t('restorePasswordScreen.welcome')}</div>
              <div>{t('restorePasswordScreen.welcomeDescription')}</div>
            </div>
          )}
          {type === 'reset' && (
            <div className="mb-3">
              <div className="text-2xl mb-4">{t('restorePasswordScreen.reset')}</div>
              <div>{t('restorePasswordScreen.resetDescription')}</div>
            </div>
          )}
        </div>
        <div className="max-w-md w-full">
          <Loader isLoading={validationLoading}>
            {validateHashSucceeded && (
              <form onSubmit={handleRestorePassword} className="max-w-md w-full px-5">
                <TextInput
                  label={t('userForms.createPassword')}
                  containerClassName="mb-3 mt-3 pr-2 pl-2"
                  placeholder={t('userForms.passwordPlaceholder')}
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextInput
                  label={t('userForms.passwordConfirm')}
                  containerClassName="mb-3 mt-3 pr-2 pl-2"
                  placeholder={t('userForms.passwordPlaceholder')}
                  type="password"
                  name="password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
                {error && <div className="text-teacher-blue">{t('userForms.passwordError')}</div>}
                {matchError && <div className="text-teacher-blue">{t('userForms.notMatch')}</div>}
                <Button className="mb-4 mt-3 mx-auto" type="submit">
                  {t('userForms.restorePassword')}
                </Button>
              </form>
            )}
          </Loader>
        </div>
      </div>
    </ScreenLayout>
  );
};
