import { FormVersion } from 'graphql-api';
import COLORS from 'utils/colors';

export interface IUseChart {
  data: { value: number; replierValue: number }[];
  formVersion?: FormVersion;
}

export const useChart = ({ formVersion, data }: IUseChart) => {
  const colors = formVersion !== FormVersion.VASU ? COLORS.parentalChartColors : COLORS.chartColors;
  const reversedColors = [...colors].reverse();

  const sumOfData = data.reduce((partialSum, a) => partialSum + a.value, 0);

  const yAxisKey = 'yAxis';

  const replierValues = data.map(({ replierValue }) => replierValue);

  const barData = data.reduce(
    (acc, cur) => {
      return {
        ...acc,
        [cur.replierValue]: (cur.value / sumOfData) * 100 || 0,
      };
    },
    { [yAxisKey]: 'y' },
  );

  return {
    colors: reversedColors,
    dataKeys: replierValues,
    barData: [barData],
    yAxisKey,
  };
};
