import { ChildrenEvalutationStatus, ChildrenEvalutationType, EvaluationStatus } from 'graphql-api';

export const getChildEvaluationStatus = (status?: EvaluationStatus) => {
  switch (status) {
    case EvaluationStatus.CREATED:
    case EvaluationStatus.VASU:
    case EvaluationStatus.VASU_DONE:
      return {
        childrenStatus: ChildrenEvalutationStatus.inProgress,
        childrenEvaluationType: ChildrenEvalutationType.ece,
      };
    case EvaluationStatus.DISCUSSION:
    case EvaluationStatus.DISCUSSION_READY:
      return {
        childrenStatus: ChildrenEvalutationStatus.inProgress,
        childrenEvaluationType: ChildrenEvalutationType.discussion,
      };
    case EvaluationStatus.DISCUSSION_DONE:
      return {
        childrenStatus: ChildrenEvalutationStatus.done,
        childrenEvaluationType: ChildrenEvalutationType.discussion,
      };
    case EvaluationStatus.RECAP:
    case EvaluationStatus.RECAP_READY:
      return {
        childrenStatus: ChildrenEvalutationStatus.inProgress,
        childrenEvaluationType: ChildrenEvalutationType.recap,
      };
    case EvaluationStatus.DONE:
      return {
        childrenStatus: ChildrenEvalutationStatus.done,
        childrenEvaluationType: ChildrenEvalutationType.recap,
      };
    default:
      return {
        childrenStatus: ChildrenEvalutationStatus.none,
        childrenEvaluationType: ChildrenEvalutationType.ece,
      };
  }
};
