import React from 'react';

import moment from 'moment';

import { Child, Group, Unit } from 'graphql-api';
import { ReactComponent as Trash } from 'assets/trash-icon.svg';
import colors from 'utils/colors';

export interface EditChildCardProps {
  child: Child;
  onRemoveClick: () => void;
  className?: string;
}

const EditChildCard: React.FC<EditChildCardProps> = ({ child, onRemoveClick, className = '' }) => (
  <div className={`flex flex-col bg-white rounded-lg border-2 p-4 border-admin-blue ${className}`}>
    <div className="flex items-center justify-between mb-2">
      <div className="text-teacher-blue font-bold text-xl">{`${child.firstName} ${child.lastName}`}</div>
      <Trash className="cursor-pointer" onClick={onRemoveClick} stroke={colors.teacherBlue} />
    </div>
    <div className="flex flex-col">
      <div className="text-teacher-blue mb-2">{moment(child.dateOfBirth, 'DD MM YYYY').format('DD/MM/YYYY')}</div>
      <div className="text-teacher-blue mb-2">{(child.unit as Unit).name}</div>
      <div className="text-teacher-blue mb-2">{(child.group as Group).name}</div>
    </div>
  </div>
);

export default EditChildCard;
