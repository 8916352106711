import React from 'react';

import { useParams } from 'react-router-dom';

import { ScreenLayout, Ecec } from 'components';
import { FormRouteType } from 'graphql-api';
import { getFormVersionFromRoute } from 'utils/form';

export const EcecScreen: React.FC = () => {
  const { id, formRouteType: type } = useParams();
  const formRouteType = type as FormRouteType;
  const formVersion = getFormVersionFromRoute(formRouteType);

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12 relative">
      <Ecec evaluationId={id} formRouteType={formRouteType} formVersion={formVersion} />
    </ScreenLayout>
  );
};
