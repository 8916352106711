import axios from 'axios';
import i18n from 'i18next';

export const API_URL = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 20000,
  headers: {
    Accept: 'application/json; charset=UTF-8',
    'Content-Type': 'application/json; charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'Accept-Language': i18n.language,
  },
});

export const setAuthorizationToken = (token: string): void => {
  axiosInstance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
};

export const getAuthorizationToken = (): string => axiosInstance.defaults.headers.common['Authorization'] as string;
