import React, { FC } from 'react';
import { LocaleUtils } from 'react-day-picker';

export interface YearMonthDropDownProps {
  date: Date;
  localeUtils: LocaleUtils;
  onChange: (date: Date) => void;
}

const YearMonthDropDown: FC<YearMonthDropDownProps> = ({ date, localeUtils, onChange }) => {
  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear - 6, 0);
  const toMonth = new Date(currentYear, 11);
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value, new Date().getDay()));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" value={date.getMonth()} onChange={handleChange} className="mr-2">
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" value={date.getFullYear()} onChange={handleChange}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
};

export default YearMonthDropDown;
