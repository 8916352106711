import React from 'react';

import moment from 'moment';

import { Dropdown } from 'components';

import TimeItem from './TimeItem';

export interface DateSelectorProps {
  date: Date;
  isDisabled?: boolean;
  onChange?: (newDate: Date) => void;
}

const DateDisplay: React.FC<DateSelectorProps> = (props) => {
  const minuteOptions = ['00', '15', '30', '45'];
  const hourOptions = Array.from({ length: 24 }, (_, index) => `${index}`.padStart(2, '0'));

  const { date, onChange, isDisabled = false } = props;

  const momentDate = moment(date);

  const changeHours = (hours: string) => {
    const newDate = new Date(date);
    newDate.setHours(parseInt(hours));

    if (onChange) {
      onChange(newDate);
    }
  };

  const changeMinutes = (minutes: string) => {
    const newDate = new Date(date);
    newDate.setMinutes(parseInt(minutes));

    if (onChange) {
      onChange(newDate);
    }
  };

  const hours = momentDate.format('HH');
  const minutes = momentDate.format('mm');

  return (
    <div className="flex justify-center text-teacher-blue font-semibold gap-8 md:gap-10">
      <div className="flex justify-center gap-6">
        <TimeItem>{momentDate.format('DD')}</TimeItem>
        <TimeItem>{momentDate.format('MMMM')}</TimeItem>
        <TimeItem>{momentDate.format('yyyy')}</TimeItem>
      </div>

      <div className="flex justify-center items-center">
        {isDisabled ? <TimeItem isTime>{hours}</TimeItem> : <Dropdown onChange={changeHours} options={hourOptions} defaultSelected={hours} />}:
        {isDisabled ? <TimeItem isTime>{minutes}</TimeItem> : <Dropdown onChange={changeMinutes} options={minuteOptions} defaultSelected="00" />}
      </div>
    </div>
  );
};

export default DateDisplay;
