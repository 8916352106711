import { RefObject, useEffect } from 'react';

export const useOutsideClickHandler = (ref: RefObject<HTMLDivElement>, onOutsideClick: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      onOutsideClick();
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};
