import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Modal } from 'components';
import { Child, Unit, useUnits, Group, useGroupsForCreateChildModal } from 'graphql-api';

import FormStep from './FormStep';
import DatePickerStep from './DatePickerStep';
import UnitPickerStep from './UnitPickerStep';
import GroupPickerStep from './GroupPickerStep';

export interface CreateChildModalProps {
  visible: boolean;
  openModal: (visible: boolean) => void;
  onSave: (child: Child) => void;
}

export type CreateChildModalSteps = 0 | 1 | 2 | 3;

const CreateChildModal: React.FC<CreateChildModalProps> = ({ visible, openModal, onSave }) => {
  const [child, setChild] = useState<Child>();
  const [step, setStep] = useState<CreateChildModalSteps>(0);
  const [month, setMonth] = useState<Date>(new Date());
  const { t } = useTranslation();
  const { data, loading: unitsLoading } = useUnits({ fetchPolicy: 'no-cache' });
  const { data: groupData, loading: groupsLoading } = useGroupsForCreateChildModal(child?.unit ? child.unit.slug : undefined);

  useEffect(() => {
    if (!visible) {
      setStep(0);
      setChild(undefined);
      setMonth(new Date());
    }
  }, [visible]);

  const onChange = (key: keyof Child, value: string | Date | Unit | Group) => {
    const childObject = child || ({} as Child);
    const changedObject = {
      ...childObject,
      [key]: value,
    };
    setChild(changedObject);
    if (key === 'group') {
      onSave(changedObject as Child);
      openModal(false);
    }
  };

  const onNext = () => {
    setStep((prevState) => (prevState + 1) as CreateChildModalSteps);
  };

  const titles = [t('userScreen.createChild'), t('common.dateOfBirth'), t('unitScreen.chooseUnit'), t('unitScreen.chooseGroup')];
  const filteredUnits = data?.units ? data.units.filter((item) => item.groups.length) : [];
  return (
    <Modal visible={visible} onClose={() => openModal(false)} title={titles[step]} fixHeight={step !== 1} className="pt-6 pb-2 max-w-[350px] md:max-w-full">
      {
        {
          0: <FormStep onChange={onChange} onNext={onNext} child={child} />,
          1: <DatePickerStep onChange={onChange} onNext={onNext} child={child} month={month} setMonth={setMonth} />,
          2: <UnitPickerStep onChange={onChange} onNext={onNext} filteredUnits={filteredUnits} loading={unitsLoading} />,
          3: <GroupPickerStep onChange={onChange} onNext={onNext} groups={groupData?.unitBySlug.groups} loading={groupsLoading} />,
        }[step]
      }
    </Modal>
  );
};

export default CreateChildModal;
