import React from 'react';

import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';
import { useTeacherById } from 'graphql-api';

interface IUserTeacherAnalytics {
  units?: { name: string; slug: string; id: number }[];
  selectedUnitSlug?: string;
  setUnit: (slug: string) => void;
  groups?: { name: string; slug: string }[];
}

export const useTeacherAnalytics = ({ units, selectedUnitSlug, setUnit, groups }: IUserTeacherAnalytics) => {
  const { accessLevel, unitId, userId } = useAppSelector(({ auth }) => auth);

  const isTeacher = accessLevel === AccessLevel.Teacher;

  React.useLayoutEffect(() => {
    if (isTeacher && unitId) {
      const unitOfTeacher = units?.find(({ id }) => id === unitId);

      const isSelected = selectedUnitSlug === unitOfTeacher?.slug;

      if (unitOfTeacher && !isSelected) {
        setUnit(unitOfTeacher.slug);
      }
    }
  }, [unitId, isTeacher, selectedUnitSlug, units, setUnit]);

  const { data: teacherData } = useTeacherById(userId, { fetchPolicy: 'no-cache' });

  const teachergroups = teacherData?.user.groups?.map(({ slug, name }) => ({ slug, name }));

  const filteredGroups = isTeacher ? teachergroups : groups;

  return {
    isTeacher,
    filteredGroups,
  };
};
