import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BreadCrumb, Button, DatePicker, GroupCard, Loader, TextInput, UnitAndGroupModal, FormStepArchiveButton, ArchiveForms } from 'components';
import { BreadCrumbData } from 'components/BreadCrumb';
import { Child, useUpdateChild, useDeleteChild, User } from 'graphql-api';
import { ReactComponent as Trash } from 'assets/trash-icon.svg';
import { ReactComponent as PlusCircle } from 'assets/plus-circle-icon.svg';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left-icon.svg';
import { ReactComponent as List } from 'assets/list-icon.svg';
import { ReactComponent as Refresh } from 'assets/refresh-icon.svg';
import { showToast, ToastType } from 'utils/toast';
import colors from 'utils/colors';
import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';

export interface ChildFormStepProps {
  child?: Child;
  breadcrumb: BreadCrumbData[];
  refetch: () => void;
}

export const ChildFormStep: React.FC<ChildFormStepProps> = ({ child, breadcrumb, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const isSupport = accessLevel === AccessLevel.Support;
  const [isUnitAndGroupModalOpen, setIsUnitAndGroupModalOpen] = useState(false);
  const [changedUser, setChangedUser] = useState<Child>();
  const [isEditSwitch, setIsEditSwitch] = useState(false);
  const [shouldSaveUnitAndGroup, setShouldSaveUnitAndGroup] = useState(false);

  useEffect(() => {
    setChangedUser(child);
  }, [child]);

  const [editChild, { loading: updateLoading }] = useUpdateChild({
    onCompleted(updateChildData) {
      if (updateChildData.editChild.id) {
        refetch();
        showToast({
          title: t('userMaintenanceScreen.user_edited'),
          type: ToastType.SUCCESS,
        });
      }
    },
  });

  const [deleteChild] = useDeleteChild({
    onCompleted(deleteChildData) {
      if (deleteChildData.deleteChild.id) {
        navigate(-1);
        showToast({
          title: t('userMaintenanceScreen.child_deleted'),
          type: ToastType.SUCCESS,
        });
      }
    },
  });

  const childId = child && child.id !== undefined ? +child.id : undefined;
  const removeUser = () => {
    if (child) {
      deleteChild({ variables: { id: childId } });
    }
  };

  const editUser = () => {
    editChild({
      variables: {
        id: childId,
        payload: {
          firstName: changedUser?.firstName,
          lastName: changedUser?.lastName,
          dateOfBirth: changedUser?.dateOfBirth,
          groupId: changedUser?.group?.id ? +changedUser?.group?.id : undefined,
          unitId: changedUser?.unit?.id,
        },
      },
    });
  };

  const onChange = (key: keyof Child, value: string | Date) => {
    const changedObject = changedUser || ({} as Child);
    setChangedUser({
      ...changedObject,
      [key]: value,
    });
  };

  const onEditList = () => {
    setIsEditSwitch((prevState) => !prevState);
  };

  const onGroupSave = (newGroupUser: User) => {
    const changedObject = changedUser || ({} as Child);
    setChangedUser({
      ...changedObject,
      group: newGroupUser.groups ? newGroupUser.groups[0] : undefined,
      unit: newGroupUser.unit,
    });

    if (child?.id) {
      setShouldSaveUnitAndGroup(true);
    }
  };

  const onChangeUnit = () => {
    setIsUnitAndGroupModalOpen(true);
  };

  const onRemoveUnitAndGroup = () => {
    const changedObject = changedUser || ({} as Child);
    setChangedUser({
      ...changedObject,
      group: undefined,
      unit: undefined,
    });
  };

  const disableSaveChanges = !changedUser?.firstName || !changedUser?.lastName || !changedUser?.dateOfBirth || !changedUser.unit || !changedUser.group;

  useEffect(() => {
    if (shouldSaveUnitAndGroup) {
      editChild({
        variables: {
          id: childId,
          payload: {
            groupId: changedUser?.group?.id ? +changedUser?.group?.id : undefined,
            unitId: changedUser?.unit?.id,
          },
        },
      });

      setShouldSaveUnitAndGroup(false);
      navigate(-1);
    }
  }, [navigate, shouldSaveUnitAndGroup, editChild, changedUser, childId]);

  return (
    <>
      <div className="flex items-center justify-between relative mb-4">
        <ArrowLeft className="absolute md:-left-10 cursor-pointer" onClick={() => navigate(-1)} />
        <div className="text-teacher-blue text-xl md:text-3xl font-bold ml-10 md:ml-0">{`${child?.firstName} ${child?.lastName}`}</div>
        {child && <FormStepArchiveButton onConfirm={removeUser} label={t('userMaintenanceScreen.delete_user')} disabled={isSupport} />}
      </div>
      <BreadCrumb data={breadcrumb} className="mb-8" />
      <div className="md:grid md:grid-cols-2 md:gap-24">
        <div className="flex flex-col mb-8 md:mb-0">
          <div className="text-teacher-blue text-xl font-bold mb-2">{t('userScreen.childInfo')}</div>
          <TextInput
            label={t('userScreen.firstName')}
            value={changedUser?.firstName}
            onChange={(e) => onChange('firstName', e.target.value)}
            containerClassName="mb-4"
            disabled={isSupport}
          />
          <TextInput
            label={t('userScreen.lastName')}
            value={changedUser?.lastName}
            onChange={(e) => onChange('lastName', e.target.value)}
            containerClassName="mb-4"
            disabled={isSupport}
          />
          <DatePicker selectedDate={changedUser?.dateOfBirth} onChangeDate={(date) => !isSupport && onChange('dateOfBirth', date)} disabled={isSupport} />
          <div className="flex items-center justify-between mt-6 gap-6">
            <Button variant="secondary" onClick={() => navigate(-1)}>
              {t('common.cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={editUser}
              icon={<Loader isLoading={updateLoading} isSmall className="mr-2" color="white" />}
              disabled={disableSaveChanges || isSupport}
            >
              {t('common.saveChanges')}
            </Button>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between mb-4">
            <div className="text-teacher-blue text-xl font-bold">{t('userScreen.childsUnitAndGroup')}</div>
            {child && (
              <Button
                variant="tertiary"
                onClick={onEditList}
                labelClassName="font-bold flex items-center !text-teacher-blue"
                className="w-fit !p-0"
                disabled={isSupport}
              >
                {!isEditSwitch && <List className="mr-2" />}
                {!isEditSwitch ? t('userScreen.editList') : t('common.cancel')}
              </Button>
            )}
          </div>
          {!!changedUser?.unit && (
            <div className="flex flex-col bg-white rounded-lg border-2 px-4 pt-4 pb-8 border-admin-blue">
              <div className="flex items-center justify-between mb-1">
                <div className="text-teacher-blue font-bold">{changedUser.unit?.name}</div>
                {isEditSwitch && <Trash className="cursor-pointer ml-2" stroke={colors.teacherBlue} onClick={onRemoveUnitAndGroup} />}
              </div>
              <div className="text-teacher-blue">{`${changedUser.unit.zipCode}, ${changedUser.unit.city}, ${changedUser.unit.addressLine}`}</div>
            </div>
          )}
          {isEditSwitch && changedUser?.unit && (
            <div className="flex justify-center mt-3">
              <Button variant="tertiary" labelClassName="!text-teacher-blue w-full flex justify-center" className="w-fit" onClick={onChangeUnit}>
                <Refresh className="mr-2" />
                {t('userScreen.changeUnit')}
              </Button>
            </div>
          )}
          <UnitAndGroupModal
            visible={isUnitAndGroupModalOpen}
            openModal={setIsUnitAndGroupModalOpen}
            onSave={onGroupSave}
            selectedGroups={changedUser?.group ? [changedUser.group.id] : []}
          />
          {changedUser?.unit && <div className="my-4 mx-auto w-3/4 h-0.5 bg-divider" />}
          {!!changedUser?.group && (
            <GroupCard
              group={changedUser?.group}
              numberOfChildren={changedUser?.group.children.length}
              onDelete={isEditSwitch ? () => onRemoveUnitAndGroup() : undefined}
            />
          )}
          {isEditSwitch && changedUser?.group && (
            <div className="flex justify-center mt-3">
              <Button variant="tertiary" labelClassName="!text-teacher-blue w-full flex justify-center" className="w-fit" onClick={onChangeUnit}>
                <Refresh className="mr-2" />
                {t('userScreen.changeGroup')}
              </Button>
            </div>
          )}
          {!changedUser?.group && (
            <div className="flex justify-center">
              <Button
                variant="tertiary"
                labelClassName="!text-teacher-blue w-full flex justify-center"
                className="w-fit"
                onClick={() => setIsUnitAndGroupModalOpen(true)}
              >
                <PlusCircle className="mr-2" />
                {t('groupMaintenanceScreen.new')}
              </Button>
            </div>
          )}
        </div>
      </div>

      {!!child?.id && <ArchiveForms childId={child.id} />}
    </>
  );
};
