import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, ConfirmModal } from 'components';
import colors from 'utils/colors';
import { ReactComponent as Trash } from 'assets/trash-icon.svg';

export interface FormStepArchiveButtonProps {
  label: string;
  onCancel?: () => void;
  onConfirm: () => void;
  disabled?: boolean;
}

const FormStepArchiveButton: React.FC<FormStepArchiveButtonProps> = (props) => {
  const { onCancel, onConfirm, label, disabled } = props;
  const { t } = useTranslation();

  const [shouldOpenArchiveModal, setShouldOpenArchiveModal] = React.useState(false);

  const confirm = () => {
    setShouldOpenArchiveModal(false);
    onConfirm();
  };

  const cancel = () => {
    setShouldOpenArchiveModal(false);

    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      <ConfirmModal isVisible={shouldOpenArchiveModal} onCancel={cancel} onConfirm={confirm} title={t('common.deleteSubtitle')} />
      {!disabled && (
        <Button variant="tertiary" className="w-fit" labelClassName="w-full flex justify-center" onClick={() => setShouldOpenArchiveModal(true)}>
          <Trash className="mr-2" stroke={colors.adminBlue} />
          <span className="hidden md:inline">{label}</span>
        </Button>
      )}
    </>
  );
};

export default FormStepArchiveButton;
