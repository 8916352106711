import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ScreenLayout, Selector, ChildsRelationship, GroupResults, ParentalInvolvement, ShowMore } from 'components';
import { AnalyticsType } from 'graphql-api';

export const Analytics: React.FC = () => {
  const { t } = useTranslation();
  const { formType } = useParams();
  const analyticsFormType = formType as AnalyticsType;
  const navigate = useNavigate();
  const analyticsSelector: AnalyticsType[] = ['child-relationship', 'group-results', 'parental-involvement'];
  const selectorData = analyticsSelector.map((item) => ({ label: t(`analyticsScreen.${item}.buttonTitle`), value: item }));
  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <div className="text-teacher-blue text-xl md:text-3xl font-bold mb-4">{t('analyticsScreen.title')}</div>
      <Selector data={selectorData} selectedItem={analyticsFormType} onClick={(item) => navigate(`/analytics/${item}`)} />
      <ShowMore className="text-teacher-blue mt-3 md:mt-6 md:text-sm mb-6" text={t(`analyticsScreen.${analyticsFormType}.description`)} />
      {
        {
          'child-relationship': <ChildsRelationship />,
          'group-results': <GroupResults />,
          'parental-involvement': <ParentalInvolvement />,
        }[analyticsFormType]
      }
    </ScreenLayout>
  );
};
