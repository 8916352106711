import i18n, { TFunction } from 'i18next';

export interface LanguageMenuItem {
  title: string;
  onClick: () => void;
  active: string;
}

export const getLanguageMenu = (t: TFunction): LanguageMenuItem[] =>
  i18n.services.resourceStore
    ? Object.keys(i18n.services.resourceStore.data).map((language) => ({
        title: t(`languages.${language}`),
        onClick: () => i18n.changeLanguage(language),
        active: language,
      }))
    : [];
