import { gql } from '@apollo/client';

export const UNIT_QUERY = gql`
  query Units {
    units {
      id
      slug
      name
      city
      addressLine
      zipCode
      canOpenEvaluations
      admin {
        id
        slug
        firstName
        lastName
      }
      groups {
        id
      }
    }
  }
`;

export const USER_BY_ROLE_QUERY_FOR_USER_LIST_SCREEN = gql`
  query User($role: String!, $onlyActive: Boolean) {
    users(role: $role, onlyActive: $onlyActive) {
      id
      slug
      firstName
      lastName
      email
      active
      ownedUnits {
        id
        slug
        name
      }
      unit {
        id
        slug
        name
      }
      children {
        id
        slug
        firstName
        lastName
        active
        unit {
          id
          slug
          name
        }
      }
    }
  }
`;

export const USER_BY_ROLE_QUERY_FOR_UNIT_FORM_STEP = gql`
  query User($role: String!) {
    users(role: $role) {
      id
      slug
      firstName
      lastName
    }
  }
`;

export const USER_BY_ROLE_QUERY_FOR_CALENDAR_OF_TEACHER = gql`
  query User($role: String!) {
    users(role: $role) {
      id
      slug
      firstName
      lastName
      unit {
        slug
      }
    }
  }
`;

export const USER_BY_ROLE_QUERY_FOR_TEACHER_BY_UNIT_SLUG = gql`
  query User($role: String!) {
    users(role: $role) {
      id
      slug
      firstName
      lastName
      unit {
        slug
      }
    }
  }
`;

export const GET_FAQ = gql`
  query FrequentlyAskedQuestions {
    frequentlyAskedQuestions {
      question {
        en
        fi
        sv
      }
      answer {
        en
        fi
        sv
      }
    }
  }
`;

export const CHILDREN_BY_GROUP_SLUG = gql`
  query groupBySlug($slug: String!) {
    groupBySlug(slug: $slug) {
      id
      slug
      name
      children {
        id
        slug
        firstName
        lastName
        dateOfBirth
        active
        evaluation {
          id
          status
        }
        unit {
          id
          slug
          name
          city
          addressLine
          zipCode
        }
        group {
          id
          slug
          name
        }
        evaluation {
          status
        }
      }
    }
  }
`;

export const GROUPS_BY_UNIT_SLUG_QUERY = gql`
  query unitBySlug($slug: String!) {
    unitBySlug(slug: $slug) {
      id
      name
      slug
      city
      addressLine
      zipCode
      canOpenEvaluations
      admin {
        id
        slug
        firstName
        lastName
      }
      groups {
        id
        name
        unitId
        slug
        evaluationStatus {
          ecec
          discussion
          recap
          done
          status
        }
        children {
          id
          slug
          firstName
          lastName
          dateOfBirth
          unit {
            id
            slug
            name
            city
            addressLine
            zipCode
          }
          group {
            id
            name
            slug
            children {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_CHILD_BY_SLUG = gql`
  query childBySlug($slug: String!) {
    childBySlug(slug: $slug) {
      id
      slug
      firstName
      lastName
      dateOfBirth
      unit {
        id
        name
        city
        addressLine
        zipCode
      }
      parent {
        id
        firstName
        lastName
      }
      group {
        id
        name
        evaluationStatus {
          ecec
          discussion
          recap
          done
          status
        }
        children {
          id
          slug
        }
      }
    }
  }
`;

export const GET_CONSULTATION_BY_TEACHER = gql`
  query Consultation($teacherID: Int!, $childID: Int, $showOnlyVacant: Boolean!) {
    consultation(teacherID: $teacherID, childID: $childID, showOnlyVacant: $showOnlyVacant) {
      id
      evaluationId
      startsAt
      type
      parent {
        id
        firstName
        lastName
      }
      teacher {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_FORM_BY_CHILD_ID = gql`
  query Evaluation($childId: Int!, $type: String!) {
    evaluations(childId: $childId) {
      id
      status
      teacher {
        id
        firstName
        lastName
      }
      form(type: $type) {
        parentHasSigned
        teacherHasSigned
      }
    }
  }
`;

export const GET_PARENTS_CHILDREN = gql`
  query User($id: Int!) {
    user(id: $id) {
      children {
        id
        firstName
        lastName
      }
    }
  }
`;

export const PARENTAL_ANALYTICS = gql`
  query parentalAnalytics($queryData: AnalyticsQueryDto!) {
    parentalAnalytics(queryData: $queryData) {
      forms {
        name {
          en
          fi
          sv
        }
        questions {
          answers {
            values {
              repliers
              value
            }
          }
          question {
            en
            fi
            sv
          }
          description {
            en
            fi
            sv
          }
        }
      }
    }
  }
`;

export const VASU_ANALYTICS = gql`
  query vasuAnalytics($queryData: AnalyticsQueryDto!) {
    vasuAnalytics(queryData: $queryData) {
      child {
        firstName
        lastName
        dateOfBirth
        enrollmentDate
        healthAndOtherReport
      }
      forms {
        name {
          en
          fi
          sv
        }
        questions {
          answers {
            agreed
            myAnswer
            discussionAnswer
            values {
              repliers
              value
            }
          }
          question {
            en
            fi
            sv
          }
          description {
            en
            fi
            sv
          }
        }
      }
    }
  }
`;

export const GET_PARENTAL_INVOLVEMENT_FORM = gql`
  query Evaluation($id: Int!, $type: String!) {
    evaluation(id: $id) {
      id
      updatedAt
      ageOfChildAtEvaluation
      child {
        id
        firstName
        lastName
        dateOfBirth
        healthAndOtherReport
        enrollmentDate
        parentalInvolvementForm {
          parentHasSigned
        }
        parent {
          id
          firstName
          lastName
        }
      }
      teacher {
        id
        firstName
        lastName
      }
      status
      form(type: $type) {
        parentHasSigned
        teacherHasSigned
        formGroups {
          name {
            en
            fi
            sv
          }
          id
          questions {
            questionType
            id
            description {
              en
              fi
              sv
            }
            question {
              en
              fi
              sv
            }
            answers {
              id
              answer
              type
              replier {
                id
                role
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FORM_BY_EVALUATION_ID = gql`
  query Evaluation($id: Int!, $type: String!) {
    evaluation(id: $id) {
      id
      updatedAt
      comment
      goal
      ageOfChildAtEvaluation
      support {
        ecec
        discussion
        recap
      }
      evaluationOfSupport {
        ecec
        discussion
        recap
      }
      otherParticipated {
        ecec
        discussion
        recap
      }
      child {
        id
        firstName
        lastName
        dateOfBirth
        healthAndOtherReport
        enrollmentDate
        parentalInvolvementForm {
          parentHasSigned
        }
        parent {
          id
          firstName
          lastName
        }
      }
      teacher {
        id
        firstName
        lastName
      }
      status
      form(type: $type) {
        parentHasSigned
        teacherHasSigned
        teacherSignedName
        parentSignedName
        teacherSignedDate
        parentSignedDate
        formGroups {
          changes
          name {
            en
            fi
            sv
          }
          id
          questions {
            questionType
            id
            description {
              en
              fi
              sv
            }
            question {
              en
              fi
              sv
            }
            isImportantToReach
            reached
            answers {
              id
              answer
              type
              replier {
                id
                role
              }
            }
          }
        }
      }
    }
  }
`;

export const TEACHER_BY_ID = gql`
  query User($id: Int!) {
    user(id: $id) {
      id
      unit {
        canOpenEvaluations
      }
      groups {
        id
        name
        unitId
        slug
        evaluationStatus {
          ecec
          discussion
          recap
          done
          status
        }
        children {
          id
          slug
          firstName
          lastName
        }
      }
    }
  }
`;

export const PARENT_BY_ID = gql`
  query User($id: Int!) {
    user(id: $id) {
      id
      slug
      role
      firstName
      lastName
      email
      ownedUnits {
        id
        slug
        name
        city
        zipCode
        addressLine
      }
      unit {
        id
        slug
        name
        city
        zipCode
        addressLine
        canOpenEvaluations
      }
      groups {
        id
        name
        unitId
        slug
        evaluationStatus {
          ecec
          discussion
          recap
          done
          status
        }
        children {
          id
          slug
          firstName
          lastName
        }
      }
      children {
        id
        slug
        firstName
        lastName
        dateOfBirth
        active
        evaluation {
          id
          status
          evaluationFormStatus
        }
        unit {
          id
          slug
          name
          city
          addressLine
          zipCode
        }
        group {
          id
          slug
          name
        }
      }
    }
  }
`;

export const USER_BY_ID_QUERY_FOR_UNITADMIN_CALENDAR = gql`
  query User($id: Int!) {
    user(id: $id) {
      ownedUnits {
        slug
        name
      }
    }
  }
`;

export const USER_BY_SLUG_QUERY = gql`
  query userBySlug($slug: String!) {
    userBySlug(slug: $slug) {
      id
      slug
      firstName
      lastName
      email
      unit {
        id
        slug
        name
        city
        addressLine
        zipCode
      }
      groups {
        id
        name
        slug
        children {
          id
        }
      }
      ownedUnits {
        id
        slug
        name
        city
        zipCode
        addressLine
      }
      children {
        id
        slug
        firstName
        lastName
        dateOfBirth
        active
        group {
          id
        }
        unit {
          id
        }
        evaluation {
          status
        }
      }
    }
  }
`;

export const USER_BY_ID_QUERY_FOR_ECEC_LANDING = gql`
  query User($id: Int!) {
    user(id: $id) {
      id
      slug
      unit {
        canOpenEvaluations
      }
      groups {
        id
        name
        slug
        evaluationStatus {
          ecec
          discussion
          recap
          done
          status
        }
        children {
          id
          slug
          firstName
          lastName
        }
      }
      children {
        id
        firstName
        lastName
        evaluation {
          id
          status
        }
      }
    }
  }
`;

export const FIND_BY_ID_QUERY_FOR_PARENT_STATUSES = gql`
  query findParentFormsStatuses($id: Int!) {
    findParentFormsStatuses(id: $id) {
      parentalInvolvementForm
      evaluationForm
      discussionForm
      recapForm
      bookedAppointment {
        id
        type
      }
    }
  }
`;
