import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Loader, ScreenLayout } from 'components';
import { GroupFormStep } from 'components/Units';
import { useGroupBySlugForEditGroupScreen, GroupForEditGroupScreen } from 'graphql-api';
import { useUnitName } from 'hooks/useUnitName';

export const EditGroupScreen: React.FC = () => {
  const { t } = useTranslation();
  const { unitSlug, groupSlug } = useParams();
  const [group, setGroup] = useState<GroupForEditGroupScreen>();

  const { isLoadingUnit, unitName } = useUnitName(unitSlug);

  const { loading: isLoadingGroup } = useGroupBySlugForEditGroupScreen(groupSlug, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data.groupBySlug) {
        setGroup(data.groupBySlug);
      }
    },
  });

  const breadcrumb = [
    {
      step: '/units',
      title: t('unitScreen.title'),
    },
    {
      step: `/units/${unitSlug}`,
      title: unitName,
    },
    {
      step: `/units/${unitSlug}/${groupSlug}`,
      title: group?.name || '',
    },
  ];

  const isLoading = isLoadingUnit || isLoadingGroup;

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <Loader isLoading={isLoading}>{!!unitSlug && <GroupFormStep group={group} unitSlug={unitSlug} breadcrumb={breadcrumb} />}</Loader>
    </ScreenLayout>
  );
};
