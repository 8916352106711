import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Loader, Modal } from 'components';
import { useAppSelector } from 'hooks/redux';
import { useAcceptGdpr } from 'graphql-api';
import { acceptGDPR } from 'redux/authentication/authentication.actions';

const GDPRWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { acceptedGDPR, isAuthenticated } = useAppSelector(({ auth }) => auth);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!acceptedGDPR && isAuthenticated) {
      setIsOpen(true);
    }
  }, [acceptedGDPR, isAuthenticated]);

  const [acceptGdpr, { loading }] = useAcceptGdpr({
    onCompleted(data) {
      if (data.acceptGDPR.acceptedGDPR) {
        setIsOpen(false);
        dispatch(acceptGDPR());
      }
    },
  });

  const onGTC = () => {
    acceptGdpr();
  };
  return (
    <>
      <Modal visible={isOpen} title={t('gtcModal.title')} fixHeight={false} className="pt-6 pb-2 relative max-w-[350px] md:max-w-full">
        <div className="text-teacher-blue pb-16 pr-2 text-sm">
          <span>{t('gtcModal.acceptTermsLinklessPart')}</span>{' '}
          <a
            href="/Asiakasrekisterin tietosuojaseloste_OyAnkkalampiAnkdammenAb.pdf"
            target="_blank"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            {t('gtcModal.acceptTermsLinkPart')}
          </a>
        </div>

        <Loader isLoading={loading}>
          <div className="flex items-center absolute bottom-0 w-full bg-white">
            <Button variant="tertiary" onClick={() => setIsOpen(false)} labelClassName="!text-teacher-blue">
              {t('common.disagree')}
            </Button>
            <Button variant="tertiary" onClick={onGTC} labelClassName="font-bold !text-teacher-blue">
              {t('common.agree')}
            </Button>
          </div>
        </Loader>
      </Modal>
      {children}
    </>
  );
};

export default GDPRWrapper;
