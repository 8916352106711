import React from 'react';
import { useGroupBySlugForBreadcrumb } from 'graphql-api';

export const useGroupName = (groupSlug?: string) => {
  const [groupName, seGroupName] = React.useState('');

  const { loading: isLoadingGroup } = useGroupBySlugForBreadcrumb(groupSlug, {
    onCompleted(data) {
      if (data.groupBySlug) {
        seGroupName(data.groupBySlug.name);
      }
    },
  });

  return {
    isLoadingGroup,
    groupName,
  };
};
