import { useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useGroupResultsAnalytics, useUnits } from 'graphql-api';
import { ages } from 'utils/form';

export interface IUseGroupResults {
  evaluationsFrom: string;
  evaluationsUntil: string;
}

export const useGroupResults = (interval?: IUseGroupResults) => {
  const { t } = useTranslation();
  const [selectedUnitSlug, setSelectedUnitSlug] = useState<string>();
  const [selectedAgeGroup, setSelectedAgeGroup] = useState<string>();
  const { data, loading: unitLoading } = useUnits();

  const {
    data: analytics,
    error,
    loading: isLoadingAnalytics,
  } = useGroupResultsAnalytics(selectedAgeGroup, selectedUnitSlug, interval?.evaluationsFrom, interval?.evaluationsUntil);

  const units = useMemo(
    () =>
      data?.units
        .filter((item) => !!item.groups.length)
        .map((filteredItem) => ({
          slug: filteredItem.slug,
          name: filteredItem.name,
          id: filteredItem.id,
        })),
    [data],
  );

  const ageGroups = ages.map((item) => ({
    slug: item,
    name: `${item} ${t(`ageGroups.${item}`)}`,
  }));

  const setUnit = (unit: string) => {
    setSelectedUnitSlug(unit);
    setSelectedAgeGroup(undefined);
  };

  return {
    analytics,
    analyticsError: error,
    unitLoading,
    isLoadingAnalytics,
    selectedUnitSlug,
    ageGroups,
    selectedAgeGroup,
    setSelectedAgeGroup,
    units,
    setUnit,
  };
};
