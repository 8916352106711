import { User } from './interfaces';

export const LOGIN_REQUEST = 'LOGINS_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const RESTORE_PASSWORD_REQUEST = 'RESTORE_PASSWORD_REQUEST';
export const RESTORE_PASSWORD_SUCCESS = 'RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_FAILURE = 'RESTORE_PASSWORD_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const VALIDATE_HASH_REQUEST = 'VALIDATE_HASH_REQUEST';
export const VALIDATE_HASH_SUCCESS = 'VALIDATE_HASH_SUCCESS';
export const VALIDATE_HASH_FAILURE = 'VALIDATE_HASH_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const AUTH_TOAST_INIT = 'AUTH_TOAST_INIT';
export const ACCEPT_GDPR = 'ACCEPT_GDPR';

export interface IAcceptGdprAction {
  type: typeof ACCEPT_GDPR;
}

export interface IAuthToastInitAction {
  type: typeof AUTH_TOAST_INIT;
}

export interface ILoginRequestAction {
  type: typeof LOGIN_REQUEST;
}

export interface ILoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  payload: User;
}

export interface ILoginFailureAction {
  type: typeof LOGIN_FAILURE;
  error: string;
}

export interface ILogoutRequestAction {
  type: typeof LOGOUT_REQUEST;
}

export interface ILogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}

export interface ILogoutFailureAction {
  type: typeof LOGOUT_FAILURE;
  error: string;
}

export interface IRestorePasswordRequestAction {
  type: typeof RESTORE_PASSWORD_REQUEST;
}

export interface IRestorePasswordSuccessAction {
  type: typeof RESTORE_PASSWORD_SUCCESS;
}

export interface IRestorePasswordFailureAction {
  type: typeof RESTORE_PASSWORD_FAILURE;
  error: string;
}

export interface IForgotPasswordRequestAction {
  type: typeof FORGOT_PASSWORD_REQUEST;
}

export interface IForgotPasswordSuccessAction {
  type: typeof FORGOT_PASSWORD_SUCCESS;
}

export interface IForgotPasswordFailureAction {
  type: typeof FORGOT_PASSWORD_FAILURE;
  error: string;
}

export interface IValidateHashRequestAction {
  type: typeof VALIDATE_HASH_REQUEST;
}

export interface IValidateHashSuccessAction {
  type: typeof VALIDATE_HASH_SUCCESS;
}

export interface IValidateHashFailureAction {
  type: typeof VALIDATE_HASH_FAILURE;
  error: Error;
}

export interface IRefreshTokenRequestAction {
  type: typeof REFRESH_TOKEN_REQUEST;
}
export interface IRefreshTokenSuccessAction {
  type: typeof REFRESH_TOKEN_SUCCESS;
  payload: {
    accessToken: string;
    refreshToken: string;
  };
}

export interface IRefreshTokenFailureAction {
  type: typeof REFRESH_TOKEN_FAILURE;
  error: string;
}

export type AuthActionTypes =
  | ILoginRequestAction
  | ILoginSuccessAction
  | ILoginFailureAction
  | ILogoutRequestAction
  | ILogoutSuccessAction
  | ILogoutFailureAction
  | IRestorePasswordRequestAction
  | IRestorePasswordSuccessAction
  | IRestorePasswordFailureAction
  | IForgotPasswordRequestAction
  | IForgotPasswordSuccessAction
  | IForgotPasswordFailureAction
  | IValidateHashRequestAction
  | IValidateHashSuccessAction
  | IValidateHashFailureAction
  | IRefreshTokenRequestAction
  | IRefreshTokenSuccessAction
  | IRefreshTokenFailureAction
  | IAuthToastInitAction
  | IAcceptGdprAction;
