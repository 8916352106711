import { toast } from 'react-toastify';

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export interface ToastProps {
  title: string;
  onClose?: () => void;
  type: ToastType;
}

export const showToast = ({ title, onClose, type }: ToastProps) => {
  toast[type](title, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClose,
  });
};
