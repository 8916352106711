import React, { PropsWithChildren } from 'react';

import { Menu } from 'components';
import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';

export interface ScreenLayoutProps {
  layoutClassName?: string;
}

const ScreenLayout: React.FC<PropsWithChildren<ScreenLayoutProps>> = ({ children, layoutClassName }) => {
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const background = accessLevel === AccessLevel.Parent ? 'bg-white' : 'bg-background';

  const [isOpenOnMobile, setIsOpenOnMobile] = React.useState(false);

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <Menu isOpenOnMobile={isOpenOnMobile} toggleOnMobile={() => setIsOpenOnMobile((prev) => !prev)} />
      <div className={`flex flex-1 overflow-auto pb-10 ${background} ${layoutClassName || ''} ${isOpenOnMobile ? 'hidden' : ''}`}>{children}</div>
    </div>
  );
};

export default ScreenLayout;
