import React from 'react';

import { useTranslation } from 'react-i18next';

import { Modal } from 'components';
import { User } from 'graphql-api';

export interface UnitAdminModalProps {
  visible: boolean;
  openModal: (visible: boolean) => void;
  onSelect: (unit: User) => void;
  users?: User[];
  selectedAdmin: User | undefined;
}

const UnitAdminModal: React.FC<UnitAdminModalProps> = ({ visible, openModal, onSelect, users, selectedAdmin }) => {
  const { t } = useTranslation();

  const selectItem = (user: User) => {
    onSelect(user);
    openModal(false);
  };
  return (
    <Modal visible={visible} title={t('unitScreen.chooseAdmin')} onClose={() => openModal(false)} className="max-w-[350px] md:max-w-full">
      {users &&
        users.map((item) => {
          const selected = item.id === selectedAdmin?.id;
          const selectedBackground = selected ? 'bg-admin-blue' : '';
          return (
            <button
              key={item.id}
              className={`text-teacher-blue ${selectedBackground} hover:bg-background py-2 pl-2 text-left`}
              onClick={() => selectItem(item)}
            >
              {`${item.firstName} ${item.lastName}`}
            </button>
          );
        })}
    </Modal>
  );
};

export default UnitAdminModal;
