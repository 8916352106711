import { QueryHookOptions, useQuery } from '@apollo/client';

import { VASU_ANALYTICS } from './queries';
import { Analytics, AnalyticsChartType } from './types';

export const useGroupResultsAnalytics = (age?: string, unitSlug?: string, evaluationsFrom?: string, evaluationsUntil?: string, options?: QueryHookOptions) =>
  useQuery<{ vasuAnalytics: Analytics }>(VASU_ANALYTICS, {
    skip: !age || !unitSlug,
    variables: { queryData: { age, unitSlug, chartType: AnalyticsChartType.PIE, evaluationsFrom, evaluationsUntil } },
    ...options,
  });
