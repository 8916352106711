import React, { FormEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import NavigationRoute from 'navigation/NavigationRoute';
import { Button, Loader, TextInput, ScreenLayout } from 'components';
import { loginUser } from 'redux/authentication/authentication.actions';
import { AppState } from 'redux/store';
import { useAppDispatch } from 'hooks/redux';
import { ReactComponent as TextLogo } from 'assets/text-logo.svg';

export const LoginScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading } = useSelector(({ auth }: AppState) => auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e: FormEvent) => {
    e.preventDefault();
    dispatch(loginUser({ email, password }));
  };

  return (
    <ScreenLayout>
      <div className="flex flex-1 flex-col justify-center items-center">
        <div className="text-2xl md:text-5xl flex items-center text-teacher-blue mb-6 font-bold">
          <span className="mr-2">{t('common.welcome')}</span>
          <TextLogo className="-ml-2 -mr-1 h-6 md:scale-200 md:mx-8 md:translate-y-0.5" />
          <span>!</span>
        </div>
        <form onSubmit={handleLogin} className="max-w-md w-full px-5">
          <TextInput
            label={t('userForms.email')}
            placeholder={t('userForms.emailPlaceholder')}
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            containerClassName="mb-6"
          />
          <TextInput
            label={t('userForms.password')}
            placeholder={t('userForms.passwordPlaceholder')}
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            containerClassName="mb-6"
          />
          <Loader isLoading={isLoading}>
            <div className="flex flex-col">
              <Link to={NavigationRoute.ForgotPassword} className="ml-auto text-admin-blue">
                {t('userForms.forgotPassword')}
              </Link>
              <Button type="submit" className="mx-auto mt-8 w-32 md:w-56">
                {t('userForms.login')}
              </Button>
            </div>
          </Loader>
        </form>
      </div>
    </ScreenLayout>
  );
};
