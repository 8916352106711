import React from 'react';

import { ReactComponent as Done } from 'assets/donegoal.svg';
import { ReactComponent as CancelAppointmentIcon } from 'assets/cancel-appointment.svg';
import { useTranslation } from 'react-i18next';
import { Evaluation } from 'graphql-api';

export interface SwitchProps {
  formGroup: Evaluation | null;
}

const SupportFormStatus: React.FC<SwitchProps> = ({ formGroup }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center text-teacher-blue ml-4 text-xs">
      <div>
        {formGroup?.form?.teacherHasSigned !== null && (
          <div className="flex items-center">
            {formGroup?.form.teacherHasSigned ? <Done width="18" /> : <CancelAppointmentIcon />}
            <div className="mr-2 ml-2">{t('accessLevels.Teacher')}</div>
          </div>
        )}
      </div>
      <div>
        {formGroup?.form?.parentHasSigned !== null && (
          <div className="flex items-center">
            {formGroup?.form.parentHasSigned ? <Done width="18" /> : <CancelAppointmentIcon />}
            <div className="mr-2 ml-2">{t('accessLevels.Parent')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportFormStatus;
