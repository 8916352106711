import React from 'react';

import moment from 'moment';

export interface TimeViewHeaderProps {
  date: Date;
}

const TimeViewHeader: React.FC<TimeViewHeaderProps> = ({ date }) => {
  return (
    <div>
      <div className="m-1 -ml-0.5 sm:ml-1">{moment(date).format('ddd')}</div>
      <div className="font-normal opacity-60 m-1">{moment(date).format('DD')}</div>
    </div>
  );
};

export default TimeViewHeader;
