import React from 'react';

import { ReactComponent as Search } from 'assets/search-icon.svg';
import { AccessLevel } from 'redux/authentication/enums';
import colors from 'utils/colors';

export type TextInputProps = JSX.IntrinsicElements['input'] & {
  label?: string;
  labelClassName?: string;
  containerClassName?: string;
  isSearch?: boolean;
  onClick?: () => void;
  accessLevel?: AccessLevel;
};

const TextInput = ({
  className = '',
  isSearch,
  containerClassName = '',
  labelClassName = '',
  name,
  label,
  onClick,
  accessLevel,
  disabled,
  value,
  ...props
}: TextInputProps) => {
  const isSearchPadding = isSearch ? 'pl-10' : '';
  const isParent = accessLevel === AccessLevel.Parent;
  const borderColor = disabled ? 'border-disabled' : isParent ? 'border-parent-green' : 'border-admin-blue';
  const textColor = disabled ? 'text-disabled' : isParent ? 'text-parent-green' : 'text-teacher-blue';
  const searchColor = isParent ? colors.parentGreen : colors.adminBlue;
  return (
    <div className={`relative flex flex-col ${containerClassName}`} role="button" tabIndex={0} onClick={onClick}>
      {label && (
        <label htmlFor={name} className={`ml-2 mb-1 md:mb-2 text-teacher-blue text-xs md:text-base font-semibold ${labelClassName}`}>
          {label.toUpperCase()}
        </label>
      )}
      {isSearch && <Search className="absolute top-1/4 left-3" stroke={searchColor} />}
      <input
        name={name}
        type="text"
        className={`focus:outline-none text-xs md:text-base p-3 rounded-lg border-2 bg-white ${borderColor} ${textColor} ${isSearchPadding} ${className}`}
        value={value}
        {...props}
      />
    </div>
  );
};

export default TextInput;
