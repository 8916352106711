import moment from 'moment';
import type { DateLocalizer } from 'react-big-calendar';

import type { ConsultingEvent } from 'graphql-api';

export const eventStartAccessor = (event: ConsultingEvent) => {
  return moment(event.startsAt).toDate();
};

export const eventEndAccessor = (event: ConsultingEvent) => {
  const start = moment(event.startsAt);

  return start.add(45, 'minute').toDate();
};

const formatRange = (range: { start: Date; end: Date }, culture: string, localizer: DateLocalizer) => {
  const start = localizer.format(range.start, 'MMMM DD');
  const end = localizer.format(range.end, 'MMMM DD');
  const year = localizer.format(range.start, 'yyyy');

  return `${start} - ${end} ${year}`;
};

const formatTimeRange = (range: { start: Date; end: Date }, culture: string, localizer: DateLocalizer) => {
  const start = localizer.format(range.start, 'HH:mm');
  const end = localizer.format(range.end, 'HH:mm');

  return `${start} - ${end}`;
};

export const formats = {
  timeGutterFormat: 'HH:00',
  agendaHeaderFormat: formatRange,
  dayHeaderFormat: 'MMMM D yyyy',
  dayRangeHeaderFormat: formatRange,
  eventTimeRangeStartFormat: 'HH:mm',
  eventTimeRangeEndFormat: 'HH:mm',
  agendaTimeRangeFormat: formatTimeRange,
  eventTimeRangeFormat: ' ',
};
