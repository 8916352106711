import React from 'react';

import { useTranslation } from 'react-i18next';

import { Child, ChildrenEvalutationStatus, ChildrenEvalutationType } from 'graphql-api';
import { ReactComponent as Edit } from 'assets/edit-icon.svg';
import { ReactComponent as Done } from 'assets/done-icon.svg';
import { ReactComponent as Document } from 'assets/document-icon.svg';
import { ReactComponent as InProgress } from 'assets/inprogress-icon.svg';
import { Button } from 'components';
import { AccessLevel } from 'redux/authentication/enums';
import colors from 'utils/colors';

export interface ChildCardProps {
  child: Pick<Child, 'firstName' | 'lastName' | 'active'>;
  onEditClick?: () => void;
  onClick?: () => void;
  childrenStatus: ChildrenEvalutationStatus;
  childrenEvaluationType?: ChildrenEvalutationType;
  className?: string;
  accessLevel?: AccessLevel;
}

const ChildCard: React.FC<ChildCardProps> = ({ onClick, child, onEditClick, childrenStatus, childrenEvaluationType, accessLevel, className = '' }) => {
  const { t } = useTranslation();
  const borderColor = accessLevel === AccessLevel.Parent ? 'border-parent-green' : 'border-admin-blue';

  const isNotReadyForParent = childrenStatus === ChildrenEvalutationStatus.none && accessLevel === AccessLevel.Parent;

  return (
    <div className={`flex flex-col bg-white rounded-lg border-2 p-4 ${borderColor} ${className}`}>
      <div className="flex items-center justify-between mb-1">
        <div className="text-teacher-blue font-bold text-xl">{`${child.firstName} ${child.lastName}`}</div>
        {onEditClick && <Edit className="cursor-pointer" onClick={onEditClick} />}
      </div>
      <div className="flex items-center">
        {child?.active &&
          {
            [ChildrenEvalutationStatus.done]: (
              <>
                <Done stroke={colors.done} />
                <div className="text-done ml-2 font-medium">
                  {t(`childrenEvaluationTypes.${childrenEvaluationType}`)} {t('common.done')}
                </div>
              </>
            ),
            [ChildrenEvalutationStatus.inProgress]: (
              <>
                <InProgress />
                <div className="text-progress ml-2 font-medium">{`${t(`childrenEvaluationTypes.${childrenEvaluationType}`)} ${t('common.inProgress')}`}</div>
              </>
            ),
            [ChildrenEvalutationStatus.none]: (
              <>
                <Document />
                <div className="text-admin-blue ml-2 font-medium">{`${t(`childrenEvaluationTypes.${childrenEvaluationType}`)} ${t(
                  'common.hasntStarted',
                )}`}</div>
              </>
            ),
          }[childrenStatus]}
        {!child?.active && (
          <>
            <Document />
            <div className="text-admin-blue ml-2 font-medium">{`${t(`childrenEvaluationTypes.${childrenEvaluationType}`)} ${t('common.archived')}`}</div>
          </>
        )}
      </div>
      {onClick && (
        <Button variant="secondary" onClick={onClick} className="!w-full mt-4" disabled={isNotReadyForParent}>
          {t('common.continue')}
        </Button>
      )}
    </div>
  );
};

export default ChildCard;
