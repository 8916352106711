import React from 'react';

import type { TooltipProps } from 'recharts';

import { calcTooltipPercentage } from 'utils/chart';

export const ChartTooltipHoc = (barWidth: number) =>
  function ChartTooltip(props: TooltipProps<number, React.Key>) {
    const { active, payload, coordinate } = props;
    if (active && payload && payload.length && coordinate) {
      const percentage = calcTooltipPercentage({ payload, coordinate }, barWidth);

      return (
        <div className="flex items-center justify-center w-10 h-6 rounded-lg border border-admin-blue bg-background translate-y-1">
          <div className="text-teacher-blue text-xs">{`${percentage?.toFixed(0)}%`}</div>
        </div>
      );
    }

    return null;
  };
