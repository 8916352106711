import React from 'react';

import { useTranslation } from 'react-i18next';

import { ScreenLayout } from 'components';
import { UnitFormStep } from 'components/Units';

export const NewUnitScreen: React.FC = () => {
  const { t } = useTranslation();

  const breadcrumb = [
    {
      step: '/units',
      title: t('unitScreen.title'),
    },
    {
      step: '/units/new',
      title: t('unitScreen.addNewUnit'),
    },
  ];

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <UnitFormStep breadcrumb={breadcrumb} />
    </ScreenLayout>
  );
};
