import React from 'react';

import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'hooks/redux';

interface PrivateRouteProps {
  component: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component }) => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);
  return isAuthenticated ? component : <Navigate to="/" />;
};

export default PrivateRoute;
