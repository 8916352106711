import React, { useEffect, useState } from 'react';

import { Modal, TextInput } from 'components';

export interface ModalPickerProps {
  title: string;
  label: string;
  data?: { name: string; slug: string }[];
  onChange: (slug: string) => void;
  selectedItem?: string;
  containerClassName?: string;
  disabled?: boolean;
}

const ModalPicker: React.FC<ModalPickerProps> = ({ label, title, selectedItem, data, onChange, containerClassName, disabled = false }) => {
  const [pickerValue, setPickerValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const selectedValue = data?.find((item) => item.slug === selectedItem)?.name || '';
    setPickerValue(selectedValue);
  }, [selectedItem, data]);
  return (
    <>
      <Modal visible={isModalOpen} title={title} onClose={() => setIsModalOpen(false)} fixHeight={true} className="max-w-[350px] md:max-w-full">
        {data && !!data.length
          ? data.map((item) => {
              const isSelected = selectedItem === item.slug;
              const selectedTextColor = isSelected ? 'text-white' : 'text-teacher-blue';
              const selectedBackgroundColor = isSelected ? 'bg-teacher-blue' : 'bg-transparent';
              return (
                <button
                  key={item.slug}
                  className={`text-teacher-bluehover:bg-background py-2 pl-2 text-left ${selectedBackgroundColor} ${selectedTextColor}`}
                  onClick={() => {
                    onChange(item.slug);
                    setIsModalOpen(false);
                  }}
                >
                  {item.name}
                </button>
              );
            })
          : null}
      </Modal>
      <TextInput
        disabled={disabled}
        label={label}
        containerClassName={`mb-4 ${containerClassName || ''}`}
        onClick={() => setIsModalOpen(!disabled)}
        value={pickerValue}
      />
    </>
  );
};

export default ModalPicker;
