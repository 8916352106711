import type { TooltipProps } from 'recharts';

export const calcTooltipPercentage = (props: Required<Pick<TooltipProps<number, React.Key>, 'payload' | 'coordinate'>>, barWidth: number) => {
  const { payload, coordinate } = props;
  const rightSides: number[] = [];

  let sum = 0;

  for (const { value } of payload) {
    sum += value || 0;
    rightSides.push((sum * barWidth) / 100);
  }

  const cursorX = coordinate?.x || 0;

  const rightIndex = rightSides.findIndex((right) => right >= cursorX);

  return payload[rightIndex]?.value || 0;
};
