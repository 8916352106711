import { gql } from '@apollo/client';

export const CREATE_UNIT = gql`
  mutation createUnit($payload: CreateUnitDto!) {
    createUnit(payload: $payload) {
      id
      slug
    }
  }
`;

export const UPDATE_UNIT = gql`
  mutation updateUnit($id: Int!, $payload: UpdateUnitDto!) {
    updateUnit(id: $id, payload: $payload) {
      id
    }
  }
`;

export const DELETE_UNIT = gql`
  mutation deleteUnit($id: Int!) {
    deleteUnit(id: $id) {
      action
      message
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation addGroup($id: Int!, $payload: AddGroupDto!) {
    addGroup(id: $id, payload: $payload) {
      id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation editGroup($id: Int!, $payload: EditGroupPayloadDto!) {
    editGroup(id: $id, payload: $payload) {
      id
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup($groupId: Int!, $id: Int!) {
    deleteGroup(groupId: $groupId, id: $id) {
      action
      message
    }
  }
`;

export const CREATE_USER = gql`
  mutation addUser($id: Int, $payload: AddUserDto!) {
    addUser(id: $id, payload: $payload) {
      role
      slug
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation editUser($id: Int!, $payload: EditUserPayloadDto!) {
    editUser(id: $id, payload: $payload) {
      role
    }
  }
`;

export const REMOVE_USER = gql`
  mutation archiveUser($id: Int!) {
    archiveUser(id: $id) {
      role
    }
  }
`;

export const SEND_INVITATION = gql`
  mutation resendEmailByUserId($id: Int!) {
    resendEmailByUserId(id: $id) {
      message
    }
  }
`;

export const CREATE_PARENT = gql`
  mutation createParent($payload: CreateParentDto!) {
    createParent(payload: $payload) {
      id
      slug
    }
  }
`;

export const UPDATE_PARENT = gql`
  mutation editParent($id: Int!, $payload: EditParentDto!) {
    editParent(id: $id, payload: $payload) {
      id
    }
  }
`;

export const REMOVE_PARENT = gql`
  mutation archiveParent($id: Int!) {
    archiveParent(id: $id) {
      id
    }
  }
`;

export const REMOVE_CHILD = gql`
  mutation deleteChild($id: Int!) {
    deleteChild(id: $id) {
      id
    }
  }
`;

export const UPDATE_CHILD = gql`
  mutation editChild($id: Int!, $payload: EditChildPayloadDto!) {
    editChild(id: $id, payload: $payload) {
      id
    }
  }
`;

export const ADD_ANSWER = gql`
  mutation addAnswer($id: Int!, $payload: AddAnswersPayloadDto!) {
    addAnswer(id: $id, payload: $payload) {
      message
    }
  }
`;

export const CREATE_CONSULTATION = gql`
  mutation createConsultation($consultation: CreateConsultationDto!) {
    createConsultation(createConsultationDto: $consultation) {
      startsAt
    }
  }
`;

export const START_EVALUATION = gql`
  mutation createEvaluation($payload: CreateEvaluationPayloadDto!) {
    createEvaluation(payload: $payload) {
      id
    }
  }
`;

export const DELETE_CONSULTATION = gql`
  mutation deleteConsultation($consultation: DeleteConsultationDto!) {
    deleteConsultation(deleteConsultationDto: $consultation) {
      success
    }
  }
`;

export const ADD_DISCUSSION = gql`
  mutation addDiscussionAnswer($id: Int!, $payload: AddAnswersPayloadDto!) {
    addDiscussionAnswer(id: $id, payload: $payload) {
      message
    }
  }
`;

export const ADD_PARENT_CONSULTATION = gql`
  mutation addParentToConsultations($consultation: AddParentToConsultationsDto!) {
    addParentToConsultations(addToParentConsultationsDto: $consultation) {
      success
    }
  }
`;

export const ADD_RECAP = gql`
  mutation addRecapAnswers($id: Int!, $payload: RecapEvaluationDto!) {
    addRecapAnswers(id: $id, payload: $payload) {
      message
    }
  }
`;

export const REMOVE_PARENT_FROM_CONSULTATION = gql`
  mutation removeParentFromConsultation($consultation: RemoveParentFromConsultationDto!) {
    removeParentFromConsultation(removeParentFromConsultationDto: $consultation) {
      success
    }
  }
`;

export const ACCEPT_GDPR = gql`
  mutation acceptGDPR {
    acceptGDPR {
      acceptedGDPR
    }
  }
`;

export const PARENT_SIGN_DISCUSSION_FORM = gql`
  mutation parentSignDiscussion($id: Int!, $sign: Boolean!) {
    parentSignDiscussion(id: $id, sign: $sign) {
      id
    }
  }
`;

export const PARENT_SIGN_RECAP_FORM = gql`
  mutation parentSignRecap($id: Int!, $sign: Boolean!) {
    parentSignRecap(id: $id, sign: $sign) {
      id
    }
  }
`;
