import { useCreateConsultation, useDeleteConsultation, useConsultationsByTeacher } from 'graphql-api';

export const useConsultations = (teacherId: number | null, childId?: number | undefined) => {
  const { loading, data, refetch } = useConsultationsByTeacher({
    notifyOnNetworkStatusChange: true,
    variables: {
      teacherID: teacherId,
      childID: childId,
      showOnlyVacant: false,
    },
    fetchPolicy: 'no-cache',
    skip: !teacherId,
  });

  const [deleteConsultation] = useDeleteConsultation({ onCompleted: refetch });

  const [createConsultation] = useCreateConsultation({
    onCompleted: refetch,
    onError: refetch,
  });

  return {
    loading,
    consultations: data?.consultation || [],
    deleteConsultation,
    createConsultation,
  };
};
