import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Loader } from 'components';
import { User, Group, Unit } from 'graphql-api';

interface Props {
  onChange: (key: keyof User, value: string | Date | Unit | Group[]) => void;
  onNext: () => void;
  loading: boolean;
  groups?: Group[];
}

const GroupPickerStep: FC<Props> = ({ onChange, loading, groups }) => {
  const { t } = useTranslation();
  return (
    <Loader isLoading={loading}>
      {groups && groups.length ? (
        groups.map((item) => (
          <button key={item.id} className="text-teacher-bluehover:bg-background py-2 pl-2 text-left" onClick={() => onChange('groups', [item])}>
            {item.name}
          </button>
        ))
      ) : (
        <div className="text-teacher-blue py-16 text-center px-2">{t('userScreen.noGroups')}</div>
      )}
    </Loader>
  );
};

export default GroupPickerStep;
