import React from 'react';

import { Modal, Button, DateSelector, TextInput } from 'components';
import { useTranslation } from 'react-i18next';

import { ModalProps } from 'components/Modal';
import { ReactComponent as BookAppointmentIcon } from 'assets/book-appointment.svg';
import { ReactComponent as CancelAppointmentIcon } from 'assets/cancel-appointment.svg';
import Colors from 'utils/colors';
import type { User } from 'graphql-api';

export interface BookingModalProps extends ModalProps {
  isBooked: boolean;
  parentEmail: string;
  extraEmail?: string;
  date: Date;
  teacher: Pick<User, 'firstName' | 'lastName'>;
  cancelAppointment: () => void;
  bookAppointment: () => void;
  setExtraEmail: (extraEmail: string) => void;
  shouldDisableBooking: boolean;
}

const BookingModal: React.FC<BookingModalProps> = (props) => {
  const {
    onClose,
    date,
    teacher,
    visible,
    cancelAppointment,
    fixHeight,
    parentEmail,
    extraEmail,
    isBooked,
    setExtraEmail,
    bookAppointment,
    shouldDisableBooking,
  } = props;
  const { t } = useTranslation();

  const cancelThis = () => {
    cancelAppointment();
    onClose && onClose();
  };

  const bookThis = () => {
    bookAppointment();
    onClose && onClose();
  };

  const modalTitle = isBooked ? t('parentCalendar.bookingModal.yourAppointment') : t('parentCalendar.bookingModal.title');

  const addedByTeacher = `${teacher.firstName} ${teacher.lastName}`;

  const changeExtraEmail: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setExtraEmail(event.target.value.trim());
  };

  const confirm = isBooked ? cancelThis : bookThis;

  const confirmTitle = isBooked ? t('parentCalendar.bookingModal.cancelAppointment') : t('parentCalendar.bookingModal.title');

  const confirmIcon = isBooked ? <CancelAppointmentIcon stroke={Colors.teacherBlue} /> : <BookAppointmentIcon stroke={Colors.teacherBlue} />;

  return (
    <Modal className="max-w-[350px] md:max-w-full" title={modalTitle} visible={visible} onClose={onClose} fixHeight={fixHeight}>
      <div className="flex flex-col gap-4">
        <div className="px-4 mt-3">
          <div className="text-teacher-blue">
            {t('parentCalendar.bookingModal.addedBy')} {addedByTeacher}
          </div>
          <div className="my-3">
            <DateSelector isDisabled date={date} />
          </div>
          <div className="text-teacher-blue">{t('parentCalendar.bookingModal.sendInvitationTo')}:</div>
          <div className="font-semibold text-teacher-blue">
            <div>{parentEmail}</div>
            {!!extraEmail && isBooked && <div>{extraEmail}</div>}
          </div>
        </div>
        {!isBooked && (
          <div>
            <div className="font-bold text-teacher-blue">{t('parentCalendar.bookingModal.addAnotherInvite')}</div>

            <div className="mt-2">
              <TextInput onChange={changeExtraEmail} value={extraEmail} />
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <Button
            labelClassName="!text-teacher-blue font-semibold"
            onClick={confirm}
            variant="tertiary"
            icon={confirmIcon}
            disabled={!isBooked && shouldDisableBooking}
          >
            {confirmTitle}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BookingModal;
