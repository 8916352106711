import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Loader, TextInput } from 'components';
import { Unit } from 'graphql-api';
import { normalizedString } from 'utils/helpers';
import { ReactComponent as Edit } from 'assets/edit-icon.svg';
import { ReactComponent as Plus } from 'assets/plus-icon.svg';
import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';

export interface UnitListStepProps {
  loading: boolean;
  data?: { units: Unit[] };
  onClick: (item?: Unit) => void;
}

export const UnitListStep: React.FC<UnitListStepProps> = ({ loading, data, onClick }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const isSupport = accessLevel === AccessLevel.Support;

  const units = data?.units.filter((item) => normalizedString(item.name).toLowerCase().includes(normalizedString(searchValue).toLowerCase()));
  return (
    <>
      <div className="text-teacher-blue text-xl md:text-3xl font-bold mb-4">{t('unitScreen.title')}</div>
      <div className="flex items-center mb-6 md:mb-12">
        <TextInput
          placeholder={t('common.search')}
          type="text"
          name="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          containerClassName="w-full mr-3 md:mr-6"
          isSearch
          className="py-2 md:py-3 !text-base"
          labelClassName="text-sm"
        />
        {accessLevel !== AccessLevel.UnitAdmin && (
          <Button className="p-2 md:p-3 w-auto" labelClassName="w-full flex justify-center whitespace-nowrap" disabled={isSupport} onClick={() => onClick()}>
            <Plus />
            <span className="hidden md:inline md:ml-2">{t('unitScreen.addNewUnit')}</span>
          </Button>
        )}
      </div>
      <Loader isLoading={loading}>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
          {units &&
            units.map((item) => (
              <div key={item.id} className="flex flex-col bg-white rounded-lg border-2 px-4 pt-4 pb-8 border-admin-blue">
                <div className="flex items-center justify-between mb-1">
                  <div className="text-teacher-blue font-bold">{item.name}</div>
                  <Edit className="cursor-pointer" onClick={() => onClick(item)} />
                </div>
                <div className="text-teacher-blue">{`${item.zipCode}, ${item.city}, ${item.addressLine}`}</div>
              </div>
            ))}
        </div>
      </Loader>
    </>
  );
};
