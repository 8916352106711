import React from 'react';

import type { ConsultingEvent } from 'graphql-api';

export interface AgendaTimeProps {
  label: string;
  event: ConsultingEvent;
}

const AgendaTime: React.FC<AgendaTimeProps> = ({ label, event }) => (
  <div
    className={`${
      event.parent ? 'bg-done text-white' : 'bg-table bg-opacity-50 text-teacher-blue'
    } rounded-l p-1 font-bold my-2 border-l border-t border-b border-teacher-blue`}
  >
    {label}
  </div>
);

export default AgendaTime;
