import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ChildCard, ErrorMessage, GroupCard, Loader, ModalPicker, ScreenLayout, ShowMore } from 'components';
import { useEcecLanding } from 'hooks/useEcecLanding';
import NavigationRoute from 'navigation/NavigationRoute';
import { getChildEvaluationStatus } from 'utils/getChildEvaluationStatus';
import { EvaluationFormStatus } from 'graphql-api';

export const EcecLandingScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { unitSlug } = useParams();
  const { isAdmin, children, units, selectedUnitSlug, setSelectedUnitSlug, groups, isParent, subtitle, isActive, accessLevel, onChildClick, loading } =
    useEcecLanding();

  const evaluationFormStatus = [EvaluationFormStatus.PARTIALLY_FILLED_TEACHER_SIGNED, EvaluationFormStatus.FILLED];

  useEffect(() => {
    setSelectedUnitSlug(unitSlug);
  }, [unitSlug, setSelectedUnitSlug]);

  useEffect(() => {
    if (selectedUnitSlug) {
      navigate(`${NavigationRoute.EcecPlan}/unit/${selectedUnitSlug}`);
    }
  }, [selectedUnitSlug, navigate]);

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <div className="text-teacher-blue text-xl md:text-5xl font-bold mb-4 md:mb-8">{t('ececLanding.title')}</div>
      <div className="text-teacher-blue text-xl font-bold mb-6 hidden md:block">{t('ececLanding.subtitle')}</div>
      <ShowMore text={t('ececLanding.description')} className="text-teacher-blue mb-8 whitespace-pre-line md:pr-12 text-xs md:text-base" />
      {isAdmin && (
        <ModalPicker
          data={units}
          title={t('unitScreen.chooseUnit')}
          label={t('userMaintenanceScreen.unit')}
          selectedItem={selectedUnitSlug}
          onChange={setSelectedUnitSlug}
          containerClassName="mr-4 w-64 w-full md:w-72"
        />
      )}
      {subtitle && <div className="text-teacher-blue text-xl md:text-5xl font-semibold mb-6 mt-4 md:mt-8">{subtitle}</div>}
      {!!groups.length && <ErrorMessage error={!isActive} errorText={t('unitScreen.inactiveSwitch')} />}
      <Loader isLoading={loading}>
        <div className="grid gap-4 grid-cols-1 xl:grid-cols-3">
          {!isParent &&
            groups.map((item) => (
              <GroupCard
                key={item.id}
                group={item}
                numberOfChildren={item.children.length}
                onClick={() => navigate(`${NavigationRoute.EcecPlan}/${item.slug}`)}
                continueDisabled={!isActive}
              />
            ))}
          {isParent &&
            children.map((child) => {
              const status = getChildEvaluationStatus(
                evaluationFormStatus.includes(child.evaluation?.evaluationFormStatus as EvaluationFormStatus) ? child.evaluation?.status : undefined,
              );
              return (
                child.active && (
                  <ChildCard
                    key={child.id}
                    child={child}
                    childrenStatus={status.childrenStatus}
                    childrenEvaluationType={status.childrenEvaluationType}
                    onClick={() => onChildClick(child)}
                    accessLevel={accessLevel}
                  />
                )
              );
            })}
        </div>
      </Loader>
    </ScreenLayout>
  );
};
