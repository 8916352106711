import React from 'react';

import { useTranslation } from 'react-i18next';

import { FormVersion } from 'graphql-api';

import ChartLegendColor from './ChartLegendColor';
import ChartLegendText from './ChartLegendText';
import { groupResultsChartColors, parentInvolvementChartColors } from './chartColors';

export interface ChartLegendProps {
  formVersion: FormVersion;
}

export const ChartLegend: React.FC<ChartLegendProps> = ({ formVersion }) => {
  const { t } = useTranslation();

  const texts =
    formVersion === FormVersion.VASU
      ? ['formOptions.5', 'formOptions.4', 'formOptions.3', 'formOptions.2', 'formOptions.1']
      : ['parentalImportanceFormOptions.3', 'parentalImportanceFormOptions.2', 'parentalImportanceFormOptions.1'];

  const colors = formVersion === FormVersion.VASU ? groupResultsChartColors : parentInvolvementChartColors;

  return (
    <div className="mt-5 md:mt-12">
      <div className="border-2 border-transparent flex justify-evenly md:my-1">
        {texts.map((text) => (
          <ChartLegendText key={text}>{t(text)}</ChartLegendText>
        ))}
      </div>
      <div className="rounded-full border-2 border-table border-opacity-50 flex justify-evenly overflow-hidden">
        {colors.map((color) => (
          <ChartLegendColor key={color} color={color} />
        ))}
      </div>
    </div>
  );
};
