import React from 'react';

import { useTranslation } from 'react-i18next';

import { TeacherCalendar, ModalPicker } from 'components';
import { useUnits, useUsersByRoleForCalendarOfTeacher } from 'graphql-api';
import { AccessLevel } from 'redux/authentication/enums';

export const SuperAdminCalendar: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useUnits();
  const { data: teacherData } = useUsersByRoleForCalendarOfTeacher(AccessLevel.Teacher);

  const [unitSlug, setUnitSlug] = React.useState<string>();
  const [teacherSlug, setTeacherSlug] = React.useState<string>();

  const teachers = React.useMemo(
    () =>
      teacherData?.users
        .filter(({ unit }) => unit?.slug === unitSlug)
        .map(({ firstName, lastName, slug, id }) => ({ name: `${firstName} ${lastName}`, slug, id })),
    [teacherData, unitSlug],
  );

  const teacher = React.useMemo(() => teachers?.find(({ slug }) => slug === teacherSlug), [teachers, teacherSlug]);

  const teacherId = teacher ? parseInt(teacher.id) : null;

  return (
    <React.Fragment>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-x-5">
        <ModalPicker
          title={t('unitScreen.chooseUnit')}
          label={t('userMaintenanceScreen.unit')}
          onChange={(slug) => setUnitSlug(slug)}
          data={data?.units}
          selectedItem={unitSlug}
        />

        <ModalPicker
          title={t('superAdminCalendar.chooseTeacher')}
          label={t('common.teacher')}
          onChange={(slug) => setTeacherSlug(slug)}
          data={teachers}
          selectedItem={teacherSlug}
        />
      </div>

      <TeacherCalendar teacherId={teacherId} />
    </React.Fragment>
  );
};
