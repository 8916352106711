import React, { FC } from 'react';

import { Loader } from 'components';
import { Child, Group, Unit } from 'graphql-api';

interface Props {
  onChange: (key: keyof Child, value: string | Date | Unit | Group) => void;
  onNext: () => void;
  loading: boolean;
  groups?: Group[];
}

const GroupPickerStep: FC<Props> = ({ onChange, loading, groups }) => {
  return (
    <Loader isLoading={loading}>
      {groups &&
        groups.map((item) => (
          <button key={item.id} className="text-teacher-bluehover:bg-background py-2 pl-2 text-left" onClick={() => onChange('group', item)}>
            {item.name}
          </button>
        ))}
    </Loader>
  );
};

export default GroupPickerStep;
