const endpoints = {
  login: '/auth/login',
  user: '/auth/profile',
  restorePassword: '/auth/password',
  forgotPassword: '/auth/forgot-password',
  validateHash: '/auth/validate',
  refreshToken: '/auth/refresh-token',
};

export default endpoints;
