import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Modal } from 'components';
import { Unit, useUnits, useGroupsByUnitSlug, Group, User } from 'graphql-api';

import UnitPickerStep from './UnitPickerStep';
import GroupPickerStep from './GroupPickerStep';

export interface UnitAndGroupModalProps {
  visible: boolean;
  openModal: (visible: boolean) => void;
  onSave: (user: User) => void;
  selectedGroups?: string[];
  selectedUnitId?: number;
  isChangeUnit?: boolean;
}

export type UnitAndGroupModalSteps = 0 | 1;

const UnitAndGroupModal: React.FC<UnitAndGroupModalProps> = ({ isChangeUnit, visible, openModal, onSave, selectedGroups, selectedUnitId }) => {
  const [user, setUser] = useState<User>();
  const [step, setStep] = useState<UnitAndGroupModalSteps>(0);
  const { t } = useTranslation();
  const { data, loading: unitsLoading } = useUnits({ fetchPolicy: 'no-cache' });
  const { data: groupData, loading: groupsLoading } = useGroupsByUnitSlug(user?.unit?.slug);

  useEffect(() => {
    if (!visible) {
      setStep(0);
      setUser(undefined);
    }
  }, [visible]);

  const onChange = (key: keyof User, value: string | Date | Unit | Group[]) => {
    const userObject = user || ({} as User);
    const changedObject = {
      ...userObject,
      [key]: value,
    };
    setUser(changedObject);
    if (key === 'groups') {
      onSave(changedObject as User);
      openModal(false);
    }
  };

  const onNext = () => {
    setStep((prevState) => (prevState + 1) as UnitAndGroupModalSteps);
  };

  const titles = [t('unitScreen.chooseUnit'), t('unitScreen.chooseGroup')];

  const filteredUnits = data?.units ? data.units.filter((item) => item.groups.length) : [];

  const isSelectedFilteredUnits = selectedUnitId
    ? filteredUnits.filter((item) => (!isChangeUnit ? item.id === selectedUnitId : item.id !== selectedUnitId))
    : filteredUnits;

  const firstUnit = isSelectedFilteredUnits[0];

  const shouldSkipUnitSelection = visible && !user?.unit && !!selectedUnitId && !isChangeUnit && step === 0 && !!firstUnit;

  React.useEffect(() => {
    if (shouldSkipUnitSelection) {
      onChange('unit', firstUnit);
      onNext();
    }
  }, [shouldSkipUnitSelection, firstUnit]);

  const filteredGroups =
    groupData && selectedGroups ? groupData.unitBySlug.groups.filter((item) => !selectedGroups.includes(item.id)) : groupData?.unitBySlug.groups;

  return (
    <Modal visible={visible} onClose={() => openModal(false)} title={titles[step]} fixHeight={!step} className="pt-6 pb-2 max-w-[350px] md:max-w-full">
      {
        {
          0: <UnitPickerStep filteredUnits={isSelectedFilteredUnits} onChange={onChange} onNext={onNext} loading={unitsLoading} />,
          1: <GroupPickerStep groups={filteredGroups} onChange={onChange} onNext={onNext} loading={groupsLoading} />,
        }[step]
      }
    </Modal>
  );
};

export default UnitAndGroupModal;
