import { TFunction } from 'i18next';

import { Question, Evaluation, FormVersion, AnalyticsQuestion, QuestionType } from 'graphql-api';
import { Choices } from 'components/ChoiceCircle';
import { AccessLevel } from 'redux/authentication/enums';

export const getDiscussionSelectedAnswers = (
  questionItem: Question,
  formValue: { [key: string]: string | number },
  isDiscussionOver: boolean,
  accessLevel?: AccessLevel,
) => {
  const isDiscussionOrRecap = !accessLevel;
  const discussionAnswer = isDiscussionOrRecap
    ? questionItem.answers.find((answerItem) => answerItem.type === 'discussion')?.answer || formValue[questionItem.id]
    : undefined;
  const selectedAnswer = questionItem.answers.find((answerItem) => answerItem.answer === formValue[questionItem.id]?.toString());
  const parentAnswer = questionItem.answers.find((item) => item.replier.role === AccessLevel.Parent);
  const teacherAnswer = questionItem.answers.find((item) => item.replier.role === AccessLevel.Teacher && item.type === 'default');
  const isOtherSelected =
    (selectedAnswer && selectedAnswer.answer !== parentAnswer?.answer && selectedAnswer.answer !== teacherAnswer?.answer) || !selectedAnswer;
  const bothAnswered = selectedAnswer
    ? selectedAnswer?.answer === parentAnswer?.answer && selectedAnswer?.answer === teacherAnswer?.answer
    : parentAnswer?.answer === teacherAnswer?.answer;
  const getType = (): Choices => {
    if (!isDiscussionOrRecap) {
      return accessLevel === AccessLevel.Parent ? Choices.Parent : Choices.Teacher;
    }
    if (isOtherSelected) {
      return Choices.Other;
    }
    if (bothAnswered) {
      return Choices.Both;
    }
    if (selectedAnswer?.answer === parentAnswer?.answer) {
      return Choices.ParentOther;
    }
    if (selectedAnswer?.answer === teacherAnswer?.answer) {
      return Choices.TeacherOther;
    }
    if (formValue[questionItem.id] && !selectedAnswer) {
      return Choices.Other;
    }
    return Choices.Other;
  };

  const filteredAnswers = accessLevel ? questionItem.answers.filter((answerItem) => answerItem.replier.role === accessLevel) : [];
  const filterAnswerArray = accessLevel ? filteredAnswers : questionItem.answers;
  const previouslySelectedAnswers = filterAnswerArray
    .filter((item) => item.type === 'default')
    .map((answerItem) => ({
      value: answerItem.answer.toString(),
      type: !bothAnswered ? (answerItem.replier.role as Choices) : Choices.Both,
    }));
  const selectedAnswers = [];
  if (isDiscussionOver || formValue[questionItem.id]) {
    const agreedAnswer = {
      value: isDiscussionOver ? discussionAnswer?.toString() || '' : formValue[questionItem.id].toString(),
      type: getType(),
    };
    if (agreedAnswer.value) {
      selectedAnswers.push(agreedAnswer);
    }
  }
  return accessLevel ? selectedAnswers : previouslySelectedAnswers.concat(selectedAnswers);
};

export const getQuestionGroup = (formGroup: Evaluation, id: number) =>
  formGroup.form.formGroups.find((item) => item.questions.find((questionItem) => id === questionItem.id));

export const getQuestion = (formGroup: Evaluation, id: number) => getQuestionGroup(formGroup, id)?.questions.find((questionItem) => questionItem.id === id);

export const getQuestionIds = (formGroup: Evaluation, hasText?: boolean) =>
  formGroup.form.formGroups
    .map((formItem) =>
      formItem.questions
        .filter((filteredQuestions) => (hasText ? true : filteredQuestions.questionType !== QuestionType.TEXT))
        .map((questionItem) => questionItem.id),
    )
    .flat();

export const getFormValueItems = (formGroup: Evaluation, isDiscussion?: boolean) => {
  const questionIds = getQuestionIds(formGroup);

  const getCurrentValue = (id: number) => {
    const answers = getQuestion(formGroup, id)?.answers;
    const discussionAnswer = answers?.find((item) => item.type === 'discussion');
    if (answers && !answers.length) {
      return undefined;
    }
    if (isDiscussion && answers && discussionAnswer) {
      return discussionAnswer.answer;
    }
    if (
      answers &&
      !answers.map((item) => item.answer.toString()).every((answerItem, index, answerArray) => answerArray.indexOf(answerItem) === index) &&
      !isDiscussion
    ) {
      return answers[0].answer;
    }
    return undefined;
  };

  return questionIds.reduce((acc, curr) => ({ ...acc, [curr]: getCurrentValue(curr) }), {});
};

export const getImportanceFormValueItems = (formGroup: Evaluation) => {
  const questionIds = getQuestionIds(formGroup);

  return questionIds.reduce((acc, id) => ({ ...acc, [id]: getQuestion(formGroup, id)?.isImportantToReach }), {});
};

export const getRecapFormValueItems = (formGroup: Evaluation) => {
  const questionIds = getQuestionIds(formGroup);

  return questionIds.reduce(
    (acc, id) => ({
      ...acc,
      [id]: getQuestion(formGroup, id)?.isImportantToReach ? getQuestion(formGroup, id)?.reached : undefined,
    }),
    {},
  );
};

const getReplierRoleForUser = (role: AccessLevel) => (AccessLevel.Parent === role ? AccessLevel.Parent : AccessLevel.Teacher);

export const getVASUFormValues = (formGroup: Evaluation, role: AccessLevel) => {
  const questionIds = getQuestionIds(formGroup, true);
  const replierRole = getReplierRoleForUser(role);

  return questionIds.reduce((acc, id) => {
    const question = getQuestion(formGroup, id);

    return {
      ...acc,
      [id]: !question?.answers.length ? undefined : question?.answers.find((answerItem) => answerItem.replier.role === replierRole)?.answer,
    };
  }, {});
};

export const createChartData = (questionItem: AnalyticsQuestion, t: TFunction, formVersion = FormVersion.VASU, hasCorrection?: boolean) => {
  const versionedText = formVersion === FormVersion.VASU ? 'formOptions' : 'parentalImportanceFormOptions';
  return questionItem.answers.values.map((answerItem) => ({
    name: t(`${versionedText}.${answerItem.value}`),
    value: hasCorrection ? answerItem.repliers + 1 : answerItem.repliers,
    replierValue: answerItem.value,
    isHighlighted: questionItem.answers.myAnswer === answerItem.value,
  }));
};

export const getAnswerId = (formGroup: Evaluation, id: number, role: AccessLevel, isDiscussion?: boolean) => {
  const questions = getQuestion(formGroup, id);
  const replierRole = getReplierRoleForUser(role);
  const selectedAnswer = questions?.answers.find((item) => (isDiscussion ? item.type === 'discussion' : item.replier.role === replierRole));
  return selectedAnswer?.id || null;
};

export const getMultipleChoiceAnswers = (
  formGroup: Evaluation,
  answers: {
    answer: string | number;
    questionId: number;
    id?: number | null;
    isImportantToReach?: boolean;
  }[],
) =>
  answers
    .filter((item) => getQuestion(formGroup, item.questionId)?.questionType !== QuestionType.TEXT && item.answer)
    .map((numberedItem) => ({
      ...numberedItem,
      answer: +numberedItem.answer,
    }));

export const getTextAnswers = (
  formGroup: Evaluation,
  answers: {
    answer: string | number;
    questionId: number;
    id?: number | null;
    isImportantToReach?: boolean;
  }[],
) => answers.filter((item) => getQuestion(formGroup, item.questionId)?.questionType === QuestionType.TEXT && item.answer);
