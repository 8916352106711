import React, { useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { store } from 'redux/store';
import { LoginScreen } from 'screens/LoginScreen';
import { FAQScreen } from 'screens/FAQScreen';
import { Analytics } from 'screens/AnalyticsScreen';
import setAxiosInterceptors from 'api/interceptors';
import { logOutUser, refreshToken } from 'redux/authentication/authentication.actions';
import { useAppSelector } from 'hooks/redux';
import { UnitListScreen } from 'screens/UnitListScreen';
import { EditUnitScreen } from 'screens/EditUnitScreen';
import { EditGroupScreen } from 'screens/EditGroupScreen';
import { NewUnitScreen } from 'screens/NewUnitScreen';
import { UserListScreen } from 'screens/UserListScreen';
import { EditUserScreen } from 'screens/EditUserScreen';
import { EditUnitChildScreen } from 'screens/EditUnitChildScreen';
import { EditUsersChildScreen } from 'screens/EditUsersChildScreen';
import { NewUserScreen } from 'screens/NewUserScreen';
import { ForgotPasswordScreen } from 'screens/ForgotPasswordScreen';
import { RestorePasswordScreen } from 'screens/RestorePasswordScreen';
import { EcecLandingScreen } from 'screens/EcecLandingScreen';
import { CalendarScreen } from 'screens/CalendarScreen';
import { EcecLandingGroupsScreen } from 'screens/EcecLandingGroupsScreen';
import { AccessLevel } from 'redux/authentication/enums';
import { EcecScreen } from 'screens/EcecScreen';
import { HomeScreen } from 'screens/HomeScreen';
import { useIdleLogout } from 'hooks/useIdleLogout';

import NavigationRoute from './NavigationRoute';
import PrivateRoute from './PrivateRoute';

const RootRouter: React.FC = () => {
  useIdleLogout();

  const { isAuthenticated, accessLevel } = useAppSelector(({ auth }) => auth);

  useEffect(() => {
    const unsubscribeFromChanges = setAxiosInterceptors(store, logOutUser, refreshToken);
    return () => {
      unsubscribeFromChanges();
    };
  }, []);

  const rootParams = accessLevel === AccessLevel.Parent || accessLevel === AccessLevel.Teacher ? NavigationRoute.Home : NavigationRoute.Units;
  return (
    <Router>
      <Routes>
        <Route path={NavigationRoute.Root} element={!isAuthenticated ? <LoginScreen /> : <Navigate to={rootParams} />} />
        <Route path={NavigationRoute.ForgotPassword} element={!isAuthenticated ? <ForgotPasswordScreen /> : <Navigate to={rootParams} />} />
        <Route path={NavigationRoute.RestorePassword} element={!isAuthenticated ? <RestorePasswordScreen /> : <Navigate to={rootParams} />} />
        <Route path={NavigationRoute.Home} element={<PrivateRoute component={<HomeScreen />} />} />
        <Route path={NavigationRoute.FAQ} element={<PrivateRoute component={<FAQScreen />} />} />
        <Route path={NavigationRoute.Units} element={<PrivateRoute component={<UnitListScreen />} />} />
        <Route path={NavigationRoute.UnitsWithUnitSlug} element={<PrivateRoute component={<EditUnitScreen />} />} />
        <Route path={NavigationRoute.UnitsWithGroupSlug} element={<PrivateRoute component={<EditGroupScreen />} />} />
        <Route path={NavigationRoute.UnitsWithChildSlug} element={<PrivateRoute component={<EditUnitChildScreen />} />} />
        <Route path={NavigationRoute.AddUnits} element={<PrivateRoute component={<NewUnitScreen />} />} />
        <Route path={NavigationRoute.Users} element={<PrivateRoute component={<UserListScreen />} />} />
        <Route path={NavigationRoute.UsersWithUserSlug} element={<PrivateRoute component={<EditUserScreen />} />} />
        <Route path={NavigationRoute.UsersWithChildSlug} element={<PrivateRoute component={<EditUsersChildScreen />} />} />
        <Route path={NavigationRoute.AddUser} element={<PrivateRoute component={<NewUserScreen />} />} />
        <Route path={NavigationRoute.Analytics} element={<PrivateRoute component={<Analytics />} />} />
        <Route path={NavigationRoute.EcecPlan} element={<PrivateRoute component={<EcecLandingScreen />} />} />
        <Route path={NavigationRoute.EcecPlanUnitSlug} element={<PrivateRoute component={<EcecLandingScreen />} />} />
        <Route path={NavigationRoute.EcecPlanForm} element={<PrivateRoute component={<EcecScreen />} />} />
        <Route path={NavigationRoute.EcecPlanGroups} element={<PrivateRoute component={<EcecLandingGroupsScreen />} />} />
        <Route path={NavigationRoute.Calendar} element={<PrivateRoute component={<CalendarScreen />} />} />
      </Routes>
    </Router>
  );
};

export default RootRouter;
