import React, { useState } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Loader, ScreenLayout } from 'components';
import { UnitFormStep } from 'components/Units';
import { Unit, useUnits, Group } from 'graphql-api';

export const EditUnitScreen: React.FC = () => {
  const { t } = useTranslation();
  const { unitSlug } = useParams();
  const navigate = useNavigate();
  const [unit, setUnit] = useState<Unit>();
  const { loading, refetch } = useUnits({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(unitData: { units: Unit[] }) {
      if (unitData.units) {
        const selectedUnit = unitData.units.find((item) => item.slug === unitSlug);
        setUnit(selectedUnit);
      }
    },
  });

  const breadcrumb = [
    {
      step: '/units',
      title: t('unitScreen.title'),
    },
    {
      step: `/units/${unitSlug}`,
      title: unit?.name || '',
    },
  ];

  const onGroupClick = (groupItem?: Group) => {
    navigate(`/units/${unitSlug}/${groupItem?.slug}`);
  };

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <Loader isLoading={loading}>
        <UnitFormStep unit={unit} breadcrumb={breadcrumb} onClick={onGroupClick} refetch={refetch} />
      </Loader>
    </ScreenLayout>
  );
};
