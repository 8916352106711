import React from 'react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import 'moment/locale/fi';
import 'moment/locale/sv';

import { store, persistor } from 'redux/store';
import RootRouter from 'navigation/RootRouter';
import { client } from 'graphql-api';
import GDPRWrapper from 'components/GDPRWrapper';

function App() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ToastContainer bodyClassName="bg-white text-teacher-blue font-medium text-base" toastClassName="bg-white" />
          <GDPRWrapper>
            <RootRouter />
          </GDPRWrapper>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
