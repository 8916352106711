import React, { FC, useMemo } from 'react';

import { ReactComponent as Circle } from 'assets/circle.svg';
import colors from 'utils/colors';

export enum Choices {
  Parent = 'Parent',
  Teacher = 'Teacher',
  Both = 'Both',
  Other = 'Other',
  ParentOther = 'ParentOther',
  TeacherOther = 'TeacherOther',
  NotSelected = 'NotSelected',
}

export interface ChoiceCircleProps {
  selected?: Choices;
  onClick?: () => void;
  dividerVisible?: boolean;
  shouldFitMore?: boolean;
}

const ChoiceCircle: FC<ChoiceCircleProps> = ({ selected, onClick, dividerVisible = true, shouldFitMore = false }) => {
  const containerBorder = useMemo(() => {
    switch (selected) {
      case Choices.Other:
      case Choices.ParentOther:
      case Choices.TeacherOther:
        return 'border-orange';
      case Choices.Both:
        return 'border-parent-green';
      default:
        return 'border-admin-blue';
    }
  }, [selected]);

  const contentColor = useMemo(() => {
    switch (selected) {
      case Choices.Other:
        return colors.orange;
      case Choices.NotSelected:
        return colors.transparent;
      case Choices.Parent:
      case Choices.ParentOther:
        return colors.parentGreen;
      case Choices.TeacherOther:
      case Choices.Both:
        return colors.teacherBlue;
      default:
        return colors.teacherBlue;
    }
  }, [selected]);

  return (
    <div className={`border-2 relative rounded-full w-6 h-6 ${containerBorder}`} onClick={onClick} role="button">
      <Circle fill={contentColor} width={20} height={20} />
      {dividerVisible && <div className={`absolute ${shouldFitMore ? 'left-8' : 'left-10'} w-3 md:left-12 md:w-7 h-0 top-[0.65rem] border border-table`} />}
    </div>
  );
};

export default ChoiceCircle;
