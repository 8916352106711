export const getRadioButtonGroupProps = (): JSX.IntrinsicElements['input'][] => [
  {
    name: 'answer1',
    id: 'answer1',
    value: 1,
  },
  {
    name: 'answer2',
    id: 'answer2',
    value: 2,
  },
  {
    name: 'answer3',
    id: 'answer3',
    value: 3,
  },
  {
    name: 'answer4',
    id: 'answer4',
    value: 4,
  },
  {
    name: 'answer5',
    id: 'answer5',
    value: 5,
  },
];

export const getImportanceRadioButtonGroupProps = (): JSX.IntrinsicElements['input'][] => [
  {
    name: 'answer1',
    id: 'answer1',
    value: 1,
  },
  {
    name: 'answer2',
    id: 'answer2',
    value: 2,
  },
  {
    name: 'answer3',
    id: 'answer3',
    value: 3,
  },
];

export const idleLogoutTimeoutMilliseconds = 1000 * 60 * 30;
export const idleLogoutTimeoutMillisecondsSuperAdmin = 1000 * 60 * 15;
