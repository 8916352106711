import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Child, useChildrenByGroupSlug } from 'graphql-api';
import NavigationRoute from 'navigation/NavigationRoute';
import { showToast, ToastType } from 'utils/toast';
import { AccessLevel } from 'redux/authentication/enums';
import { getFormRouteType } from 'utils/form';

import { useOpenEvaluation } from './useOpenEvaluation';
import { useAppSelector } from './redux';

export const useEcecLandingGroups = (groupSlug?: string) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId, accessLevel } = useAppSelector(({ auth }) => auth);
  const { data } = useChildrenByGroupSlug(groupSlug, { fetchPolicy: 'no-cache' });
  const children = data?.groupBySlug.children || [];
  const groupName = data?.groupBySlug.name;
  const isAdmin = accessLevel === AccessLevel.SuperAdmin || accessLevel === AccessLevel.UnitAdmin || accessLevel === AccessLevel.Support;

  const { startEvaluation, loading } = useOpenEvaluation();

  const onChildClick = (item: Child) => {
    if (item.evaluation?.id) {
      const formType = getFormRouteType(item.evaluation?.status);
      navigate(`${NavigationRoute.EcecPlan}/${formType}/${item.evaluation?.id}`);
    } else {
      if (!isAdmin) {
        startEvaluation({
          variables: {
            payload: {
              childId: item.id,
              teacherId: userId || 0,
            },
          },
        });
      } else {
        showToast({
          type: ToastType.ERROR,
          title: t('evaluationNotifications.adminVasuDisabled'),
        });
      }
    }
  };

  return {
    children: children || [],
    onChildClick,
    loading,
    groupName,
  };
};
