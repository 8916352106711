import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BreadCrumb, Button, Loader, TextInput, UnitModal, FormStepArchiveButton } from 'components';
import { BreadCrumbData } from 'components/BreadCrumb';
import { useCreateUser, User, useUpdateUser, useDeleteUser, Unit, useResendLink, useUnits } from 'graphql-api';
import { ReactComponent as Trash } from 'assets/trash-icon.svg';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left-icon.svg';
import { ReactComponent as Edit } from 'assets/edit-icon.svg';
import { ReactComponent as PlusCircle } from 'assets/plus-circle-icon.svg';
import { ReactComponent as List } from 'assets/list-icon.svg';
import { ReactComponent as Mail } from 'assets/mail-icon.svg';
import { showToast, ToastType } from 'utils/toast';
import { AccessLevel } from 'redux/authentication/enums';
import colors from 'utils/colors';
import { getUserUnitIds } from 'utils/users';
import { useAppSelector } from 'hooks/redux';

export interface UnitAdminStepProps {
  user?: User;
  breadcrumb: BreadCrumbData[];
  refetch?: () => void;
  role: AccessLevel;
}

export const UnitAdminStep: React.FC<UnitAdminStepProps> = ({ user, breadcrumb, refetch, role }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const isSupport = accessLevel === AccessLevel.Support;
  const [changedUser, setChangedUser] = useState<User>();
  const [isEditSwitch, setIsEditSwitch] = useState(false);
  const [isUnitModalOpen, setIsUnitModalOpen] = useState(false);
  const [shouldSaveUnits, setShouldSaveUnits] = useState(false);

  const { data } = useUnits();

  useEffect(() => {
    setChangedUser(user);
  }, [user]);

  const [createUser, { loading: createLoading }] = useCreateUser({
    onCompleted(addUserData) {
      if (addUserData.addUser.role) {
        refetch && refetch();
        navigate(`/users/${AccessLevel.UnitAdmin}/${addUserData.addUser.slug}`);
        showToast({
          title: t('userMaintenanceScreen.user_added'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError() {
      showToast({
        title: t('userMaintenanceScreen.emailExists'),
        type: ToastType.ERROR,
      });
    },
  });

  const [updateUser, { loading: updateLoading }] = useUpdateUser({
    onCompleted(editUserData) {
      if (editUserData.editUser.role) {
        refetch && refetch();
        showToast({
          title: t('userMaintenanceScreen.user_edited'),
          type: ToastType.SUCCESS,
        });
      }
    },
  });

  const [deleteUser] = useDeleteUser({
    onCompleted(archiveUserData) {
      if (archiveUserData.archiveUser.role) {
        navigate('/users');
        showToast({
          title: t('userMaintenanceScreen.user_deleted'),
          type: ToastType.SUCCESS,
        });
      }
    },
  });

  const removeUser = () => {
    if (user) {
      deleteUser({ variables: { id: +user.id } });
    }
  };

  const addUser = () => {
    const unitIds = getUserUnitIds(changedUser) || [];

    createUser({
      variables: {
        id: unitIds[0],
        payload: {
          firstName: changedUser?.firstName,
          lastName: changedUser?.lastName,
          email: changedUser?.email,
          unitIds: unitIds,
          role,
        },
      },
    });
  };

  const editUser = () => {
    updateUser({
      variables: {
        id: +(user as User).id,
        payload: {
          firstName: changedUser?.firstName,
          lastName: changedUser?.lastName,
          email: changedUser?.email,
          unitIds: getUserUnitIds(changedUser),
          role,
        },
      },
    });
  };

  const onChange = (key: keyof User, value: string | Unit[]) => {
    const changedObject = changedUser || ({} as User);
    setChangedUser({
      ...changedObject,
      [key]: value,
    });
  };

  const onEditList = () => {
    setIsEditSwitch((prevState) => !prevState);
  };

  const [resendEmailByUserId] = useResendLink({
    onCompleted(resendEmailByUserIdData) {
      if (resendEmailByUserIdData.resendEmailByUserId.message) {
        showToast({
          title: t('userMaintenanceScreen.email_sent'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError() {
      showToast({
        title: t('common.try_again_later'),
        type: ToastType.ERROR,
      });
    },
  });

  const onUnitSelect = (selectedUnit: Unit) => {
    onChange('ownedUnits', changedUser?.ownedUnits ? changedUser.ownedUnits.concat(selectedUnit) : [selectedUnit]);

    if (user?.id) {
      setShouldSaveUnits(true);
    }
  };

  const onRemoveUnit = (removeUnit: Unit) => {
    onChange('ownedUnits', changedUser?.ownedUnits ? changedUser?.ownedUnits?.filter((item) => item.id !== removeUnit.id) : []);

    if (user?.id) {
      setShouldSaveUnits(true);
    }
  };

  useEffect(() => {
    if (shouldSaveUnits) {
      updateUser({
        variables: {
          id: +(user as User).id,
          payload: {
            unitIds: getUserUnitIds(changedUser),
          },
        },
      });

      setShouldSaveUnits(false);
    }
  }, [shouldSaveUnits, updateUser, changedUser, user]);

  const disableSaveChanges = !changedUser?.firstName || !changedUser?.lastName || !changedUser?.email || !changedUser.ownedUnits;

  return (
    <>
      <div className="flex items-center justify-between relative mb-4">
        <ArrowLeft className="absolute md:-left-10 cursor-pointer" onClick={() => navigate(-1)} />
        <div className="text-teacher-blue text-xl md:text-3xl font-bold ml-10 md:ml-0">
          {!user ? t('userScreen.addNew.UnitAdmin') : `${user.firstName} ${user.lastName}`}
        </div>
        {user && !isSupport && <FormStepArchiveButton onConfirm={removeUser} label={t('userScreen.deleteUser')} />}
      </div>
      <BreadCrumb data={breadcrumb} className="mb-4 md:mb-8" />
      <div className="md:grid md:grid-cols-2 md:gap-24">
        <div className="flex flex-col mb-6 md:mb-0">
          <div className="text-teacher-blue text-xl font-bold mb-2">{t('userScreen.adminInfo')}</div>
          <TextInput
            label={t('userScreen.firstName')}
            value={changedUser?.firstName}
            onChange={(e) => onChange('firstName', e.target.value)}
            containerClassName="mb-4"
            disabled={isSupport}
          />
          <TextInput
            label={t('userScreen.lastName')}
            value={changedUser?.lastName}
            onChange={(e) => onChange('lastName', e.target.value)}
            containerClassName="mb-4"
            disabled={isSupport}
          />
          <TextInput
            label={t('userScreen.email')}
            value={changedUser?.email}
            onChange={(e) => onChange('email', e.target.value)}
            containerClassName="mb-2"
            disabled={isSupport}
          />
          {user && !isSupport && (
            <Button
              variant="tertiary"
              className="w-fit"
              labelClassName="w-full flex justify-center"
              onClick={() => resendEmailByUserId({ variables: { id: user ? +user?.id : undefined } })}
            >
              <Mail className="mr-2" />
              {t('userScreen.resendLink')}
            </Button>
          )}
          <div className="flex items-center justify-between mt-6 gap-6">
            <Button variant="secondary" onClick={() => navigate(-1)}>
              {t('common.cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={() => (user ? editUser() : addUser())}
              icon={<Loader isLoading={updateLoading || createLoading} isSmall className="mr-2" color="white" />}
              disabled={disableSaveChanges || isSupport}
            >
              {t('common.saveChanges')}
            </Button>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between mb-4">
            <div className="text-teacher-blue text-xl font-bold">{t('userScreen.adminUnits')}</div>
            {user && (
              <Button
                variant="tertiary"
                onClick={onEditList}
                labelClassName="font-bold flex items-center !text-teacher-blue"
                className="w-fit !p-0"
                disabled={isSupport}
              >
                {!isEditSwitch && <List className="mr-2" />}
                {!isEditSwitch ? t('userScreen.editList') : t('common.cancel')}
              </Button>
            )}
          </div>
          {!!changedUser?.ownedUnits &&
            changedUser?.ownedUnits.map((item) => (
              <div key={item.id} className="flex flex-col bg-white rounded-lg border-2 px-4 pt-4 pb-8 mb-4 border-admin-blue">
                <div className="flex items-center justify-between mb-1">
                  <div className="text-teacher-blue font-bold">{item.name}</div>
                  {isEditSwitch || !user ? (
                    <Trash className="cursor-pointer ml-2" stroke={colors.teacherBlue} onClick={() => onRemoveUnit(item)} />
                  ) : (
                    <Edit className="cursor-pointer" onClick={() => navigate(`/units/${item.slug}`)} />
                  )}
                </div>
                <div className="text-teacher-blue">{`${item.zipCode}, ${item.city}, ${item.addressLine}`}</div>
              </div>
            ))}
          <UnitModal
            units={data?.units}
            selectedUnits={changedUser?.ownedUnits}
            visible={isUnitModalOpen}
            openModal={setIsUnitModalOpen}
            onSelect={onUnitSelect}
          />
          {(isEditSwitch || !user) && (
            <div className="flex justify-center">
              <Button
                variant="tertiary"
                labelClassName="!text-teacher-blue w-full flex justify-center"
                className="w-fit"
                onClick={() => setIsUnitModalOpen(true)}
              >
                <PlusCircle className="mr-2" />
                {t('unitMaintenanceScreen.new')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
