import React, { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';
import { ReactComponent as Done } from 'assets/donegoal.svg';
import { ReactComponent as Warning } from 'assets/warning.svg';
import { ConsultationType, EvaluationStatus, FormRouteType, ParentFormStatusesDto, useParentFormsStatuses } from '../../graphql-api';

export interface SelectorItem {
  value: string;
  label: string;
}

export interface SelectorProps {
  data: SelectorItem[];
  onClick: (item: string) => void;
  selectedItem?: string;
  containerClassName?: string;
  evaluationId?: string;
  status?: EvaluationStatus;
  calendarStatusRefresh?: boolean;
  setCalendarStatusRefresh?: (status: boolean) => void;
  setParentTabStatus?: (data: ParentFormStatusesDto) => void;
}

const Selector: React.FC<SelectorProps> = ({
  data,
  selectedItem,
  onClick,
  containerClassName,
  evaluationId = undefined,
  status = undefined,
  calendarStatusRefresh = undefined,
  setCalendarStatusRefresh = undefined,
  setParentTabStatus = undefined,
}) => {
  const [parentFormStatuses, setParentFormStatuses] = useState<ParentFormStatusesDto>();
  const { loading, refetch } = useParentFormsStatuses(evaluationId ? parseInt(evaluationId) : null, {
    onCompleted(data) {
      setParentFormStatuses(data.findParentFormsStatuses);
      if (setParentTabStatus) {
        setParentTabStatus(data.findParentFormsStatuses);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (calendarStatusRefresh) {
      if (setCalendarStatusRefresh) {
        setCalendarStatusRefresh(false);
        refetch();
      }
    }
  }, [refetch, calendarStatusRefresh, setCalendarStatusRefresh]);

  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const backgroundColor = useMemo(() => {
    switch (accessLevel) {
      case AccessLevel.Teacher:
        return 'bg-teacher-blue';
      case AccessLevel.Parent:
        return 'bg-parent-green';
      default:
        return 'bg-admin-blue';
    }
  }, [accessLevel]);
  const textColor = useMemo(() => {
    switch (accessLevel) {
      case AccessLevel.Teacher:
        return 'text-teacher-blue';
      case AccessLevel.Parent:
        return 'text-parent-green';
      default:
        return 'text-admin-blue';
    }
  }, [accessLevel]);
  if (!data || !data.length) return null;

  if (status && status > EvaluationStatus.DISCUSSION_DONE) {
    data = data.filter((data) => data.value !== FormRouteType.parentalInvolvement && data.value !== FormRouteType.calendar);
  } else if (status && status > EvaluationStatus.VASU_DONE) {
    data = data.filter((data) => data.value !== FormRouteType.parentalInvolvement);
  }

  const tabStatuses = loading
    ? []
    : data.map((item) => {
        switch (item.value) {
          case FormRouteType.parentalInvolvement:
            return !!parentFormStatuses?.parentalInvolvementForm;
          case FormRouteType.evaluation:
            return !!parentFormStatuses?.evaluationForm;
          case FormRouteType.calendar:
            return !!(
              parentFormStatuses?.bookedAppointment &&
              status &&
              ((status === EvaluationStatus.VASU_DONE &&
                parentFormStatuses?.bookedAppointment.filter((consultation) => consultation.type === ConsultationType.DISCUSSION).length) ||
                (status === EvaluationStatus.DISCUSSION_DONE &&
                  parentFormStatuses?.bookedAppointment.filter((consultation) => consultation.type === ConsultationType.RECAP).length))
            );
          case FormRouteType.discussion:
            return !!parentFormStatuses?.discussionForm;
          case FormRouteType.recap:
            return !!parentFormStatuses?.recapForm;
          default:
            return false;
        }
      });

  const getTabStatusIcon = (isDone: boolean) => (isDone ? <Done className="ml-2" width="18" /> : <Warning className="ml-2" />);

  return (
    <div className={`flex items-center flex-wrap gap-y-2 gap-x-4 md:gap-x-8 mb-2 ${containerClassName || ''}`}>
      {!loading &&
        data.map((item, lineNumber) => {
          const isSelected = selectedItem === item.value;
          const selectedTextColor = isSelected ? 'text-white' : textColor;
          const selectedBackgroundColor = isSelected ? backgroundColor : 'bg-transparent';

          const isDisabledParentalInvolvementTab = parentFormStatuses && lineNumber === 1 && tabStatuses[0] === false;
          const isDisabledCalendarTab = parentFormStatuses && lineNumber === 2 && tabStatuses[1] === false;

          const isDisabledTab = isDisabledParentalInvolvementTab || isDisabledCalendarTab;

          const disabledButtonClass = isDisabledTab ? 'cursor-not-allowed text-gray-400' : '';

          return (
            <button
              className={`py-1 px-2 font-medium text-sm md:text-base rounded-md whitespace-nowrap ${selectedBackgroundColor} ${selectedTextColor} ${disabledButtonClass}`}
              key={item.value}
              onClick={() => onClick(item.value)}
              disabled={isDisabledTab}
            >
              <div className="flex">
                <div>{`${lineNumber + 1}. ${item.label} `}</div> {parentFormStatuses && <div>{getTabStatusIcon(tabStatuses[lineNumber])}</div>}
              </div>
            </button>
          );
        })}
    </div>
  );
};

export default Selector;
