import React from 'react';

import { useTranslation } from 'react-i18next';

import { ScreenLayout } from 'components';
import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';
import { ReactComponent as Home } from 'assets/home.svg';

export const HomeScreen: React.FC = () => {
  const { t } = useTranslation();
  const { accessLevel: role } = useAppSelector(({ auth }) => auth);

  const isTeacher = role === AccessLevel.Teacher;

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <div className="text-xl md:text-5xl text-teacher-blue font-bold mb-4 md:mb-10">{t('common.home')}</div>
      <div className="text-xs md:text-xl font-bold text-teacher-blue mb-3 md:mb-5">{t(`home.${role}.title`)}</div>
      <div className="text-xs md:text-base text-teacher-blue">{t(`home.${role}.p1`)}</div>
      {isTeacher && <div className="text-xs md:text-base text-teacher-blue">{t('home.Teacher.p2')}</div>}
      <div className="mt-3">
        <div className="md:flex md:items-start md:gap-8">
          <div className="md:flex-1">
            {isTeacher && <div className="text-xs md:text-sm font-bold text-teacher-blue">{t('home.Teacher.listTitle')}</div>}
            <ul className="list-disc text-teacher-blue pl-5 text-xs md:text-sm">
              <li>{t(`home.${role}.list.1`)}</li>
              <li>{t(`home.${role}.list.2`)}</li>
              {isTeacher && (
                <>
                  <li>{t('home.Teacher.list.3')}</li>
                  <li>{t('home.Teacher.list.4')}</li>
                  <li>{t('home.Teacher.list.5')}</li>
                </>
              )}
            </ul>
          </div>
          <Home className="md:order-first md:flex-1 w-full h-full mt-5 md:mt-0" />
        </div>
      </div>
    </ScreenLayout>
  );
};
