import React from 'react';

import type { View } from 'react-big-calendar';

export const useCalendarView = () => {
  const [view, setView] = React.useState<View>('month');

  const onViewChange = (newView: View) => {
    if (view !== newView) {
      setView(newView);
    }
  };

  return {
    view,
    onViewChange,
  };
};
