import React, { PropsWithChildren } from 'react';

export interface AnalyticsQuestionWrapper {
  title: string;
}

export const AnalyticsQuestionWrapper: React.FC<PropsWithChildren<AnalyticsQuestionWrapper>> = ({ title, children }) => {
  return (
    <>
      <div className="md:text-3xl text-teacher-blue font-bold mb-3 md:mb-6 mt-6 md:mt-12">{title}</div>
      <div className="grid gap-4 grid-cols-1 xl:grid-cols-3">{children}</div>
    </>
  );
};
