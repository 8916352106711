import i18n from 'i18next';
import { ApolloClient, InMemoryCache, createHttpLink, from, ApolloLink, Observable, Operation } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import Client from 'api/Client';
import { setAuthorizationToken } from 'api/axiosInstance';
import { LOGOUT_SUCCESS, REFRESH_TOKEN_SUCCESS } from 'redux/authentication/types';
import { persistor, store } from 'redux/store';
import { showToast, ToastType } from 'utils/toast';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const getNewToken = async (operation: Operation, onSuccess: () => void) => {
  try {
    const { auth } = store.getState();
    const response = await Client.refreshToken(auth.refreshToken);
    const { accessToken, refreshToken } = response;
    store.dispatch({
      type: REFRESH_TOKEN_SUCCESS,
      payload: { accessToken, refreshToken },
    });
    setAuthorizationToken(accessToken);
    const oldHeaders = operation.getContext().headers;
    operation.setContext({
      ...oldHeaders,
      Authorization: `Bearer ${accessToken}`,
    });
    onSuccess();
  } catch (err) {
    persistor.purge();
    setAuthorizationToken('');
    store.dispatch({ type: LOGOUT_SUCCESS });
  }
};

const logoutLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      forward(operation).subscribe({
        next: (response) => {
          const resolveNext = () => {
            observer.next(response);
            observer.complete();
          };
          if (response && response.errors) {
            const unauthorizedError = response.errors.find((errorItem) => errorItem.message === 'Unauthorized');
            if (unauthorizedError) {
              const onSuccess = () => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };
                forward(operation).subscribe(subscriber);
              };

              getNewToken(operation, onSuccess);
            } else {
              resolveNext();
            }
          } else {
            resolveNext();
          }
        },
      });
    }),
);

const authLink = setContext((_, { headers }) => {
  const { auth } = store.getState();
  return {
    headers: {
      ...headers,
      Authorization: auth.accessToken ? `Bearer ${auth.accessToken}` : '',
      'Accept-Language': i18n.language,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError || graphQLErrors?.length) {
    showToast({
      title: i18n.t('common.cantFullfillRequest'),
      type: ToastType.ERROR,
    });
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, logoutLink, authLink, httpLink]),
});

export default client;
