import React from 'react';

import type { ChartColor } from './chartColors';

export interface ChartLegendColorProps {
  color: ChartColor;
}

const ChartLegendColor: React.FC<ChartLegendColorProps> = ({ color }) => <div className={`h-8 flex-1 ${color}`} />;

export default ChartLegendColor;
