import { useMemo, useState } from 'react';

import { Evaluation, useAddDiscussionAnswer, CommentsAndGoals, FormVersion, QuestionType } from 'graphql-api';
import { useTranslation } from 'react-i18next';

import { getAnswerId, getFormValueItems, getImportanceFormValueItems, getMultipleChoiceAnswers, getTextAnswers } from 'utils/answers';
import { showToast, ToastType } from 'utils/toast';
import type { AccessLevel } from 'redux/authentication/enums';

export interface IUseDiscussion {
  formGroup: Evaluation | null;
  formValue: { [key: string]: string | number };
  setFormValue: React.Dispatch<React.SetStateAction<{ [key: string]: string | number }>>;
  setSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  skip: boolean;
  refetch: () => void;
  formVersion: FormVersion;
  supportValue: string | null;
  evaluationSupportValue: string | null;
  setEvaluationSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  participatedSupportValue: string | null;
  setParticipatedSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  accessLevel: AccessLevel;
}

const useDiscussion = (props: IUseDiscussion) => {
  const {
    formGroup,
    setFormValue,
    formValue,
    skip,
    refetch,
    accessLevel,
    formVersion,
    setSupportValue,
    supportValue,
    setEvaluationSupportValue,
    evaluationSupportValue,
    setParticipatedSupportValue,
    participatedSupportValue,
  } = props;
  const { t } = useTranslation();
  const [importanceValues, setImportanceValues] = useState<{ [key: string]: boolean }>({});
  const [additionalData, setAdditionalData] = useState<CommentsAndGoals>({
    comments: '',
    goals: '',
  });

  const setDiscussionOnLoad = () => {
    if (formGroup) {
      const formValueItems = getFormValueItems(formGroup, true);
      const importanceValueItems = getImportanceFormValueItems(formGroup);
      setFormValue(formValueItems);
      setSupportValue(formGroup.support?.discussion || null);
      setEvaluationSupportValue(formGroup.evaluationOfSupport?.discussion || null);
      setParticipatedSupportValue(formGroup.otherParticipated?.discussion || null);
      setImportanceValues(importanceValueItems);
      setAdditionalData({
        comments: formGroup.comment || '',
        goals: formGroup.goal || '',
      });
    }
  };

  const [addDiscussionAnswer, { loading: isDiscussionAnswerLoading }] = useAddDiscussionAnswer({
    onCompleted(addDiscussionAnswerData) {
      if (addDiscussionAnswerData.addDiscussionAnswer.message) {
        refetch();
        showToast({
          title: t('common.form_filled'),
          type: ToastType.SUCCESS,
        });
      }
    },
  });

  const sendDiscussion = (sign?: boolean) => {
    if (!formGroup) return null;

    const answers = Object.keys(formValue).map((key) => ({
      answer: formValue[key],
      questionId: +key,
      id: formGroup ? getAnswerId(formGroup, +key, accessLevel, true) : null,
      isImportantToReach: importanceValues[key],
    }));

    const multipleChoiceAnswers = getMultipleChoiceAnswers(formGroup, answers);
    const textAnswers = getTextAnswers(formGroup, answers);
    addDiscussionAnswer({
      variables: {
        id: formGroup?.id,
        payload: {
          comment: additionalData.comments,
          goal: additionalData.goals,
          multipleChoiceAnswers,
          textAnswers,
          sign,
          formType: formVersion,
          support: supportValue,
          evaluationOfSupport: evaluationSupportValue,
          otherParticipated: participatedSupportValue,
        },
      },
    });
  };

  const discussionDisabled = useMemo(() => {
    const filteredFormValue =
      formGroup && formGroup?.form.formGroups.map((item) => item.questions.filter((questionItem) => questionItem.questionType !== QuestionType.TEXT)).flat();
    return (
      !formGroup ||
      !filteredFormValue ||
      !filteredFormValue.every((value) => formValue[value.id]) ||
      !Object.keys(additionalData).every((key) => additionalData[key as keyof CommentsAndGoals])
    );
  }, [formGroup, formValue, additionalData]);

  return skip
    ? {
        setDiscussionOnLoad: undefined,
        sendDiscussion: undefined,
        discussionDisabled: undefined,
        setImportanceValues: undefined,
        importanceValues: undefined,
        setAdditionalData: undefined,
        additionalData: undefined,
        isDiscussionAnswerLoading,
      }
    : {
        setDiscussionOnLoad,
        sendDiscussion,
        discussionDisabled,
        setImportanceValues,
        importanceValues,
        setAdditionalData,
        additionalData,
        isDiscussionAnswerLoading,
      };
};

export default useDiscussion;
