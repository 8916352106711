import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import fi from './translations/fi.json';
import sv from './translations/sv.json';

export interface Translation {
  en: string;
  fi: string;
  sv: string;
}

const resources = {
  en: { translation: en },
  fi: { translation: fi },
  sv: { translation: sv },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
