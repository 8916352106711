import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ChildCard, Loader, ScreenLayout } from 'components';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left-icon.svg';
import { useEcecLandingGroups } from 'hooks/useEcecLandingGroups';
import { getChildEvaluationStatus } from 'utils/getChildEvaluationStatus';

export const EcecLandingGroupsScreen: React.FC = () => {
  const navigate = useNavigate();
  const { groupSlug } = useParams();
  const { groupName, loading, children, onChildClick } = useEcecLandingGroups(groupSlug);
  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <div className="relative">
        <ArrowLeft className="absolute md:-left-10 cursor-pointer" onClick={() => navigate(-1)} />
        {groupName && <div className="text-teacher-blue text-xl md:text-3xl font-bold mb-6 md:mb-8 ml-10 md:ml-0">{groupName}</div>}
        <Loader isLoading={loading}>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
            {children.map((child) => {
              const status = getChildEvaluationStatus(child.evaluation?.status);
              return (
                child.active && (
                  <ChildCard
                    key={child.id}
                    child={child}
                    childrenStatus={status.childrenStatus}
                    childrenEvaluationType={status.childrenEvaluationType}
                    onClick={() => onChildClick(child)}
                  />
                )
              );
            })}
          </div>
        </Loader>
      </div>
    </ScreenLayout>
  );
};
