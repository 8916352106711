import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { useTranslation } from 'react-i18next';

import { ScreenLayout, TeacherCalendar, ParentCalendar, SuperAdminCalendar, UnitAdminCalendar, Switch, ModalPicker } from 'components';
import { AccessLevel } from 'redux/authentication/enums';
import { useUsersByRoleForUnitFormStep } from 'graphql-api';

export const CalendarScreen: React.FC = () => {
  const { t } = useTranslation();
  const { accessLevel, userId, email } = useAppSelector(({ auth }) => auth);
  const [forcedParent, setForcedParent] = useState(false);
  const [selectedParent, setSelectedParent] = useState<string>();
  const isSupport = accessLevel === AccessLevel.Support;

  const { data } = useUsersByRoleForUnitFormStep(AccessLevel.Parent, { fetchPolicy: 'no-cache' });

  const parentsData = data?.users.map((user) => ({
    slug: user.id,
    name: `${user.firstName} ${user.lastName}`,
  }));

  useEffect(() => {
    if (isSupport && !forcedParent) {
      setSelectedParent(undefined);
    }
  }, [forcedParent, isSupport]);

  const parentId = selectedParent ? parseInt(selectedParent) : userId;

  const calendars: Record<AccessLevel, React.ReactNode> = {
    [AccessLevel.Parent]: <ParentCalendar parentEmail={email} parentId={parentId} />,
    [AccessLevel.Teacher]: <TeacherCalendar teacherId={userId} description={t('teacherCalendar.description')} />,
    [AccessLevel.SuperAdmin]: <SuperAdminCalendar />,
    [AccessLevel.UnitAdmin]: <UnitAdminCalendar userId={userId} />,
    [AccessLevel.Support]: forcedParent ? <ParentCalendar parentEmail={email} parentId={parentId} /> : <SuperAdminCalendar />,
  };

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <div className="text-teacher-blue text-xl md:text-3xl font-bold mb-4">{t('newMenu.calendar')}</div>
      {isSupport && (
        <div>
          <div className="mb-3">
            <Switch
              onChange={() => {
                setForcedParent(!forcedParent);
              }}
              checked={forcedParent}
              title={t(`common.parent`)}
            />
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-x-5">
            {forcedParent && (
              <ModalPicker
                title={t('superAdminCalendar.chooseParent')}
                label={t('common.parent')}
                onChange={(user) => setSelectedParent(user)}
                data={parentsData}
                selectedItem={selectedParent}
              />
            )}
          </div>
        </div>
      )}
      {calendars[accessLevel]}
    </ScreenLayout>
  );
};
