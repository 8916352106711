import React from 'react';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Modal, Button, DateSelector } from 'components';
import { ModalProps } from 'components/Modal';

export interface NewTimeModalProps extends ModalProps {
  saveTime: (time: string) => void;
  date: Date;
  reservedDates: string[];
}

const NewTimeModal: React.FC<NewTimeModalProps> = (props) => {
  const { visible, saveTime, date: selectedDate, reservedDates, onClose, fixHeight } = props;
  const { t } = useTranslation();

  const [time, setTime] = React.useState<Date>(selectedDate);

  React.useLayoutEffect(() => {
    const defaultDate = new Date(selectedDate);
    defaultDate.setMinutes(0);
    defaultDate.setSeconds(0);
    setTime(defaultDate);
  }, [selectedDate]);

  const hasNoConflictWithReservedDates = (newAppointmentDate: string) => {
    const newMoment = moment(newAppointmentDate);

    for (const start of reservedDates) {
      const startMoment = moment(start);
      const endMoment = moment(start).add(45, 'minute');
      const newMomentEnd = moment(newMoment).add(45, 'minute');

      const hasConflict = [newMoment, newMomentEnd].some((m) => m.isBetween(startMoment, endMoment, null, '[)'));

      if (hasConflict) {
        return false;
      }
    }

    return true;
  };

  const years = selectedDate.getFullYear();
  const months = selectedDate.getMonth();
  const days = selectedDate.getDate();

  const addDate: React.MouseEventHandler = () => {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const newAppointmentDate = moment({
      years,
      months,
      days,
      hours,
      minutes,
      seconds: 0,
    }).toISOString();

    if (hasNoConflictWithReservedDates(newAppointmentDate)) {
      saveTime(newAppointmentDate);
    }
  };

  return (
    <Modal className="max-w-[350px] md:max-w-full" title={t('calendar.newDateTitle')} visible={visible} onClose={onClose} fixHeight={fixHeight}>
      <div className="py-8">
        <DateSelector date={time} onChange={(newDate) => setTime(newDate)} />
      </div>

      <div className="flex justify-end">
        <Button onClick={addDate} variant="tertiary" labelClassName="font-semibold">
          {t('calendar.addDate')}
        </Button>
      </div>
    </Modal>
  );
};

export default NewTimeModal;
