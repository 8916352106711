import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Loader, ScreenLayout } from 'components';
import { ChildFormStep } from 'components/Units';
import { Child, useChildBySlug } from 'graphql-api';
import { useUnitName } from 'hooks/useUnitName';
import { useGroupName } from 'hooks/useGroupName';

export const EditUnitChildScreen: React.FC = () => {
  const { t } = useTranslation();
  const { unitSlug, groupSlug, childSlug } = useParams();
  const [child, setChild] = useState<Child>();
  const { loading, refetch } = useChildBySlug(childSlug, {
    onCompleted(data) {
      if (data.childBySlug) {
        setChild(data.childBySlug);
      }
    },
  });

  const { isLoadingUnit, unitName } = useUnitName(unitSlug);
  const { isLoadingGroup, groupName } = useGroupName(groupSlug);

  const breadcrumb = [
    {
      step: '/units',
      title: t('unitScreen.title'),
    },
    {
      step: `/units/${unitSlug}`,
      title: unitName,
    },
    {
      step: `/units/${unitSlug}/${groupSlug}`,
      title: groupName,
    },
    {
      step: `/units/${unitSlug}/${groupSlug}/${childSlug}`,
      title: `${child?.firstName} ${child?.lastName}`,
    },
  ];

  const isLoading = loading || isLoadingUnit || isLoadingGroup;

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <Loader isLoading={isLoading}>
        <ChildFormStep child={child} breadcrumb={breadcrumb} refetch={refetch} />
      </Loader>
    </ScreenLayout>
  );
};
