import React from 'react';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const useYear = () => {
  const { t } = useTranslation();
  const middle = moment().startOf('year').add(6, 'months');
  const isFirstSemester = moment().isAfter(middle);

  const currentIntervalStart = isFirstSemester ? moment().startOf('year') : moment().startOf('year').subtract(1, 'year');

  const intervals = Array.from({ length: 6 }, (_, index) => ({
    startYear: currentIntervalStart.clone().subtract(index, 'year'),
    endYear: currentIntervalStart.clone().subtract(index, 'year').add(1, 'year'),
  }));

  const yearPickerData = intervals.map(({ startYear, endYear }, index) => ({
    slug: startYear.format('yyyy'),
    name: index === 0 ? t('yearPicker.currentYear') : `${startYear.format('yyyy')} - ${endYear.format('yyyy')}`,
    evaluationsFrom: startYear.add(6, 'months').toISOString(),
    evaluationsUntil: endYear.add(6, 'months').toISOString(),
  }));

  const [yearSlug, setYearSlug] = React.useState(yearPickerData[0].slug);

  const selectedInterval = yearPickerData.find(({ slug }) => slug === yearSlug);

  return {
    setYearSlug,
    yearSlug,
    yearPickerData,
    selectedInterval,
  };
};
