import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BreadCrumb, Button, ChildCard, TextInput, FormStepArchiveButton } from 'components';
import { BreadCrumbData } from 'components/BreadCrumb';
import { useUpdateGroup, useDeleteGroup, GroupForEditGroupScreen } from 'graphql-api';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left-icon.svg';
import { showToast, ToastType } from 'utils/toast';
import { getChildEvaluationStatus } from 'utils/getChildEvaluationStatus';
import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';

export interface GroupFormStepProps {
  group?: GroupForEditGroupScreen;
  unitSlug: string;
  breadcrumb: BreadCrumbData[];
}

export const GroupFormStep: React.FC<GroupFormStepProps> = ({ group, unitSlug, breadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const isSupport = accessLevel === AccessLevel.Support;

  const [changedGroup, setChangedGroup] = useState<GroupForEditGroupScreen>();

  const [editGroup] = useUpdateGroup({
    onCompleted(editGroupData) {
      if (editGroupData.editGroup.id) {
        showToast({
          title: t('groupMaintenanceScreen.group_edited'),
          type: ToastType.SUCCESS,
        });
      }
    },
  });

  const [deleteGroup] = useDeleteGroup({
    onCompleted(deleteGroupData) {
      if (deleteGroupData.deleteGroup.action) {
        navigate(-1);
        showToast({
          title: t('groupMaintenanceScreen.group_deleted'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError() {
      showToast({
        title: t('groupMaintenanceScreen.delete_error'),
        type: ToastType.ERROR,
      });
    },
  });

  const removeGroup = () => {
    deleteGroup({ variables: { groupId: group ? +group.id : '', id: group?.unitId } });
  };

  const updateGroup = () => {
    editGroup({
      variables: {
        id: group ? +group?.id : '',
        payload: {
          name: changedGroup?.name,
        },
      },
    });
  };

  useEffect(() => {
    setChangedGroup(group);
  }, [group]);

  const onChange = (key: keyof GroupForEditGroupScreen, value: string) => {
    const changedObject = changedGroup || ({} as GroupForEditGroupScreen);
    setChangedGroup({
      ...changedObject,
      [key]: value,
    });
  };

  const childCards = group
    ? group?.children.map((child) => {
        const status = getChildEvaluationStatus(child.evaluation?.status);

        return (
          <ChildCard
            key={child.id}
            child={child}
            childrenStatus={status.childrenStatus}
            childrenEvaluationType={status.childrenEvaluationType}
            onEditClick={() => navigate(`/units/${unitSlug}/${group.slug}/${child.slug}`)}
          />
        );
      })
    : [];

  return (
    <>
      <div className="flex items-center justify-between relative mb-4">
        <ArrowLeft className="absolute md:-left-10 cursor-pointer" onClick={() => navigate(-1)} />
        <div className="text-teacher-blue text-xl md:text-3xl font-bold ml-10 md:ml-0">{!group ? t('groupMaintenanceScreen.new') : group.name}</div>
        <FormStepArchiveButton onConfirm={removeGroup} label={t('groupMaintenanceScreen.delete')} disabled={isSupport} />
      </div>
      <BreadCrumb data={breadcrumb} className="mb-8" />
      <div className="flex flex-col md:grid md:grid-cols-2 md:gap-24">
        <div className="flex flex-col">
          <TextInput
            label={t('groupMaintenanceScreen.name')}
            value={changedGroup?.name}
            onChange={(e) => onChange('name', e.target.value)}
            containerClassName="mb-4"
            disabled={isSupport}
          />
          <div className="flex items-center justify-around mt-3 md:mt-6 mb-8 md:mb-16 gap-3 md:gap-6">
            <Button variant="secondary" onClick={() => navigate(-1)} className="!bg-white w-32 md:w-56">
              {t('common.cancel')}
            </Button>
            <Button className="w-32 md:w-56" variant="primary" onClick={updateGroup} disabled={isSupport}>
              {t('common.saveChanges')}
            </Button>
          </div>
        </div>
        <div className="flex flex-col order-first md:order-none">
          <div className="text-teacher-blue text-base font-semibold mb-3">{t('groupScreen.teachersInTheGroup').toUpperCase()}</div>
          {group?.educators?.map((educator) => (
            <div key={educator.id} className="text-teacher-blue font-bold ml-4 mb-2">{`${educator.firstName} ${educator.lastName}`}</div>
          ))}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-teacher-blue text-xl font-bold mb-6">{t('groupMaintenanceScreen.childrenInTheGroup')}</div>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">{childCards}</div>
      </div>
    </>
  );
};
