import React from 'react';

import { useTranslation } from 'react-i18next';
import type { View, SlotInfo } from 'react-big-calendar';
import BigCalendar from 'react-big-calendar-like-google';
import moment from 'moment';
import i18next from 'i18next';

import type { ConsultingEvent } from 'graphql-api';
import { eventEndAccessor, eventStartAccessor, formats } from 'utils/calendar';

import { useCalendarCustomComponents, useEventPropGetter } from './hooks';

import 'react-big-calendar-like-google/lib/css/react-big-calendar.css';
import './calendar.styles.css';

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

interface CalendarProps {
  consultations: ConsultingEvent[];
  view: View;
  onViewChange?: (newView: View) => void;
  onSelectSlot?: (newView: SlotInfo) => void;
  onSelectEvent?: (newView: ConsultingEvent) => void;
}

export const Calendar: React.FC<CalendarProps> = (props) => {
  const { consultations, view, onViewChange, onSelectSlot, onSelectEvent } = props;
  const { t } = useTranslation();

  const eventTitleAccessor = (event: ConsultingEvent) => {
    return event?.parent ? `${event?.parent?.firstName} ${event?.parent?.lastName}` : t('calendar.notBooked');
  };

  const eventPropGetter = useEventPropGetter(consultations, view);

  const components = useCalendarCustomComponents();

  const messages = {
    date: t('common.date'),
    week: t('calendar.week'),
    day: t('calendar.day'),
    month: t('calendar.month'),
    previous: t('common.back'),
    next: t('common.next'),
    today: t('calendar.today'),
    agenda: t('calendar.agenda'),
    showMore: (total: number) => `+${total} ${t('common.more')}`,
  };

  const preventRangeSelectionOnTimeViews = () => false;

  return (
    <BigCalendar
      onSelectEvent={onSelectEvent}
      scrollToTime={new Date()}
      onSelectSlot={onSelectSlot}
      events={consultations}
      startAccessor={eventStartAccessor}
      endAccessor={eventEndAccessor}
      titleAccessor={eventTitleAccessor}
      selectable
      onSelecting={preventRangeSelectionOnTimeViews}
      components={components}
      eventPropGetter={eventPropGetter}
      view={view}
      onView={onViewChange}
      formats={formats}
      culture={i18next.language}
      messages={messages}
    />
  );
};
