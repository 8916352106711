export default {
  teacherBlue: '#0D3470',
  'teacherBlue-0.3': 'rgba(13, 52, 112, 0.3)',
  adminBlue: '#6588BC',
  parentGreen: '#7FBD7F',
  chartColors: ['#0D3470', '#91DAF1', '#A84CE2', '#FFA9D7', '#F1E042'],
  parentalChartColors: ['#0D3470', '#F1E042', '#A84CE2'],
  white: '#fdfbff',
  done: '#3DB43B',
  orange: '#FFB561',
  transparent: 'rgba(0, 0, 0, 0)',
  ageGroup: '#FFC889',
};
