import { useState, useMemo } from 'react';

import { useGroupsByUnitSlug, useParentalAnalytics, useUnits } from 'graphql-api';

export interface IUseParentalInvolvement {
  evaluationsFrom: string;
  evaluationsUntil: string;
}

export const useParentalInvolvement = (interval?: IUseParentalInvolvement) => {
  const [selectedUnitSlug, setSelectedUnitSlug] = useState<string>();
  const [selectedGroupSlug, setSelectedGroupSlug] = useState<string>();
  const { data, loading: unitLoading } = useUnits();
  const { data: unitData, loading: groupLoading } = useGroupsByUnitSlug(selectedUnitSlug);

  const {
    data: analytics,
    error,
    loading: isLoadingAnalytics,
  } = useParentalAnalytics(selectedGroupSlug, selectedUnitSlug, interval?.evaluationsFrom, interval?.evaluationsUntil);

  const units = useMemo(
    () =>
      data?.units
        .filter((item) => !!item.groups.length)
        .map((filteredItem) => ({
          slug: filteredItem.slug,
          name: filteredItem.name,
          id: filteredItem.id,
        })),
    [data],
  );

  const groups = useMemo(
    () =>
      unitData?.unitBySlug.groups.map((item) => ({
        slug: item.slug,
        name: item.name,
      })),
    [unitData],
  );

  const setUnit = (unit: string) => {
    setSelectedUnitSlug(unit);
    setSelectedGroupSlug(undefined);
  };

  const selectedGroupTitle = useMemo(() => groups?.find((item) => item.slug === selectedGroupSlug)?.name, [groups, selectedGroupSlug]);

  return {
    analytics,
    analyticsError: error,
    isLoadingUnits: unitLoading || groupLoading,
    isLoadingAnalytics,
    selectedUnitSlug,
    selectedGroupSlug,
    setSelectedGroupSlug,
    units,
    groups,
    setUnit,
    selectedGroupTitle,
  };
};
