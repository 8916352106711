import { useState } from 'react';

import { Evaluation, useAddRecapAnswer } from 'graphql-api';
import { useTranslation } from 'react-i18next';

import { getFormValueItems, getRecapFormValueItems } from 'utils/answers';
import { showToast, ToastType } from 'utils/toast';

export interface IUseRecap {
  formGroup: Evaluation | null;
  setFormValue: React.Dispatch<React.SetStateAction<{ [key: string]: string | number }>>;
  setSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  skip: boolean;
  refetch: () => void;
  supportValue: string | null;
  evaluationSupportValue: string | null;
  setEvaluationSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
  participatedSupportValue: string | null;
  setParticipatedSupportValue: React.Dispatch<React.SetStateAction<string | null>>;
}

const useRecap = (props: IUseRecap) => {
  const {
    formGroup,
    setFormValue,
    skip,
    refetch,
    setSupportValue,
    supportValue,
    setEvaluationSupportValue,
    evaluationSupportValue,
    setParticipatedSupportValue,
    participatedSupportValue,
  } = props;
  const { t } = useTranslation();
  const [recapFormValue, setRecapFormValue] = useState<{ [key: string]: boolean }>({});
  const [commentFormValue, setCommentFormValue] = useState<{ [key: string]: string }>({});

  const setRecapOnLoad = () => {
    if (formGroup) {
      const formValueItems = getFormValueItems(formGroup);
      setSupportValue(formGroup.support?.recap || null);
      setEvaluationSupportValue(formGroup.evaluationOfSupport?.recap || null);
      setParticipatedSupportValue(formGroup.otherParticipated?.recap || null);
      const recapFormValueItems = getRecapFormValueItems(formGroup);
      const commentFormValueItems = formGroup.form.formGroups.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id.toString()]: curr.changes || '',
        }),
        {},
      );
      setFormValue(formValueItems);
      setRecapFormValue(recapFormValueItems);
      setCommentFormValue(commentFormValueItems);
    }
  };

  const [addRecapAnswers, { loading: isRecapAnswerLoading }] = useAddRecapAnswer({
    onCompleted(addRecapAnswersData) {
      if (addRecapAnswersData.addRecapAnswers.message) {
        refetch();
        showToast({
          title: t('common.form_filled'),
          type: ToastType.SUCCESS,
        });
      }
    },
  });

  const sendRecap = (sign?: boolean) => {
    if (!formGroup) return null;

    const answers = Object.keys(recapFormValue).map((key) => ({
      reached: !!recapFormValue[key],
      questionId: +key,
    }));
    const formGroupAnswers = Object.keys(commentFormValue).map((key) => ({
      changes: commentFormValue[key],
      formGroupId: +key,
    }));

    addRecapAnswers({
      variables: {
        id: formGroup?.id,
        payload: {
          answers,
          evaluationId: formGroup?.id,
          formGroupAnswers,
          sign,
          support: supportValue,
          evaluationOfSupport: evaluationSupportValue,
          otherParticipated: participatedSupportValue,
        },
      },
    });
  };

  return skip
    ? {
        sendRecap: undefined,
        setRecapOnLoad: undefined,
        setRecapFormValue: undefined,
        setCommentFormValue: undefined,
        recapFormValue: undefined,
        commentFormValue: undefined,
        isRecapAnswerLoading,
      }
    : {
        sendRecap,
        setRecapOnLoad,
        setRecapFormValue,
        setCommentFormValue,
        recapFormValue,
        commentFormValue,
        isRecapAnswerLoading,
      };
};

export default useRecap;
