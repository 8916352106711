import React from 'react';

import { useTranslation } from 'react-i18next';

import ChoiceGroup from 'components/ChoiceGroup';
import { getDiscussionSelectedAnswers } from 'utils/answers';
import { FormVersion, Question } from 'graphql-api';
import { Translation } from 'locales';
import { AccessLevel } from 'redux/authentication/enums';

export interface ImportanceFormItemProps {
  formType: FormVersion;
  questionItem: Question;
  versionedRadioGroup: JSX.IntrinsicElements['input'][];
  formValue: { [key: string]: string | number };
  isError?: boolean;
  recapFormValue?: { [key: string]: boolean };
  onChange?: (key: string | number, value: number | string) => void;
  onRecapChange?: (key: string | number, value?: boolean) => void;
  lang: keyof Translation;
  disabledForm: boolean;
}

const ImportanceFormItem: React.FC<ImportanceFormItemProps> = (props) => {
  const { questionItem, formType, versionedRadioGroup, onChange, onRecapChange, formValue, recapFormValue, isError = false, lang, disabledForm } = props;
  const { t } = useTranslation();

  const question = questionItem.question[lang];
  return (
    <ChoiceGroup
      key={`${question}-${questionItem.id}`}
      containerClassName="mb-8"
      options={versionedRadioGroup.map((item, index) => ({
        value: item.value as number | string,
        id: `${question}-${index}`,
        name: `${question}-${index}`,
        label: t(`parentalImportanceFormOptions.${item.value}`),
      }))}
      onClick={(value: string) => {
        if (!disabledForm) {
          onChange && onChange(questionItem.id, +value);
        }
      }}
      selected={getDiscussionSelectedAnswers(questionItem, formValue, false, AccessLevel.Parent)}
      label={question}
      isError={isError && !formValue[questionItem.id]}
      onAgreed={
        formType === FormVersion.RECAP && !disabledForm
          ? () => {
              onRecapChange && onRecapChange(questionItem.id, recapFormValue && !recapFormValue[questionItem.id]);
            }
          : undefined
      }
      isAgreed={recapFormValue && !!recapFormValue[questionItem.id]}
    />
  );
};

export default ImportanceFormItem;
