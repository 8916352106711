import { QueryHookOptions, useQuery } from '@apollo/client';

import { GET_FORM_BY_EVALUATION_ID, GET_PARENTAL_INVOLVEMENT_FORM } from './queries';
import { Evaluation, FormVersion } from './types';

export const useFormByEvalId = (id?: number, type?: FormVersion, options?: QueryHookOptions) =>
  useQuery<{ evaluation: Evaluation }>(GET_FORM_BY_EVALUATION_ID, { skip: !id || !type, variables: { id, type }, ...options });

export const useParentalFormByEvalId = (id?: number, type?: FormVersion, options?: QueryHookOptions) =>
  useQuery<{ evaluation: Evaluation }>(GET_PARENTAL_INVOLVEMENT_FORM, { skip: !id || !type, variables: { id, type }, ...options });
