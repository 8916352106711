import React from 'react';
import { useUnitBySlugForBreadcrumb } from 'graphql-api';

export const useUnitName = (unitSlug?: string) => {
  const [unitName, setUnitName] = React.useState('');

  const { loading: isLoadingUnit } = useUnitBySlugForBreadcrumb(unitSlug, {
    onCompleted(data) {
      if (data.unitBySlug) {
        setUnitName(data.unitBySlug.name);
      }
    },
  });

  return {
    isLoadingUnit,
    unitName,
  };
};
