import { AxiosError, AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { Store, Unsubscribe } from 'redux';

import { setAuthorizationToken, API_URL, axiosInstance } from './axiosInstance';
import endpoints from './endpoints';

const excludedRoutes = [endpoints.login, endpoints.refreshToken];

interface ExtendedRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

let tokenRefreshing = false;
let subscribers: ((accessToken: string) => void)[] = [];

export const onAccessTokenFetched = (store: Store): void => {
  tokenRefreshing = false;
  const { accessToken } = store.getState().auth;
  subscribers.map((callback) => callback(accessToken));
  subscribers = [];
};

const addSubscriber = (callback: (token: string) => void): void => {
  subscribers.push(callback);
};

const resetTokenAndReattemptRequest = async (
  error: AxiosError,
  axios: AxiosInstance,
  store: Store,
  logout: () => any,
  refreshToken: (refreshToken: string, onAccessTokenFetched: (store: Store) => void) => any,
): Promise<AxiosResponse | void | unknown> => {
  const originalRequest: ExtendedRequestConfig = error.config;
  const { response } = error;
  if (response && response.status && response.status === 401) {
    const { refreshToken: refreshTokenValue } = store.getState().auth;
    if (!refreshTokenValue || originalRequest.url === `${API_URL}${endpoints.refreshToken}` || originalRequest._retry) {
      store.dispatch(logout());
      setAuthorizationToken('');
      return Promise.reject(error);
    }

    originalRequest._retry = true;
    if (!tokenRefreshing) {
      tokenRefreshing = true;
      store.dispatch(refreshToken(refreshTokenValue, () => onAccessTokenFetched(store)));
    }

    const retryOrigReq = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        originalRequest.headers!.Authorization = `Bearer ${accessToken}`;
        return resolve(axios(originalRequest));
      });
    });
    return retryOrigReq;
  }
  return Promise.reject(error);
};

const setAxiosInterceptors = (
  store: Store,
  logout: () => any,
  refreshToken: (refreshToken: string, onAccessTokenFetched: (store: Store) => void) => any,
): Unsubscribe => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response } = error;
      if (response.status === 401 && !excludedRoutes.includes(response.config.url)) {
        return resetTokenAndReattemptRequest(error, axiosInstance, store, logout, refreshToken);
      } else if (response) {
        return Promise.reject(error);
      }
    },
  );

  return store.subscribe(() => {
    const {
      auth: { accessToken },
    } = store.getState();
    setAuthorizationToken(accessToken);
  });
};

export default setAxiosInterceptors;
