import { QueryHookOptions, useQuery } from '@apollo/client';

import { GET_CHILD_BY_SLUG } from './queries';
import { ChildById } from './types';

type Data = { childBySlug: ChildById };

type Vars = { slug?: string };

export const useChildBySlug = (slug?: string, options?: QueryHookOptions<Data, Vars>) =>
  useQuery<Data, Vars>(GET_CHILD_BY_SLUG, { skip: !slug, variables: { slug }, ...options });
