import type { User, Group, Unit } from 'graphql-api';
import { transformBirthDate } from 'utils/helpers';

export const getUserUnitIds = (user?: User): undefined | number[] => user?.ownedUnits?.map((item) => item.id);

export const getParentChildren = (parent?: User) =>
  parent?.children?.map((childItem) => ({
    id: childItem.id,
    firstName: childItem.firstName,
    lastName: childItem.lastName,
    dateOfBirth: transformBirthDate(childItem.dateOfBirth),
    groupId: +(childItem.group as Group).id,
    unitId: (childItem.unit as Unit).id ? +(childItem.unit as Unit).id : '',
  }));
