import { AccessLevel } from 'redux/authentication/enums';

import { axiosInstance, setAuthorizationToken } from './axiosInstance';
import endpoints from './endpoints';

class Client {
  login = async (data: { password: string; email: string }): Promise<{ accessToken: string; refreshToken: string }> => {
    try {
      const loginData = await axiosInstance.post(endpoints.login, data);
      setAuthorizationToken(loginData.data.accessToken);
      return loginData.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  };

  getUserData = async (): Promise<{
    accessLevel: AccessLevel;
    userId: number;
    unitId?: number;
    username: string;
    acceptedGDPR: boolean;
  }> => {
    try {
      const userData = await axiosInstance.get(endpoints.user);
      return {
        accessLevel: userData.data.role,
        userId: userData.data.userId,
        unitId: userData.data.unitId,
        username: userData.data.username,
        acceptedGDPR: userData.data.acceptedGDPR,
      };
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  };

  restorePassword = async (hash: string, password: string): Promise<void> => {
    try {
      const restorePasswordData = await axiosInstance.patch(endpoints.restorePassword, {
        hash,
        password,
      });
      return restorePasswordData.data;
    } catch (error: any) {
      return error.response.data;
    }
  };

  forgotPassword = async (email: string): Promise<void> => {
    try {
      const forgotPassword = await axiosInstance.post(endpoints.forgotPassword, {
        email,
      });
      return forgotPassword.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  };

  validateHash = async (hash: string): Promise<void> => {
    try {
      await axiosInstance.get(`${endpoints.validateHash}?hash=${hash}`);
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  };

  refreshToken = async (refreshToken: string): Promise<{ refreshToken: string; accessToken: string }> => {
    const response = await axiosInstance.post(endpoints.refreshToken, {
      refreshToken,
      clientSecret: process.env.REACT_APP_CLIENT_SECRET,
    });

    return response.data;
  };
}

export default new Client();
